import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import GridView from './List/GridView';
import { Box, useTheme } from '@mui/material';
import Loader from 'src/components/Loader';
import { useLazyGetRolesQuery } from 'src/services/api';
import { setAllKCRole } from 'src/redux/slices/data';
import { sortArray } from '../Roles/utils';
import { useDispatch, useSelector } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { StringKeys } from 'src/types/base';
import { ErrorContext } from 'src/utils/errorMappings';

const Users = ({ renderTabs, title }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const allRoles = useSelector((state) => state.data.allKCRoles);

  const [fetchAllRoles, { data: appRoles, isLoading: isLoadingRoles }] =
    useLazyQuery<void, StringKeys>({
      api: useLazyGetRolesQuery,
      errorContext: ErrorContext.ROLES
    });

  useEffect(() => {
    if (allRoles && !allRoles.length) {
      fetchAllRoles({ params: undefined });
    }
  }, []);

  useEffect(() => {
    if (appRoles) {
      dispatch(setAllKCRole(sortArray(appRoles, 'name')));
    }
  }, [appRoles]);

  if (isLoadingRoles) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Helmet title={`${T.empowerGPT} - ${t(T.users)}`} />
      <Box
        sx={{
          width: { xs: '100%', lg: '100%' },
          backgroundColor: theme.colors.alpha.white[100]
        }}
        height={'100%'}
      >
        <GridView renderTabs={renderTabs} title={title} />
      </Box>
    </React.Fragment>
  );
};

export default Users;

import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { RefObject } from 'react';
import {
  StyledAccordionSummary,
  StyledHeading
} from '../../layouts/AccentHeaderLayout/styles';

interface IAccordionSummary {
  expanded: boolean;
  summaryRef: RefObject<HTMLHeadingElement>;
  heading: string;
}

const CustomAccordionSummary: React.FC<IAccordionSummary> = ({
  expanded,
  summaryRef,
  heading
}) => {
  return (
    <StyledAccordionSummary ref={summaryRef}>
      <StyledHeading>{heading}</StyledHeading>
      {expanded ? (
        <ExpandMore sx={{ color: '#B2B6CD' }} fontSize="small" />
      ) : (
        <ChevronRight sx={{ color: '#B2B6CD' }} fontSize="small" />
      )}
    </StyledAccordionSummary>
  );
};

export default CustomAccordionSummary;

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { MouseEvent, useContext } from 'react';
import { DocumentsContextProvider } from 'src/contexts/FileManagerContext';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useGlobalFunction from 'src/hooks/useGlobalFunction';
import { RootState, useSelector } from 'src/redux/store';
import SidebarBottomSection from '../Sidebar/BottomSection';
import SidebarMidSection from '../Sidebar/Favourites';
import History from '../Sidebar/History';
import HistoryPanel from '../Sidebar/History/HistoryPanel';
import { CustomDivider } from '../Sidebar/styles';
import SidebarTopSection from '../Sidebar/TopSection';
import { StyledMinimizedDrawer } from '../styles';

const DesktopMinimizedDrawer = ({ setShowButton }) => {
  const theme = useTheme();
  const { isSidebarOpen } = useContext(SidebarContext);
  const { showChatHistoryPanel } = useSelector(
    (state: RootState) => state.chat
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleHistoryDrawer } = useGlobalFunction();

  const handleMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
    handleHistoryDrawer(event, false);
    setShowButton(true);
  };

  return (
    <StyledMinimizedDrawer
      isSidebarOpen={isSidebarOpen}
      variant="persistent"
      anchor="left"
      open={!isSidebarOpen}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      <Box
        maxHeight={'100%'}
        height={'100%'}
        sx={{
          backgroundColor: theme.sidebar.background
        }}
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" flexDirection="column">
          <SidebarTopSection />
        </Box>
        {showChatHistoryPanel && isMobile ? (
          <HistoryPanel />
        ) : (
          <>
            <Box onMouseEnter={handleMouseEnter}>
              <DocumentsContextProvider>
                <SidebarMidSection />
              </DocumentsContextProvider>
            </Box>
            <Box marginTop={'18px'}>
              <History setShowButton={setShowButton} />
            </Box>
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              onMouseEnter={handleMouseEnter}
            >
              <CustomDivider />
              <SidebarBottomSection />
            </Box>
          </>
        )}
      </Box>
    </StyledMinimizedDrawer>
  );
};

export default DesktopMinimizedDrawer;

import { ASSISTANT_TABS, SORT_TYPES } from 'src/types/enum';
import { IAssistantProps, IAssistantTabProps } from './types';

export const BASE_64_PREFIX = 'data:image/svg+xml;base64,';
export const MAX_NAME_LENGTH = 50;
export const MAX_DESC_LENGTH = 300;
export const MAX_INSTRUCTIONS_LENGTH = 4000;
export const MAX_CONVERSATION_STARTER_LENGTH = 300;
export const MAX_CONVERSATION_STARTERS = 6;
export const MAX_ICON_FILE_SIZE = 500; // KB size
export const MAX_FILES_ATTACHED = 50;
export const DEFAULT_ASSISTANT_FILTER = 'created_on desc';
export const ASSISTANT_PAGE_SIZE = 20;

export const NEWEST_TO_OLDEST = 'created_on desc';
export const OLDEST_TO_NEWEST = 'created_on asc';
export const NAME_ASC = 'name asc';
export const NAME_DESC = 'name desc';

export const defaultAssistantProps: IAssistantProps = {
  assistantIcon: '',
  aiModel: null,
  isWebSearch: false,
  worldKnowledge: true,
  instructions: ''
};
export const sortList = [
  // {
  //   id: 1,
  //   name: 'Recently Used',
  //   code: 'recently-used'
  // },
  {
    id: 2,
    name: 'Newest to Oldest',
    code: NEWEST_TO_OLDEST
  },
  {
    id: 3,
    name: 'Oldest to Newest',
    code: OLDEST_TO_NEWEST
  },
  {
    id: 4,
    name: 'A-Z',
    code: NAME_ASC
  },
  {
    id: 5,
    name: 'Z-A',
    code: NAME_DESC
  }
];

export const initialAssistantTabs: IAssistantTabProps[] = [
  {
    label: '',
    value: ASSISTANT_TABS.ALL
  },
  {
    label: '',
    value: ASSISTANT_TABS.CREATED_BY_ME
  }
];

export const sortArrayByKeyAndType = (
  array: any,
  key: string,
  sortType: string
) => {
  let sortedArray: any = [...array];
  if (sortType === SORT_TYPES.ASCENDING) {
    return sortedArray.sort((a: any, b: any) =>
      a[key].toLowerCase().localeCompare(b[key].toLowerCase())
    );
  } else {
    return sortedArray.sort((a: any, b: any) =>
      b[key].toLowerCase().localeCompare(a[key].toLowerCase())
    );
  }
};

const getBase64StringFromDataURL = (dataURL) =>
  dataURL.replace('data:', '').replace(/^.+,/, '');

export const convertImageToBase64 = (file, setIcons, icons, icon) => {
  let reader = new FileReader();
  reader.onloadend = function () {};
  reader.readAsDataURL(file);
};

import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as AttachFileIcon } from 'src/assets/helpAction/attachment.svg';
import { BodyTextTypography, StyledLink } from './styles';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useDispatch } from 'src/redux/store';
import { MAX_FILE_SIZE, MAX_FILES } from './utils';
import { setAlert } from 'src/redux/slices/snackbar';
import { ALERT } from 'src/types/enum';

interface UploadFileProps {
  setAttachedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  attachedFiles: File[];
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadFile: React.FC<UploadFileProps> = ({
  setAttachedFiles,
  attachedFiles,
  setIsUploading
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) return;

    setIsUploading(true);

    const selectedFiles = Array.from(event.target.files);

    const currentFileCount = attachedFiles?.length;
    const availableSlots = MAX_FILES - currentFileCount;

    if (availableSlots <= 0) {
      dispatch(setAlert({ msg: t(T.fileSizeError), type: ALERT.ERROR }));
      setIsUploading(false);
      return;
    }

    const validFiles: File[] = [];
    for (const file of selectedFiles) {
      if (validFiles.length >= availableSlots) {
        dispatch(setAlert({ msg: t(T.fileSizeError), type: ALERT.ERROR }));
        break;
      }

      if (file.size > MAX_FILE_SIZE) {
        dispatch(
          setAlert({
            msg: `${file.name} ${t(T.sizeExceedError)}`,
            type: ALERT.ERROR
          })
        );
        continue;
      }
      validFiles.push(file);
    }

    if (validFiles.length > 0) {
      setAttachedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
    setIsUploading(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="start" gap={1}>
      <Box display="flex" alignItems="center" gap={1}>
        <StyledLink component="label">
          <Box sx={{ width: '24px', height: '24px' }}>
            <AttachFileIcon />
          </Box>
          <BodyTextTypography
            style={{ textDecorationLine: 'underline' }}
            variant="body2"
          >
            {t(T.attachFile)}
          </BodyTextTypography>
          <input type="file" hidden multiple onChange={handleFileChange} />
        </StyledLink>
      </Box>
    </Box>
  );
};

export default UploadFile;

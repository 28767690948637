import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ReactComponent as CheckBoxIcon } from 'src/assets/checkbox-icon.svg';
import { ReactComponent as CheckBoxSelectedIcon } from 'src/assets/checkbox-selected.svg';
import { ReactComponent as CheckBoxIndeterminateIcon } from 'src/assets/checkbox-indeterminate.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FilterOption } from './types';
import usePopover from 'src/hooks/usePopover';
import {
  checkCircleIconStyles,
  FilterButton,
  filterOptionStyles,
  filterPopoverPaperStyles
} from './styles';

interface AdvancedSearchFilterProps {
  onChange: (filterData: FilterOption[], isFilterApplied: boolean) => void;
  filterLabel: string;
  filtersData: FilterOption[];
}

const AdvancedSearchFilter = ({
  onChange,
  filterLabel,
  filtersData
}: AdvancedSearchFilterProps) => {
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [filtersValue, setFiltersValue] = useState<FilterOption[]>(filtersData);
  const {
    anchorEl: filterAnchorElem,
    isPopoverOpen: isFilterPopoverOpen,
    openPopover: openFilterPopover,
    closePopover: closeFilterPopover
  } = usePopover();

  const toggleExpand = (id: number) => {
    setFiltersValue((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
  };

  const updateFilterCount = (
    id: number,
    isChild = false,
    parentId: number | null = null
  ) => {
    let updatedCount = filtersCount;
    if (!isChild) {
      const filterItem = filtersValue.find((item) => item.id === id);
      if (!filterItem.data) {
        updatedCount += filterItem.isSelected ? -1 : 1;
      } else {
        const unSelectedChildItemLength = filterItem.data.filter(
          (item) => !item.isSelected
        ).length;
        updatedCount += filterItem.isSelected
          ? -filterItem.data.length
          : unSelectedChildItemLength;
      }
    } else {
      const filterItem = filtersValue.find((item) => item.id === parentId);
      const childItem = filterItem.data.find((item) => item.id === id);
      updatedCount += childItem.isSelected ? -1 : 1;
    }
    setFiltersCount(updatedCount);
    return updatedCount;
  };

  const updateFiltersValue = (
    id: number,
    isChild = false,
    parentId: number | null = null
  ) => {
    let updatedFiltersValue = filtersValue;
    if (isChild && parentId) {
      updatedFiltersValue = updatedFiltersValue.map((parent): FilterOption => {
        if (parent.id === parentId) {
          const updatedChildren = parent.data.map((child) =>
            child.id === id
              ? { ...child, isSelected: !child.isSelected }
              : child
          );
          const allChildrenSelected = updatedChildren.every(
            (child) => child.isSelected
          );
          const someChildrenSelected = updatedChildren.some(
            (child) => child.isSelected
          );
          return {
            ...parent,
            isSelected: allChildrenSelected,
            data: updatedChildren,
            isPartialSelected: !allChildrenSelected && someChildrenSelected
          };
        }
        return parent;
      });
    } else {
      updatedFiltersValue = updatedFiltersValue.map((item) => {
        if (item.id === id) {
          const isSelected = !item.isSelected;
          const updatedChildren = item.data
            ? item.data.map((child) => ({ ...child, isSelected }))
            : item.data;
          return {
            ...item,
            isSelected,
            data: updatedChildren,
            isPartialSelected: false
          };
        }
        return item;
      });
    }
    setFiltersValue(updatedFiltersValue);
    return updatedFiltersValue;
  };

  const handleCheckboxChange = (
    id: number,
    isChild = false,
    parentId: number | null = null
  ) => {
    const updatedFiltersCount = updateFilterCount(id, isChild, parentId);
    const updatedFiltersValue = updateFiltersValue(id, isChild, parentId);
    onChange(updatedFiltersValue, Boolean(updatedFiltersCount));
  };

  return (
    <>
      <FilterButton
        variant="outlined"
        onClick={openFilterPopover}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{ height: '16px', width: '16px', mr: '5px' }}
          />
        }
      >
        {filterLabel}
        {filtersCount > 0 ? (
          <CheckCircleIcon sx={checkCircleIconStyles} />
        ) : null}
      </FilterButton>
      <Popover
        open={isFilterPopoverOpen}
        anchorEl={filterAnchorElem}
        transitionDuration={0}
        onClose={closeFilterPopover}
        disablePortal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: filterPopoverPaperStyles
        }}
      >
        <Box>
          {filtersValue.map((item) => (
            <Box key={item.id}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={'10px'}
                py={0}
                height={36}
                onClick={() => handleCheckboxChange(item.id)}
                sx={filterOptionStyles}
              >
                <Box display="flex" alignItems="center">
                  <Checkbox
                    icon={<CheckBoxIcon />}
                    checkedIcon={<CheckBoxSelectedIcon />}
                    indeterminateIcon={<CheckBoxIndeterminateIcon />}
                    checked={item.isSelected}
                    indeterminate={item.isPartialSelected}
                  />
                  <Typography color={'#000C57'} fontSize={'14px'}>
                    {item.label}
                  </Typography>
                </Box>
                {item.data && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(item.id);
                    }}
                    size="small"
                  >
                    {item.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                )}
              </Box>
              {item.isExpanded && item.data && (
                <Box>
                  {item.data.map((child) => (
                    <Box
                      key={child.id}
                      display="flex"
                      alignItems="center"
                      px={'10px'}
                      py={0}
                      height={36}
                      pl={'32px'}
                      onClick={() =>
                        handleCheckboxChange(child.id, true, item.id)
                      }
                      sx={filterOptionStyles}
                    >
                      <Checkbox
                        icon={<CheckBoxIcon />}
                        checkedIcon={<CheckBoxSelectedIcon />}
                        checked={child.isSelected}
                      />
                      <Typography
                        sx={{
                          color: '#000C57',
                          fontWeight: 400,
                          fontSize: '14px'
                        }}
                      >
                        {child.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default AdvancedSearchFilter;

import { List } from '@mui/material';
import { useContext } from 'react';
import { TFunction } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { FAVOURITE_TYPE } from 'src/types/enum';
import ScrollableSection from '../../ScrollableSection';
import { MidSectionBox } from '../../styles';
import SidebarMenu from '../Menu/index';
import { SubMenuWrapper } from '../styles';
import SidebarFavouriteItem from './item';
import { FavouriteItem } from './items';

export const renderSidebarFavouriteItems = ({
  item,
  path,
  handleStartChatClick,
  handleFavouriteClick,
  t,
  favouriteType,
  hasFilePermissions,
  hasChatPermission,
  handleFilePermissionModal
}: {
  item: FavouriteItem;
  path: string;
  handleStartChatClick: (
    id: number,
    name: string,
    workspaceName?: string
  ) => void;
  handleFavouriteClick: (id: number, isFavourite: boolean) => void;
  t: TFunction<'translation', undefined>;
  hasFilePermissions?: boolean;
  hasChatPermission?: boolean;
  favouriteType: string;
  handleFilePermissionModal?: (id: number) => void;
}): JSX.Element => {
  const key = item.name;

  const exactMatch = item?.link
    ? !!matchPath(
        {
          path: item?.link,
          end: true
        },
        path
      )
    : false;

  return (
    <SubMenuWrapper>
      <List component="div">
        {[
          <SidebarFavouriteItem
            key={key}
            active={exactMatch}
            name={item.name}
            workspaceName={item?.workspaceName}
            link={item?.link}
            icon={item?.icon}
            id={item.id}
            handleStartChatClick={handleStartChatClick}
            handleFavouriteClick={handleFavouriteClick}
            t={t}
            favouriteType={favouriteType}
            isLink={favouriteType !== FAVOURITE_TYPE.ASSISTANT}
            hasFilePermissions={hasFilePermissions}
            hasChatPermission={hasChatPermission}
            handleFilePermissionModal={handleFilePermissionModal}
          />
        ]}
      </List>
    </SubMenuWrapper>
  );
};

function SidebarMidSection() {
  const { isSidebarOpen } = useContext(SidebarContext);
  return (
    <MidSectionBox>
      <SidebarMenu />
      {isSidebarOpen && <ScrollableSection />}
    </MidSectionBox>
  );
}

export default SidebarMidSection;

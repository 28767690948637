import {
  AdminPanelSettingsOutlined,
  AnalyticsOutlined,
  GroupsOutlined,
  ManageAccountsOutlined,
  SettingsOutlined
} from '@mui/icons-material';
import { SETTINGS_TABS } from 'src/content/Settings/utils';
import { StringKeys } from 'src/types/base';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import { ANALYTICS_ROUTES, USER_ROUTES } from 'src/utils/constants';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const getPopupRoutes = (
  userAppPermissions: StringKeys,
  isNonTrialUser: boolean
): {
  to: string;
  startIcon: JSX.Element;
  title: string;
  onClick?: () => void;
}[] => {
  const routes = [];
  if (userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_USERS]?.value) {
    routes.push({
      to: `/${USER_ROUTES.USERS}`,
      startIcon: <ManageAccountsOutlined color="primary" />,
      title: 'User and Roles'
    });
  } else if (
    isNonTrialUser &&
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROLES]?.value
  ) {
    routes.push({
      to: `/${USER_ROUTES.ROLES}`,
      startIcon: <AdminPanelSettingsOutlined />,
      title: 'User and Roles'
    });
  } else if (
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_USER_GROUPS]?.value ||
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_OWN_USER_GROUPS]?.value
  ) {
    routes.push({
      to: `/${USER_ROUTES.USER_GROUPS}`,
      startIcon: <GroupsOutlined />,
      title: 'User and Roles'
    });
  }
  if (userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ANALYTICS]?.value) {
    routes.push({
      to: `/${ANALYTICS_ROUTES.USAGE}`,
      startIcon: <AnalyticsOutlined color="primary" />,
      title: 'Usage and Analytics'
    });
    routes.push({
      to: `/settings/${SETTINGS_TABS.SYSTEM_SETTINGS}`,
      startIcon: <SettingsOutlined color="primary" />,
      title: T.settings
    });
  } else {
    routes.push({
      to: `/settings/${SETTINGS_TABS.PROFILE}`,
      startIcon: <SettingsOutlined color="primary" />,
      title: T.settings
    });
  }
  return routes;
};

import styled from '@emotion/styled';
import { Typography, Link, Button, Card, Box } from '@mui/material';

export const BodyTextTypography = styled(Typography)(({ theme }) => ({
  marginBottom: '3px',
  color: 'var(--Secondary-400, #495990)',
  fontFamily: 'Actor',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  letterSpacing: '0.5px',
  textDecorationStyle: 'solid',
  textDecorationSkipInk: 'none',
  textDecorationThickness: 'auto',
  textUnderlineOffset: 'auto',
  textUnderlinePosition: 'from-font'
}));

export const MainTextTypography = styled(Typography)(({ theme }) => ({
  color: 'var(--Secondary-500-Main, #222F59)',
  fontFamily: 'Actor',
  fontStyle: 'normal',
  fontWeight: 400,
  letterSpacing: '0.7px'
}));

export const IconStyles = { width: '48px', height: '48px' };
export const SmallIconStyles = {
  width: '24px',
  height: '24px'
};
export const IconButtonStyles = {
  position: 'absolute',
  top: 16,
  right: 16,
  padding: '8px',
  zIndex: 1
};
export const DialogStyles = {
  margin: 'auto',
  display: 'block',
  width: '80%',
  maxWidth: '800px'
};

export const StyledLink = styled(Link)<{ component?: React.ElementType }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #0073e6;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #0056b3;
  }
`;

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#6FCF97',
  borderRadius: '8px',
  width: '10%',
  height: '40px',
  fontSize: '16px',
  position: 'absolute',
  right: 1,
  bottom: 16,
  color: '#000C57',
  fontWeight: 400,
  fontFamily: 'Actor',
  '&:hover': {
    backgroundColor: '#55D484'
  }
}));

export const StyledCard = styled(Card)<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    width: '250px',
    textAlign: 'center',
    cursor: 'pointer',
    border: '1px solid #E0E0E0',
    backgroundColor: isSelected ? '#F4F6F8' : '#FFFFFF',
    boxShadow: 'none',
    transition: 'background-color 0.3s, box-shadow 0.3s, transform 0.2s ease',
    marginRight: '-1px',
    '&:hover': {
      backgroundColor: '#DDE5F2',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    },
    '&:first-of-type': { borderRadius: '8px 0 0 8px' },
    '&:last-of-type': { borderRadius: '0 8px 8px 0' },
    '&:active': { transform: 'scale(0.98)' }
  })
);

export const StyledSupportButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSidebarOpen'
})<{
  isSidebarOpen?: boolean;
  disabled?: boolean;
}>`
  background-color: #001f5a;
  color: white;
  text-transform: none;
  display: flex;
  justify-content: ${({ isSidebarOpen }) =>
    isSidebarOpen ? 'flex-start' : 'center'};
  align-items: center;
  gap: 6px;
  padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '9px 14px' : '9px 13px')};
  min-width: ${({ isSidebarOpen }) => (isSidebarOpen ? '64px' : 'inherit')};
  max-height: ${({ isSidebarOpen }) => (isSidebarOpen ? '28px' : '34px')};
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #003085;
  }
  &:disabled {
    opacity: 0.5;
  }

  .icon {
    font-size: 20px;
  }
`;

export const StyledTypography = styled(Typography)`
  padding-left: 0;
  position: relative;
  text-align: left;
  font-size: 14px;
`;

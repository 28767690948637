import { FILE_EXTENSIONS, RESOURCE_TYPE } from 'src/types/enum';
import { FilterOption } from './types';
import { ChatFile } from 'src/content/Chat/types';
import { IResourceProvider } from 'src/types/api';

export const fileTypeFiltersData: FilterOption[] = [
  {
    label: 'Workspaces',
    id: 1,
    isSelected: false,
    value: [RESOURCE_TYPE.WORKSPACE, RESOURCE_TYPE.PRIVATE_WORKSPACE]
  },
  {
    label: 'Collections',
    id: 2,
    isSelected: false,
    value: RESOURCE_TYPE.COLLECTION
  },
  { label: 'Folders', id: 3, isSelected: false, value: RESOURCE_TYPE.FOLDER },
  {
    label: 'Documents',
    id: 4,
    isSelected: false,
    isExpanded: false,
    value: RESOURCE_TYPE.FILE,
    data: [
      { label: 'PDF', id: 41, isSelected: false, value: FILE_EXTENSIONS.PDF },
      {
        label: 'Word Document',
        id: 42,
        isSelected: false,
        value: [FILE_EXTENSIONS.DOC, FILE_EXTENSIONS.DOCX, FILE_EXTENSIONS.ODT]
      },
      {
        label: 'PowerPoint',
        id: 43,
        isSelected: false,
        value: [FILE_EXTENSIONS.PPT, FILE_EXTENSIONS.PPTX, FILE_EXTENSIONS.ODP]
      },
      {
        label: 'Excel',
        id: 44,
        isSelected: false,
        value: [FILE_EXTENSIONS.XLS, FILE_EXTENSIONS.XLSX, FILE_EXTENSIONS.ODS]
      }
    ]
  }
];

export const getSourceFiltersData = (
  userResourceProviders: IResourceProvider[]
) => [
  {
    label: 'Uploaded Content',
    id: 1,
    isSelected: false,
    value: null
  },
  ...userResourceProviders
    .filter((item) => item.is_active && item.is_configuration_completed)
    .map((item) => ({
      label: item.display_name,
      id: item.id,
      isSelected: false,
      value: item.id
    }))
];

export const getFilesOptionsFromData = (
  fileData: ChatFile[],
  chatFiles: ChatFile[]
) => {
  return fileData.map((item) => ({
    ...item,
    isSelected: !!chatFiles.find((chatFile) => chatFile.id === item.id),
    isDisabled: !!chatFiles.find((chatFile) => chatFile.id === item.id)
  }));
};

import { Box, List, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import SidebarAccordion from 'src/components/Accordion';
import FilePermissionModal from 'src/content/Assistant/Components/FilePermissionModal';
import { startChat } from 'src/content/Documents/utils/gridUtils';
import DocumentsContext from 'src/contexts/FileManagerContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import { RootState, useSelector } from 'src/redux/store';
import { useLazyGetAssistantDetailQuery } from 'src/services/api';
import { StringKeys } from 'src/types/base';
import { FAVOURITE_TYPE, RESOURCE_TYPE } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { renderSidebarFavouriteItems } from '../Favourites';
import getFavouriteItems, { FavouriteItems } from '../Favourites/items';
import { MenuWrapper } from '../styles';

const Assistants = ({
  expandedSections,
  setExpandedSections,
  assistantsRef
}) => {
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const location = useLocation();
  const {
    userAppPermissions,
    userFavourites,
    isFavouritesFetching,
    icons,
    configuredModels
  } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isFileModalOpen, setIsFileModalOpen] = useState<boolean>(false);
  const [currentAssistantDetails, setCurrentAssistantDetails] =
    useState<StringKeys>();
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const { isUpdatingFavourite, updateFavourite } = useContext(DocumentsContext);
  const [hasFilePermission, setHasFilePermission] = useState<boolean>(false);
  const [
    fetchAssistantDetails,
    { isFetching: isFetchingAssistant, data: assistantData }
  ] = useLazyQuery({
    api: useLazyGetAssistantDetailQuery,
    errorContext: ErrorContext.ASSISTANT
  });

  const menuItems = useMemo(
    () =>
      getFavouriteItems(
        userAppPermissions,
        userFavourites || [],
        FAVOURITE_TYPE.ASSISTANT,
        icons,
        configuredModels
      ),
    [userFavourites, icons]
  );

  const handleStartChatClick = async (id: number, name: string) => {
    const routeState = location?.state?.documentContext;
    startChat({
      documentContext: {
        id,
        name,
        assistant_id: id
      },
      dispatch,
      navigate,
      selectedChat,
      routeState,
      resourceType: RESOURCE_TYPE.ASSISTANT
    });
  };

  const handleFavouriteClick = useCallback(
    (id: number, isFavourite: boolean) => {
      if (isUpdatingFavourite || isFavouritesFetching) return;

      dispatch(setIsFavouritesFetching(true));
      updateFavourite(id, !isFavourite, FAVOURITE_TYPE.ASSISTANT);
    },
    [
      dispatch,
      isUpdatingFavourite,
      isFavouritesFetching,
      FAVOURITE_TYPE.ASSISTANT
    ]
  );

  const handleFilePermissionModal = useCallback((id) => {
    setHasFilePermission(true);
    fetchAssistantDetails({
      params: {
        params: {
          assistant_id: id,
          fetch_all: false
        }
      }
    });
  }, []);

  const handleCloseFileModal = () => setIsFileModalOpen(false);

  useEffect(() => {
    if (
      hasFilePermission &&
      !!assistantData?.data?.length &&
      !isFetchingAssistant
    ) {
      setCurrentAssistantDetails({
        ...assistantData.data?.[0],
        aiModel: configuredModels.filter(
          (model) =>
            model.id === parseInt(assistantData?.data?.[0]?.settings.model_id)
        )[0]
      });
      setIsFileModalOpen(true);
      setHasFilePermission(false);
    }
  }, [assistantData, isFetchingAssistant, hasFilePermission]);

  if (menuItems.length > 0) {
    return (
      <SidebarAccordion
        expanded={expandedSections.assistants}
        onToggle={() =>
          setExpandedSections({
            ...expandedSections,
            assistants: !expandedSections.assistants
          })
        }
        heading={t(T.assistants)}
        summaryRef={assistantsRef}
        sx={{ bgcolor: theme.sidebar.background }}
      >
        <List disablePadding>
          {menuItems?.length ? (
            <Box>
              <Box width="100%">
                {menuItems.map((section: FavouriteItems, index: number) => (
                  <MenuWrapper key={index}>
                    <List component="div">
                      {renderSidebarFavouriteItems({
                        item: section.item,
                        path: location.pathname,
                        handleStartChatClick: handleStartChatClick,
                        handleFavouriteClick: handleFavouriteClick,
                        t: t,
                        favouriteType: FAVOURITE_TYPE.ASSISTANT,
                        hasFilePermissions: section?.item?.hasFilePermissions,
                        hasChatPermission: section?.item?.hasChatPermission,
                        handleFilePermissionModal: handleFilePermissionModal
                      })}
                    </List>
                  </MenuWrapper>
                ))}
              </Box>
            </Box>
          ) : null}
          {isFileModalOpen && (
            <FilePermissionModal
              isFileModalOpen={isFileModalOpen}
              handleCloseFileModal={handleCloseFileModal}
              rowData={currentAssistantDetails}
            />
          )}
        </List>
      </SidebarAccordion>
    );
  } else {
    return <></>;
  }
};

export default Assistants;

import React from 'react';
import { Controller } from 'react-hook-form';
import {
  HelpAndSupportTabs,
  MAX_MESSAGE_LENGTH,
  MAX_SUBJECT_LENGTH
} from './utils';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { TextField } from '@mui/material';
import { BodyTextTypography } from './styles';
import { HelpFormFieldsProps } from './types';

const HelpFormFields: React.FC<HelpFormFieldsProps> = ({
  control,
  errors,
  selectedTab
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <>
      {selectedTab !== HelpAndSupportTabs.SUGGEST_IMPROVEMENTS && (
        <>
          <Controller
            name="subject"
            control={control}
            rules={{
              required:
                selectedTab === HelpAndSupportTabs.CONTACT_SUPPORT ||
                selectedTab === HelpAndSupportTabs.REPORT_ISSUE,
              maxLength: {
                value: MAX_SUBJECT_LENGTH,
                message: t(T.maxLength, {
                  name: t(T.subject),
                  length: MAX_SUBJECT_LENGTH
                })
              }
            }}
            render={({ field }) => (
              <>
                <BodyTextTypography sx={{ marginBottom: '8px' }}>
                  {t(T.subject)} <span style={{ color: 'red' }}>*</span>
                </BodyTextTypography>
                <TextField
                  id="subject"
                  {...field}
                  fullWidth
                  placeholder={
                    selectedTab === HelpAndSupportTabs.REPORT_ISSUE
                      ? t(T.reportIssueSubject)
                      : t(T.enter)
                  }
                  variant="outlined"
                  sx={{ marginBottom: '16px' }}
                  error={!!errors.subject}
                  helperText={errors.subject?.message}
                />
              </>
            )}
          />
        </>
      )}

      <Controller
        name="message"
        control={control}
        rules={{
          required: true,
          maxLength: {
            value: MAX_MESSAGE_LENGTH,
            message: t(T.maxLength, {
              name: t(T.message),
              length: MAX_MESSAGE_LENGTH
            })
          }
        }}
        render={({ field }) => (
          <>
            <BodyTextTypography sx={{ marginBottom: '8px' }}>
              {t(T.message)} <span style={{ color: 'red' }}>*</span>
            </BodyTextTypography>
            <TextField
              id="message"
              {...field}
              fullWidth
              variant="outlined"
              multiline
              placeholder={
                selectedTab === HelpAndSupportTabs.REPORT_ISSUE
                  ? t(T.reportIssueDescription)
                  : selectedTab === HelpAndSupportTabs.SUGGEST_IMPROVEMENTS
                  ? t(T.suggestedImprovemnt)
                  : t(T.enter)
              }
              rows={8}
              sx={{ marginBottom: '16px' }}
              error={!!errors.message}
              helperText={errors.message?.message}
            />
          </>
        )}
      />
    </>
  );
};

export default HelpFormFields;

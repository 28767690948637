import React from 'react';
import { Grid } from '@mui/material';
import { CountTypography, T3Typography } from './styles';
import { InternalAnalytics } from 'src/services/analytics';

const CountBox = ({ name, count }: InternalAnalytics) => {
  return (
    <Grid
      item
      xs={12}
      sm={4}
      margin={0}
      paddingRight={0.5}
      display={{ xs: 'flex', sm: 'initial' }}
      justifyContent={{ xs: 'space-between', sm: 'initial' }}
    >
      <T3Typography>{name}</T3Typography>
      <CountTypography>{count}</CountTypography>
    </Grid>
  );
};

export default CountBox;

import React from 'react';
import { initialColorList } from 'src/theme/customColors';
import { getInitials } from 'src/utils/utils';

type WorkspaceIconProps = {
  initialsColor?: string;
  displayName: string;
  initials?: string;
  height?: number | string;
  width?: number | string;
  style?: any;
};

const WorkspaceIcon = ({
  initialsColor,
  displayName,
  initials,
  height = '28px',
  width = '28px',
  style
}: WorkspaceIconProps) => {
  return (
    <div
      style={{
        backgroundColor: initialsColor || initialColorList[0],
        border:
          !initialsColor ||
          initialsColor === initialColorList[0] ||
          initialsColor === '#000C57'
            ? '1px solid white' 
            : 'none',
        borderRadius: '4px',
        height: height,
        width: width,
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFF',
        ...style
      }}
      aria-haspopup="true"
    >
      {initials || getInitials(displayName)}
    </div>
  );
};

export default WorkspaceIcon;

import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { UserStats } from './types';

export const PROFILE_ATTRIBUTES_MAX_LENGTH = 50;

export enum SETTINGS_TABS {
  SYSTEM_SETTINGS = 'system-settings',
  PROFILE = 'profile',
  INSTRUCTIONS = 'instructions'
}

export const getUserStats = (
  dataLibraryStats: any[],
  dataChatStats: any,
  dataAssistantsStats: any[]
): UserStats => {
  const documentCount = dataLibraryStats?.reduce(
    (acc, curr) =>
      curr.name === T.F_S_PR || curr.name === T.F_S_RE || curr.name === T.F_S_US
        ? acc + curr.count
        : acc,
    0
  );
  const questionsCount = dataChatStats?.queries?.reduce(
    (acc, curr) => curr.count + acc,
    0
  );
  const assistantsCount = dataAssistantsStats?.[0].count;
  return { documentCount, questionsCount, assistantsCount };
};

import clsx from 'clsx';
import { FC, ReactNode, useContext, useState } from 'react';
import RouterLink from 'src/components/NavLink';

import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import {
  Badge,
  Button,
  Collapse,
  ListItem,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useMediaQuery,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  isLink?: boolean;
  endIcon?: React.ReactNode;
  showActiveClass?: boolean;
}

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.black[100],
    color: theme.palette.getContrastText(theme.colors.alpha.black[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.black[100]
  }
}));

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  open: openParent,
  active,
  name,
  isLink = true,
  endIcon: EndIcon,
  showActiveClass = false,
  ...rest
}) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem
        component="div"
        className="Mui-children"
        key={name}
        {...rest}
        disablePadding
        onClick={isLink && isMobile ? toggleSidebar : undefined}
      >
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          startIcon={
            Icon && <Icon sx={(theme) => ({ padding: theme.spacing(0.2) })} />
          }
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              {badge === '' ? (
                <Badge color="primary" variant="dot" />
              ) : (
                <Badge badgeContent={badge} />
              )}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}
          <CustomListItemText primaryText={isSidebarOpen ? t(name) : ''} />
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      component="div"
      key={name}
      {...rest}
      disablePadding
      onClick={isLink && isMobile ? toggleSidebar : undefined}
      sx={{
        maxHeight: isSidebarOpen ? '28px' : '34px',
        marginBottom: '4px'
      }}
    >
      <Button
        disableRipple
        {...(isLink && { className: 'Mui-active' })}
        component={isLink ? RouterLink : Button}
        {...(isLink && {
          to: link
        })}
        {...(!isSidebarOpen && { title: name })}
        startIcon={
          Icon && (
            <Icon
              style={{
                fontSize: 16,
                height: isSidebarOpen ? '20px' : '22px',
                width: isSidebarOpen ? '20px' : '22px'
              }}
            />
          )
        }
        endIcon={EndIcon && EndIcon}
        sx={{
          ...(!isSidebarOpen && {
            minWidth: 'inherit',
            paddingLeft: '18px'
          }),
          maxHeight: isSidebarOpen ? '28px' : '34px'
        }}
        className={clsx({ 'Mui-active': showActiveClass })}
      >
        <CustomListItemText primaryText={t(name)} />
        {badgeTooltip ? (
          <TooltipWrapper title={badgeTooltip} arrow placement="right">
            {badge === '' ? (
              <Badge color="primary" variant="dot" />
            ) : (
              <Badge badgeContent={badge} />
            )}
          </TooltipWrapper>
        ) : badge === '' ? (
          <Badge color="primary" variant="dot" />
        ) : (
          <Badge badgeContent={badge} />
        )}
      </Button>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  badgeTooltip: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false
};

export default SidebarMenuItem;

import { LogoutOutlined } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  Popover,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { ReactComponent as PersonOutlineOutlinedIcon } from 'src/assets/sidebarIcon/person-icon.svg';

import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';
import { authSignOut as signOut } from 'src/redux/slices/auth';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { LOOKUP } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  CustomDivider,
  LogoutButton,
  MenuWrapper,
  StyledMoreVert,
  SubMenuWrapper
} from '../styles';
import { getPopupRoutes } from './utils';
import ContactSupportButton from 'src/content/HelpAndSupport';

const BottomSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    user: { isNonTrialUser, accountType, name }
  } = useSelector((state: RootState) => state.auth);
  const { lookups } = useSelector((state: RootState) => state.data);
  const { userAppPermissions } = useSelector((state: RootState) => state.data);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>();
  const dispatch = useDispatch();
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);

  const handleLogout = async (e): Promise<void> => {
    await dispatch(signOut());
  };

  const handleOpenIcon = useCallback(() => setIsMenuOpen(true), []);
  const handleCloseIcon = useCallback(() => setIsMenuOpen(false), []);

  const popupRoutes = useMemo(
    () => getPopupRoutes(userAppPermissions, isNonTrialUser),
    [userAppPermissions, isNonTrialUser]
  );

  const userAccountType = useMemo(
    () =>
      lookups[LOOKUP.ACCOUNT_TYPE]?.find((item) => item.code === accountType),
    [accountType, lookups]
  );

  const iconSize = isSidebarOpen ? '16px' : '22px';

  return (
    <MenuWrapper>
      <SubMenuWrapper>
        <List
          component="div"
          sx={{ padding: 0, margin: 0, overflow: 'hidden' }}
          disablePadding
        >
          <ListItem component="div" disablePadding>
            <ContactSupportButton
              isSidebarOpen={isSidebarOpen}
              iconSize={iconSize}
            />
          </ListItem>
          <ListItem component="div" disablePadding>
            <Button
              id={ONBOARDING_STEPS_TARGET.SIDEBAR_MORE_ACTION_BUTTON}
              disableRipple
              startIcon={
                <PersonOutlineOutlinedIcon
                  style={{ width: iconSize, height: iconSize }}
                />
              }
              endIcon={
                <StyledMoreVert
                  id={ONBOARDING_STEPS_TARGET.SIDEBAR_MORE_ACTION_ICON}
                  isSidebarOpen={isSidebarOpen}
                />
              }
              onClick={handleOpenIcon}
              sx={{
                color: 'white',
                display: 'flex',
                minWidth: isSidebarOpen ? '64px' : 'inherit',
                padding: isSidebarOpen ? '6px 16px' : '12px 14px',
                '& .MuiButton-startIcon': {
                  mr: isSidebarOpen && '7px !important'
                }
              }}
              ref={buttonRef}
            >
              {isSidebarOpen && (
                <CustomListItemText
                  primaryText={name}
                  secondaryText={userAccountType?.value}
                  sx={{
                    paddingLeft: 0
                  }}
                />
              )}
            </Button>
          </ListItem>
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            PaperProps={{
              sx: () => ({
                border: 0,
                backgroundColor: 'white'
              })
            }}
            open={isMenuOpen}
            anchorEl={buttonRef.current}
            onClose={handleCloseIcon}
          >
            <SubMenuWrapper sx={{ p: 0, width: '230px' }}>
              <List component="div" disablePadding>
                {popupRoutes.map(
                  ({ to, startIcon: StartIcon, onClick, title }, index) => (
                    <ListItem
                      key={index}
                      component="div"
                      sx={{ padding: 0, margin: 0 }}
                      disablePadding
                      onClick={isMobile ? toggleSidebar : undefined}
                    >
                      <Button
                        id={
                          to === '/users'
                            ? ONBOARDING_STEPS_TARGET.USERS_NAVIGATION_LINK
                            : undefined
                        }
                        disableRipple
                        component={RouterLink}
                        to={to}
                        onClick={onClick || handleCloseIcon}
                        startIcon={StartIcon}
                        sx={{
                          color: 'primary.main',
                          padding: '4px 6px',
                          borderRadius: 2,
                          '&.MuiButton-root': {
                            bgcolor: 'white !important',
                            color: '#000C57 !important'
                          },
                          '&:hover': {
                            bgcolor: '#f5f5f5 !important'
                          }
                        }}
                      >
                        {t(title)}
                      </Button>
                    </ListItem>
                  )
                )}
                <CustomDivider/>
                <ListItem
                  component="div"
                  sx={{ padding: 0, margin: 0 }}
                  disablePadding
                  onClick={isMobile ? toggleSidebar : undefined}
                >
                  <LogoutButton
                    startIcon={<LogoutOutlined color="primary" />}
                    onClick={handleLogout}
                  >
                    {t(T.logout)}
                  </LogoutButton>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </Popover>
        </List>
      </SubMenuWrapper>
    </MenuWrapper>
  );
};

export default BottomSection;

import React from 'react';
import { Typography, IconButton } from '@mui/material';
import { ReactComponent as CrossIcon } from 'src/assets/cross-icon.svg';

interface AttachedFilesListProps {
  attachedFiles: File[];
  setAttachedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const AttachedFilesList: React.FC<AttachedFilesListProps> = ({
  attachedFiles,
  setAttachedFiles
}) => {
  const handleDelete = (index: number) => {
    setAttachedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  if (attachedFiles.length === 0) return null;

  return (
    <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: 0 }}>
      {attachedFiles.map((file, index) => (
        <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2">{file.name}</Typography>
          <IconButton
            onClick={() => handleDelete(index)}
            size="small"
            sx={{ marginRight: '8px' }}
          >
            <CrossIcon />
          </IconButton>
        </li>
      ))}
    </ul>
  );
};

export default AttachedFilesList;

import React, { useState } from 'react';

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isPopoverOpen = Boolean(anchorEl);

  const popoverId = isPopoverOpen ? 'more-actions' : undefined;

  const openPopover = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (event?: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  return {
    anchorEl,
    setAnchorEl,
    isPopoverOpen,
    openPopover,
    closePopover,
    popoverId
  };
};

export default usePopover;

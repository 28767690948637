import { AlertColor } from '@mui/material';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format
} from 'date-fns';
import get from 'lodash/get';
import { TFunction } from 'react-i18next';
import { ReactComponent as ConfluenceIcon } from 'src/assets/Confluence/confluence.svg';
import { ReactComponent as OneDriveIcon } from 'src/assets/onedrive.svg';
import { ReactComponent as SharepointIcon } from 'src/assets/sharepoint.svg';
import {
  ATTACH_FILE_ICON_HEIGHT,
  ATTACH_FILE_ICON_WIDTH,
  ChatAttachedFileProps,
  UPLOADED_IMAGE_ICON_HEIGHT,
  UPLOADED_IMAGE_ICON_WIDTH
} from 'src/content/Chat/utils';
import { areFilesValid } from 'src/content/Documents/UploadDocument/utils';
import { setAlert } from 'src/redux/slices/snackbar';
import { AppDispatch } from 'src/redux/store';
import { IResourceProvider } from 'src/types/api';
import { IAiModel, StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  ALERT,
  FILE_UPLOADING_STATUS,
  LOOKUP,
  RESOURCE_PROVIDERS,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_UPLOAD_FILES_FORMAT
} from '../types/enum';
import {
  UPLOADED_FILE_IDS,
  EMAIL_REG_EXP,
  MAX_CHAT_ATTACHMENT_SIZE,
  MAX_CHAT_FILE_SIZE,
  PUBLIC_ROUTES,
  TWENTY_FOUR_HOUR_FORMAT
} from './constants';
import { decrypt_local, encrypt_local } from './encryption';
import { ErrorCodes } from './errorMappings';

const AZURE_PORTAL_URL = 'https://portal.azure.com/#home';

const ATLASSIAN_ADMIN_PORTAL_URL =
  'https://id.atlassian.com/manage-profile/security/api-tokens';

const ATLASSIAN_DEVELOPER_PORTAL_URL =
  'https://id.atlassian.com/login?continue=https://developer.atlassian.com/';

export const PRIVACY_POLICY_URL = 'https://intech.empowergpt.ai/privacypolicy';

export const SCROLL_TOLERANCE = 1;

export const INVALID_USER_STATUS = 'INVALID_USER';
export const INVALID_USER_MAX_RETRIES = 3;
export const INVALID_USER_RETRY_DELAY = 2000; // miliseconds
export const PAGE_TITLE_WEIGHT = 700;
export const PAGE_TITLE_SIZE = '25px';

export const getInitials = (name: string) => {
  // Sanitize the string:
  if (name) {
    const sanitizedStr = name
      ?.trim() // Remove leading/trailing spaces
      .replace(/\s+/g, ' ') // Replace multiple spaces with single space
      .replace(/[^a-zA-Z0-9' ]/g, ' ') // Replace non-alphanumeric characters with space and allowing apostrophy
      .toUpperCase() // Convert to uppercase for consistency
      .split(' ') // Split the string on space
      .slice(0, 2); // Select only first 2 words

    // Get the initials:
    if (sanitizedStr.length === 1) {
      return sanitizedStr[0].charAt(0) + sanitizedStr[0].charAt(1);
    }
    return sanitizedStr.map((word) => word.charAt(0)).join('');
  } else return '';
};

export const formatDate = (date?: string | Date, date_format?: string) => {
  return date
    ? format(new Date(`${date}`), date_format || TWENTY_FOUR_HOUR_FORMAT)
    : '';
};

export const caseInsensitiveSort = (a: string, b: string) => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export const viewPDFDocument = (data, pageNo?: number) => {
  const url = window.URL.createObjectURL(data);
  window.open(`${url}${pageNo ? `#page=${pageNo}` : ''}`);
};

export const convertDate = (date: Date, t: any) => {
  let currentDate: any = new Date();
  let previousDate: any = new Date(date);

  const days = differenceInDays(currentDate, previousDate);
  const hours = differenceInHours(currentDate, previousDate);
  const mins = differenceInMinutes(currentDate, previousDate);
  const secs = differenceInSeconds(currentDate, previousDate);

  if (days > 0) {
    return `${days} ${t(days > 1 ? T.days : T.day)}`;
  } else if (hours > 0) {
    return `${hours} ${t(hours > 1 ? T.hours : T.hour)}`;
  } else if (mins > 0) {
    return `${mins} ${t(mins > 1 ? T.mins : T.min)}`;
  } else if (secs > 0) {
    return `${secs} ${t(secs > 1 ? T.secs : T.sec)}`;
  }
  return '';
};

export const getFileType = (
  name: string,
  source: string,
  format: string,
  original_format: string
) => {
  if (original_format) return original_format;
  else if (name) return name?.split('.')?.pop();
  else if (format) return format;
  else if (source) return source?.split('.')?.pop();

  return '';
};

export const isEmailValid = (value: string) => {
  return !!value?.match(EMAIL_REG_EXP) || false;
};

// TODO: separate files and folders related utilities
export const getProviderForCurrentResource = (
  providerId: string,
  resourceProviders: IResourceProvider[]
): RESOURCE_PROVIDERS | undefined => {
  return resourceProviders.find(({ id }) => id === +providerId)?.type;
};

export const autoScrollIntoView = (errors: string[]) => {
  const firstErrorField = errors?.[0] ?? '';
  if (firstErrorField) {
    const field = document.getElementsByName(firstErrorField);
    if (field.length) {
      field?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
};

type OverLimitMessageType = {
  msg: string;
  type: AlertColor;
};

export const checkCreditsLimit = (
  licenseInfo: {
    creditLimit: string;
    wordLimit: string;
  },
  supportEmail: string,
  t: TFunction<'translation', undefined>
): OverLimitMessageType[] => {
  let messages: OverLimitMessageType[] = [];

  if (licenseInfo.creditLimit === ErrorCodes.CREDIT_LIMIT_EXCEEDED_90_PERCENT) {
    messages.push({
      msg: T.creditLimitExceeded90Percent,
      type: ALERT.WARNING
    });
  } else if (
    licenseInfo.creditLimit === ErrorCodes.CREDIT_LIMIT_REACHED_100_PERCENT
  ) {
    messages.push({
      msg: t(T.chatCreditLimitExceededOA, { supportEmail: supportEmail }),
      type: ALERT.ERROR
    });
  }
  if (licenseInfo.wordLimit === ErrorCodes.WORD_LIMIT_EXCEEDED_90_PERCENT) {
    messages.push({
      msg: T.wordLimitExceeded90Percent,
      type: ALERT.WARNING
    });
  } else if (
    licenseInfo.wordLimit === ErrorCodes.WORD_LIMIT_REACHED_100_PERCENT
  ) {
    messages.push({
      msg: t(T.wordLimitReached100Percent, { supportEmail: supportEmail }),
      type: ALERT.ERROR
    });
  }

  return messages;
};

export const filterEmptyValuesFromList = (
  options: StringKeys,
  optionLabel: string
) =>
  options?.filter((option) => {
    const label =
      typeof option === 'string' ? option : get(option, optionLabel);
    return label && label.trim() !== '';
  });

export const getLanguageName = (language: string, code: string) => {
  if (code) {
    try {
      return new Intl.DisplayNames([language], { type: 'language' }).of(code);
    } catch (err) {
      return '';
    }
  }
  return '';
};

export const openAzurePortal = async () => {
  window.open(AZURE_PORTAL_URL, '_blank');
};

export const isValidURL = (url: string) => {
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
  return regex.test(url);
};

export const isPublicRoute = (): boolean => {
  if (PUBLIC_ROUTES.includes(window.location.pathname)) {
    return true;
  }
  return false;
};

export const getBrowserLanguage = (): string => {
  return navigator?.language?.split('-')?.[0];
};
// This function is removed from Conversation which is being used for Conversation box height .Place here to ensure that it may be get helpful in future
// const getConversationBoxHeight = () => {
//   const isDocumentViewerOpen = chatContexts[chatId]?.isDocumentViewerOpen;

//   return {
//     [theme.breakpoints.up('xl')]: {
//       height: isDocumentViewerOpen ? `calc(100% - 190px)` : baseHeight
//     },
//     [theme.breakpoints.down('xl')]: {
//       height: isDocumentViewerOpen ? `calc(100% - 200px)` : baseHeight
//     }
//   };
// };

export const isSupportedFileType = (file: File) =>
  [
    ...(Object.values(SUPPORTED_IMAGE_FORMATS) as string[]),
    ...(Object.values(SUPPORTED_UPLOAD_FILES_FORMAT) as string[])
  ].includes(file.type);

export const isFileSizeValid = (file: File) => file.size <= MAX_CHAT_FILE_SIZE;

export const validateFiles = (
  selectedFiles: ChatAttachedFileProps[],
  uploadedFiles: ChatAttachedFileProps[],
  transactionId: string,
  dispatch: AppDispatch,
  t: TFunction<'translation', undefined>
) => {
  return selectedFiles
    .map((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const transactionPath = `${transactionId}/${file.name}`;

      const isFileSupported = isSupportedFileType(file);
      const isFileValid = isFileSizeValid(file);
      const isFileNameLengthValid = areFilesValid(Array.of(file), dispatch, t);
      const isDuplicateFile = uploadedFiles.some(
        (uploadedFile) => uploadedFile.name === file.name
      );

      if (!isFileValid) {
        setTimeout(
          () =>
            dispatch(
              setAlert({
                msg: t(T.maxChatFileSize, {
                  name: file.name.slice(0, 15) + '...',
                  size: MAX_CHAT_ATTACHMENT_SIZE
                }),
                type: ALERT.ERROR
              })
            ),
          200
        );
      }
      if (!isFileSupported) {
        setTimeout(
          () =>
            dispatch(
              setAlert({
                msg: t(T.unSupportedFile, {
                  name: file.name.slice(0, 15) + '...'
                }),
                type: ALERT.ERROR
              })
            ),
          200
        );
      }
      if (
        isFileSupported &&
        isFileValid &&
        isFileNameLengthValid &&
        !isDuplicateFile
      ) {
        const fileWithProps = file as File & {
          path: string;
          fileType: string;
          status_code: string;
        };
        fileWithProps.path = transactionPath;
        fileWithProps.fileType = fileExtension;
        fileWithProps.status_code = FILE_UPLOADING_STATUS.UPLOADING;
        return fileWithProps;
      }
    })
    .filter(Boolean) as (File & { path: string; fileType: string })[];
};

export const getBase64Src = (fileData: string) => {
  let mimeType: string;

  switch (true) {
    case fileData.startsWith('/9j'):
      mimeType = 'image/jpeg';
      break;
    case fileData.startsWith('iVBORw0KGgo'):
      mimeType = 'image/png';
      break;
    case fileData.startsWith('R0lGODdh'):
    case fileData.startsWith('R0lGODlh'):
      mimeType = 'image/gif';
      break;
    default:
      mimeType = 'image/jpeg';
  }

  return `data:${mimeType};base64,${fileData}`;
};

export const openAtlassianPortal = async () => {
  window.open(ATLASSIAN_ADMIN_PORTAL_URL, '_blank');
};

export const openAtlassianAppDeveloperPortal = async () => {
  window.open(ATLASSIAN_DEVELOPER_PORTAL_URL, '_blank');
};

export const getResourceProviderIcon = (type: RESOURCE_PROVIDERS) => {
  switch (type) {
    case RESOURCE_PROVIDERS.SHAREPOINT:
      return SharepointIcon;
    case RESOURCE_PROVIDERS.ONEDRIVE:
      return OneDriveIcon;
    case RESOURCE_PROVIDERS.CONFLUENCE:
      return ConfluenceIcon;
    default:
      return null;
  }
};
export const getModelData = (option): IAiModel => {
  return {
    id: option.id,
    name: option.name,
    displayName: option.display_name,
    description: option.description,
    comment: option.comment,
    commentDetails: option.comment_details,
    accuracy: option.accuracy,
    price: option.price,
    speed: option.speed,
    priority: option.priority,
    cutoffDate: option.cutoff_date,
    family: option.family,
    deploymentProvider: option.deployment_provider,
    imageAnalysis: option.image_analysis,
    isEnabled: option.is_enabled,
    region: option.region,
    tokenLimit: option.token_limit
  };
};

export function camelToSnakeCase(obj) {
  const convertKey = (key) => key.replace(/([A-Z])/g, '_$1').toLowerCase();
  let x = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [convertKey(key), value])
  );
  return x;
}
export const isAnImage = (file) =>
  Object.values(SUPPORTED_IMAGE_FORMATS).includes(
    file?.type || `image/${file?.original_format}`
  );

export const sanitizedFiles = (uploadedFiles, currentFilesUploadingCount) =>
  uploadedFiles
    .slice(-currentFilesUploadingCount)
    .map(({ uploadedFile, ...rest }) => rest);

export const getChatImgStyles = (showStatus: boolean) => ({
  borderRadius: showStatus ? '4px' : '8px',
  maxHeight: UPLOADED_IMAGE_ICON_HEIGHT,
  maxWidth: UPLOADED_IMAGE_ICON_WIDTH,
  height: showStatus ? ATTACH_FILE_ICON_HEIGHT : 'auto',
  width: showStatus ? ATTACH_FILE_ICON_WIDTH : 'auto'
});

export const getReactionCode = (lookups, reactionId: number) => {
  return lookups[LOOKUP.REACTION]?.find((item) => item.id === reactionId)?.code;
};

export const getReactionsKeyValue = (lookups) => {
  let reactions: { [code: string]: number } = {};
  lookups[LOOKUP.REACTION]?.forEach((item) => {
    reactions[item.code] = item.id;
  });
  return reactions;
};

export const getUploadedFileIds = () => {
  return window.localStorage.getItem(UPLOADED_FILE_IDS)
    ? decrypt_local(window.localStorage.getItem(UPLOADED_FILE_IDS))
    : null;
};
export const setUploadedFileIds = (fileIds: string) =>
  window.localStorage.setItem(UPLOADED_FILE_IDS, encrypt_local(fileIds));

export const removeUploadingFilesId = (fileId?: number) => {
  let files = getUploadedFileIds() ? JSON.parse(getUploadedFileIds()) : [];
  if (fileId) {
    files = files.filter((ids) => ids != fileId);
  } else {
    files = [];
  }

  setUploadedFileIds(JSON.stringify(files));
};

export const addFileToUploadingFileIds = (fileIds: number[]) => {
  let files = getUploadedFileIds() ? JSON.parse(getUploadedFileIds()) : [];
  fileIds.forEach((fileId) => {
    files.push(fileId);
  });
  setUploadedFileIds(JSON.stringify(files));
};

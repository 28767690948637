import {
  Box,
  Button,
  CircularProgress,
  Popover,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import { RESOURCE_TYPE } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import useResourceViewer from './useResourceViewer';

const MenuPopover = ({
  popoverTargetOption,
  isMorePopupOpen,
  menuAnchorElem,
  closeMenuPopover
}) => {
  const { viewResource, showInFolder, isFetching } = useResourceViewer({
    resource: popoverTargetOption
  });

  const handleView = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    viewResource(popoverTargetOption);
  };
  const handleShowInFolder = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    showInFolder(popoverTargetOption);
  };

  return (
    <Popover
      open={isMorePopupOpen}
      anchorEl={menuAnchorElem}
      onClose={closeMenuPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disablePortal
    >
      <Box
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Button
          variant="text"
          sx={{
            borderBottom: '1px solid rgba(170, 190, 222, 0.40)',
            justifyContent: isFetching ? 'center' : 'start'
          }}
          onClick={handleView}
        >
          <Typography color={'53669B'} fontSize={'14px'}>
            {isFetching ? (
              <CircularProgress size={14} disableShrink thickness={2} />
            ) : (
              t(T.view)
            )}
          </Typography>
        </Button>
        {popoverTargetOption &&
          popoverTargetOption.type_code === RESOURCE_TYPE.FILE && (
            <Button
              variant="text"
              onClick={handleShowInFolder}
              sx={{ justifyContent: 'start' }}
            >
              <Typography color={'53669B'} fontSize={'14px'}>
                {t(T.showInFolder)}
              </Typography>
            </Button>
          )}
      </Box>
    </Popover>
  );
};

export default MenuPopover;

import { Box, CircularProgress, List } from '@mui/material';
import _, { uniqBy } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  CHAT_LIST_PAGE_SIZE,
  chatListApiParams,
  groupChatsByDate
} from 'src/content/Chat/utils';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { setChats, updateChatsPageNumber } from 'src/redux/slices/chat';
import { RootState, useDispatch } from 'src/redux/store';
import { useLazyGetChatsQuery } from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { StringKeys } from 'src/types/base';
import { ChatData } from 'src/types/chat';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { SCROLL_TOLERANCE } from 'src/utils/utils';
import { HistoryPanelBox } from '../../styles';
import { SubMenuWrapper } from '../styles';
import GroupChat from './GroupChat';
import { ChatBox } from './styles';

interface HistoryPanelProps {
  expandedSections?: StringKeys;
  setExpandedSections?: (sections: StringKeys) => void;
  todayRef?: React.RefObject<HTMLDivElement>;
  thisWeekRef?: React.RefObject<HTMLDivElement>;
  thisMonthRef?: React.RefObject<HTMLDivElement>;
  sidebarRef?: React.RefObject<HTMLDivElement>;
}

const HistoryPanel: React.FC<HistoryPanelProps> = ({
  expandedSections,
  setExpandedSections,
  todayRef,
  thisWeekRef,
  thisMonthRef,
  sidebarRef
}) => {
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const dispatch = useDispatch();
  const { isSidebarOpen } = useContext(SidebarContext);
  const scrollRef = useRef<HTMLDivElement>(sidebarRef?.current || null);
  const { chats, selectedChat, chatsPageNumber } = useSelector(
    (state: RootState) => state.chat
  );
  const [chatList, setChatList] = useState([]);
  const [page, setPage] = useState<number>(chatsPageNumber);
  const [currentPageLength, setCurrentPageLength] = useState<number>(0);
  const [fetchChat, { data, isLoading }] = useLazyQuery<
    DataApiInputParams,
    StringKeys
  >({
    api: useLazyGetChatsQuery,
    errorContext: ErrorContext.CHAT
  });
  const navigate = useNavigate();

  const fetchChatList = () => {
    fetchChat({ params: chatListApiParams(page) });
  };

  const onTitleEdited = (editedId: number, updatedTitle: string) => {
    const updatedData = chats.map((item: ChatData) =>
      item.id === editedId ? { ...item, subject: updatedTitle } : item
    );
    dispatch(setChats(updatedData));
  };

  const onChatDeleted = (deletedId: number) => {
    let updatedData = [...chatList];
    _.remove(updatedData, (item: ChatData) => item.id === deletedId);
    dispatch(setChats(updatedData));
    if (deletedId === selectedChat)
      navigate('/chat', {
        replace: true
      });
    setPage(0);
  };

  const handleScroll = (event?: React.UIEvent<HTMLDivElement> | Event) => {
    let target = isSidebarOpen
      ? (event.target as HTMLDivElement)
      : scrollRef.current;
    if (!target) return;

    const { scrollTop, scrollHeight, clientHeight } = target;
    if (
      Math.abs(scrollTop - (scrollHeight - clientHeight)) <= SCROLL_TOLERANCE &&
      currentPageLength >= CHAT_LIST_PAGE_SIZE * (page + 1)
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      const element = sidebarRef?.current;
      if (element) {
        element.addEventListener('scroll', handleScroll);
      }
      return () => {
        if (element) {
          element.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [sidebarRef, isSidebarOpen, page, currentPageLength]);

  const groupedChats = useMemo(() => groupChatsByDate(chatList), [chatList]);

  useEffect(() => {
    if (data?.data) {
      const uniqueChats = uniqBy([...chats, ...data.data], 'id');
      dispatch(setChats(uniqueChats));
    }
  }, [data]);

  useEffect(() => {
    if (chats) {
      setChatList(chats);
      setCurrentPageLength(chats.length || 0);
    }
  }, [chats]);

  useEffect(() => {
    dispatch(updateChatsPageNumber(page));
    fetchChatList();
  }, [page]);

  const groupChatData = [
    { title: t(T.today), data: groupedChats.today, ref: todayRef },
    { title: t(T.thisWeek), data: groupedChats.thisWeek, ref: thisWeekRef },
    {
      title: t(T.thisMonth),
      data: groupedChats.thisMonthAndOlder,
      ref: thisMonthRef
    }
  ];

  if (expandedSections) {
    return (
      <>
        {groupChatData.map(({ title, data, ref }) => (
          <GroupChat
            key={title}
            groupTitle={title}
            chatGroup={data}
            onChatDeleted={onChatDeleted}
            onTitleEdited={onTitleEdited}
            expandedSections={expandedSections}
            setExpandedSections={setExpandedSections}
            categoryRef={ref}
          />
        ))}
      </>
    );
  }
  return (
    <>
      {isLoading ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          width={'100%'}
          height={'100%'}
        >
          <CircularProgress
            color="secondary"
            sx={{
              alignSelf: 'center'
            }}
          />
        </Box>
      ) : (
        <HistoryPanelBox>
          <ChatBox onScroll={handleScroll} ref={scrollRef}>
            <SubMenuWrapper
              sx={{
                position: 'relative',
                bgcolor: 'white'
              }}
            >
              <List component="div" disablePadding>
                {groupChatData.map(({ title, data }) => (
                  <GroupChat
                    key={title}
                    groupTitle={title}
                    chatGroup={data}
                    onChatDeleted={onChatDeleted}
                    onTitleEdited={onTitleEdited}
                  />
                ))}
              </List>
            </SubMenuWrapper>
          </ChatBox>
        </HistoryPanelBox>
      )}
    </>
  );
};

export default HistoryPanel;

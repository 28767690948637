import React, { useState } from 'react';
import { Button, Tabs, Tooltip } from '@mui/material';
import { VIEW_TYPE } from 'src/types/enum';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GridViewIcon from '@mui/icons-material/GridView';
import useMutation from 'src/hooks/useMutation';
import { useUpdateUserPreferencesMutation } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { setUserDataLibraryViewPreference } from 'src/redux/slices/data';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { StringKeys } from 'src/types/base';
import { useLocation, useNavigate } from 'react-router';
interface ViewSwitcherProps {
  filtersValue: StringKeys;
}

const ViewSwitcher: React.FC<ViewSwitcherProps> = ({ filtersValue }) => {
  const { t } = useTranslation();
  const { userDataLibraryViewPreference } = useSelector(
    (state: RootState) => state?.data
  );
  const [selectedTab, setSelectedTab] = React.useState(
    userDataLibraryViewPreference
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [updateUserViewSetting] = useMutation({
    api: useUpdateUserPreferencesMutation,
    errorContext: ErrorContext.GENERAL
  });

  const handleTabChange = (event: React.SyntheticEvent) => {
    const newView =
      selectedTab === VIEW_TYPE.GRID ? VIEW_TYPE.LIST : VIEW_TYPE.GRID;

    setSelectedTab(newView);
    dispatch(setUserDataLibraryViewPreference(newView));
    updateUserViewSetting({
      params: { params: { name: 'user_dl_view_preference', v: newView } }
    });
    navigate(location.pathname, {
      replace: true,
      state: filtersValue || {}
    });
  };

  const viewToolTip =
    selectedTab === VIEW_TYPE.LIST
      ? t(T.switchToGridView)
      : t(T.switchToListView);

  return (
    <Tooltip title={viewToolTip}>
      <Button
        sx={{
          minWidth: 0,
          padding: '8px 12px',
          border: '1px solid'
        }}
        variant="outlined"
        color="primary"
        onClick={handleTabChange}
      >
        {selectedTab === VIEW_TYPE.LIST ? (
          <GridViewIcon />
        ) : (
          <FormatListNumberedIcon />
        )}
      </Button>
    </Tooltip>
  );
};

export default ViewSwitcher;

import { Box, Button, ListItem } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { CONTENT_PADDING } from 'src/utils/constants';

export const getAdvancedDialogPaperStyles = (theme: Theme) => ({
  maxWidth: '1200px',
  width: '100%',
  minHeight: '341px',
  height: '90%',
  padding: '32px !important',
  border: `1px solid ${theme.colors.primaryAlt.main}`,
  borderRadius: '16px !important'
});

export const searchButtonStyles = {
  borderRadius: '30px',
  background: '#7BF3B9',
  width: '22px',
  height: '22px',
  position: 'absolute',
  padding: '3px'
};

export const searchInputFieldStyles = (theme: Theme) => ({
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    pl: '12px',
    pr: '28px',
    '& fieldset': {
      border: `1px solid ${theme.colors.primaryAlt.main}`
    }
  }
});

export const checkCircleIconStyles = {
  color: '#2AB32A',
  position: 'absolute',
  bottom: '20px',
  left: '85%',
  background: '#FFF',
  borderRadius: '50px'
};

export const filterPopoverPaperStyles = {
  width: '200px',
  border: '1px solid #CAD9F2',
  boxShadow: 'none',
  borderRadius: '6px',
  mt: '5px'
};

export const filterOptionStyles = {
  cursor: 'pointer',
  borderBottom: '1px solid rgba(202, 217, 242, 0.40)',
  '&:hover': {
    backgroundColor: '#f0f4ff'
  }
};

export const FilterButton = styled(Button)(() => ({
  borderRadius: '6px',
  border: '1px solid #CAD9F2',
  display: 'flex',
  height: '32px',
  padding: '10px 5px 10px 8px',
  alignItems: 'center',
  color: '#222F59',
  fontSize: '13px',
  fontWeight: 400
}));

export const AdvancedSearchItem = styled(ListItem)(() => ({
  borderBottom: '1px solid rgba(170, 190, 222, 0.40)',
  paddingBlock: '16px !important',
  paddingInline: '0px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F5F7FA',
    '.action-items': {
      visibility: 'visible'
    }
  }
}));

export const StartChatButton = styled(Button)(() => ({
  borderRadius: '4px',
  display: 'inline-flex',
  background: '#7BF3B9',
  cursor: 'pointer',
  position: 'absolute',
  right: '40px',
  height: '24px',
  ':hover': {
    background: '#68DDA5'
  }
}));

export const menuButtonStyles = {
  display: 'inline-flex',
  cursor: 'pointer',
  position: 'absolute',
  right: '10px',
  visibility: 'hidden'
};

export const AddSelectedFilesButton = styled(Button)(() => ({
  display: 'flex',
  height: '36px',
  padding: '8px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '4px',
  background: '#7BF3B9',
  marginLeft: '10px',
  '&:hover': {
    background: '#6AE8A7'
  }
}));

export const SearchPageWrapper = styled(Box)({
  padding: CONTENT_PADDING,
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  overflowY: 'auto'
});

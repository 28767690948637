import { useNavigate } from 'react-router';
import ResourceSearch from 'src/components/ResourceSearch';
import useResourceViewer from 'src/components/ResourceSearch/useResourceViewer';
import usePopover from 'src/hooks/usePopover';
import { RESOURCE_TYPE } from 'src/types/enum';
import { ChatFile } from '../Chat/types';

const Search = () => {
  const {
    anchorEl: fileSearchAnchorElem,
    isPopoverOpen: isFileSearchDialogOpen,
    closePopover: closeFileSearchDialog
  } = usePopover();
  const navigate = useNavigate();
  const { viewResource } = useResourceViewer({});

  const startChatOnSelectedFiles = (value: ChatFile[]) => {
    navigate('/chat', { state: { chatFiles: value } });
  };

  const handleSimpleSearchItemClick = (value: ChatFile) => {
    if (value.type_code !== RESOURCE_TYPE.TAG) viewResource(value);
  };

  return (
    <ResourceSearch
      onStartChatClick={startChatOnSelectedFiles}
      onSimpleSearchItemClick={handleSimpleSearchItemClick}
      fileSearchAnchorElem={fileSearchAnchorElem}
      isFileSearchDialogOpen={isFileSearchDialogOpen}
      closeFileSearchDialog={closeFileSearchDialog}
      isDataLibrary={true}
      isSearchPage={true}
    />
  );
};

export default Search;

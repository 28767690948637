import { ReactNode } from 'react';
import { IAiModel, StringKeys } from 'src/types/base';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import getItemData from './itemData';
import { IconResource } from 'src/redux/slices/data';

export interface FavouriteItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  name: string;
  display_name: string;
  id: number;
  workspaceName?: string;
  hasFilePermissions?: boolean;
  hasChatPermission?: boolean;
}

export interface FavouriteItems {
  item: FavouriteItem;
}

const getFavouriteItems = (
  userAppPermissions,
  userFavourites: any = [],
  favouriteType?: string,
  icons?: IconResource[],
  configuredModels?: IAiModel[]
): FavouriteItems[] | [] => {
  if (
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROOT_FOLDER_CONTENT]?.value
  ) {
    const favouriteArray = userFavourites?.filter(
      (favourite) => favourite?.item_type === favouriteType
    );
    return favouriteArray && favouriteArray.length
      ? favouriteArray[0]?.items?.map((favourite: StringKeys) => {
          const itemData = getItemData(
            favourite,
            favouriteType,
            icons,
            configuredModels
          );
          return {
            item: {
              name: favourite.display_name || favourite.name,
              id: favourite.item_id,
              icon: itemData.icon,
              link: itemData.link,
              hasFilePermissions: itemData?.hasPermission || false,
              hasChatPermission: itemData?.hasChatPermission || false
            }
          };
        })
      : [];
  }
  return [];
};

export default getFavouriteItems;

export const calculateListHeight = (favouriteResources: StringKeys[]) =>
  `${
    favouriteResources?.length && favouriteResources.length <= 3
      ? favouriteResources.length * 44.5
      : 133.5
  }px`;

import { WebPubSubClient } from '@azure/web-pubsub-client';
import { enc } from 'crypto-js';
import { createContext, FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { setAlert } from 'src/redux/slices/snackbar';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { useLazyGetUploadedFilesStatusQuery } from 'src/services/api';
import { decryptResponse } from 'src/services/utils';
import { StringKeys } from 'src/types/base';
import { AnalysisResponseProps } from 'src/types/docAnalyst';
import { ALERT } from 'src/types/enum';
import { KEY_DB64 } from 'src/utils/encryption';
import logger from 'src/utils/logger';
import { getUploadedFileIds, removeUploadingFilesId } from 'src/utils/utils';

type AnalysisDataProps = {
  id: number;
  status: string;
};

type AzureWebPubSubUserContextType = {
  connectionId: string;
  processedData: StringKeys[];
  syncedData: StringKeys;
  analysisResponse: AnalysisResponseProps;
  runningAnalysisData: AnalysisDataProps;
  resetContext: () => void;
};

export const AzureWebPubSubUserContext =
  createContext<AzureWebPubSubUserContextType>(
    {} as AzureWebPubSubUserContextType
  );

export const AzureWebPubSubUserProvider: FC = ({ children }) => {
  const [pubsubClient, setPubsubClient] = useState<any>(null);
  const [connectionId, setConnectionId] = useState<string>();
  const { azureWebPubSubInfo } = useSelector((state) => state.auth);
  const [processedData, setProcessedData] = useState<StringKeys[]>([]);
  const [syncedData, setSyncedData] = useState<StringKeys>({});
  const { tenant_info } = useSelector((state: RootState) => state.library);
  const [fetchUploadedFilesStatus] = useLazyQuery({
    api: useLazyGetUploadedFilesStatusQuery
  });
  const [analysisResponse, setAnalysisResponse] =
    useState<AnalysisResponseProps>();
  const [runningAnalysisData, setRunningAnalysisData] =
    useState<AnalysisDataProps>();
  const isMounted = useRef(true);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const wpsClient = new WebPubSubClient(azureWebPubSubInfo.url);
    wpsClient.on('connected', (e) => {
      logger.log('connected user wps connection', e.connectionId);
      setConnectionId(e.connectionId);
      setPubsubClient(wpsClient);
    });
    wpsClient.on('disconnected', (e) => {
      logger.log('disconnected user wps connection', e.connectionId);
      setConnectionId(undefined);
      setPubsubClient(null);
    });
    wpsClient.on('server-message', ({ message }: any) => {
      const KEY_BDB64 = enc.Utf8.parse(KEY_DB64.slice(0, 32));
      const data = decryptResponse(message, KEY_BDB64);
      if (data?.event_type === 'REFRESH_TOKEN') {
        dispatch(
          setAlert({
            msg: 'Account type updated; Reloading application in 3 seconds.',
            type: ALERT.WARNING
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (data.event_type === 'DOC_ANALYSIS_QUESTIONS_EVENT') {
        setAnalysisResponse(data?.data);
      } else if (data.event_type === 'DOC_ANALYSIS_EVENT') {
        setRunningAnalysisData({
          id: data?.data?.id,
          status: data?.data?.status_code
        });
      }
    });

    wpsClient.on('group-message', ({ message }: any) => {
      onGroupMessage(message);
    });

    wpsClient.start();
    return () => {
      try {
        wpsClient?.leaveGroup(
          `data-library-${tenant_info['default_namespace']['id']}`
        );
      } catch (error) {
        logger.error('Error in leaving group', error);
      }
      isMounted.current = false;
      wpsClient?.stop();
    };
  }, [azureWebPubSubInfo.url]);

  useEffect(() => {
    if (connectionId && pubsubClient) {
      let groupName = `data-library-${tenant_info['default_namespace']['id']}`;
      pubsubClient?.joinGroup(groupName);

      const uploadingFileIds = getUploadedFileIds()
        ? JSON.parse(getUploadedFileIds())
        : [];

      if (uploadingFileIds.length) {
        fetchUploadedFilesStatus({
          params: {
            params: { file_ids: uploadingFileIds }
          },
          successCallback(data) {
            if (data.length > 0) {
              setProcessedData(data);
              removeUploadingFilesId();
            }
          }
        });
      }
    }
  }, [connectionId, pubsubClient]);

  const resetContext = () => {
    if (isMounted.current) {
      setProcessedData([]);
      setSyncedData({});
    }
  };

  const onGroupMessage = (message) => {
    const KEY_BDB64 = enc.Utf8.parse(KEY_DB64.slice(0, 32));
    const data = decryptResponse(message, KEY_BDB64);
    if (data?.event_type == 'FILE_PROCESSING_CSC_EVENT') {
      setProcessedData(data?.state_change);
    } else if (data?.event_type == 'FILE_SYNC_CSC_EVENT') {
      setSyncedData(data?.state_change);
    }
  };

  return (
    <AzureWebPubSubUserContext.Provider
      value={{
        connectionId,
        processedData,
        resetContext,
        syncedData,
        analysisResponse,
        runningAnalysisData
      }}
    >
      {children}
    </AzureWebPubSubUserContext.Provider>
  );
};

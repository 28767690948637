import styled from '@emotion/styled';
import { Link } from '@mui/material';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { getEncryptParams } from 'src/keycloak';
import { getParam, getPayload } from 'src/utils/encryption';
import {
  getEncryptedFile,
  readFileAsBuffer
} from 'src/utils/files/file_encryption';

export const MAX_FILE_SIZE = 25 * 1024 * 1024;
export const MAX_FILES = 5;
export const MAX_SUBJECT_LENGTH = 100;
export const MAX_MESSAGE_LENGTH = 2500;

export enum HelpAndSupportTabs {
  CONTACT_SUPPORT = 'contactSupport',
  REPORT_ISSUE = 'reportIssue',
  SUGGEST_IMPROVEMENTS = 'suggestImprovements'
}

export const createFormData = async (
  data: any,
  attachedFiles: File[],
  selectedTab: string
) => {
  const formData = new FormData();

  if (IS_ENCRYPTION_ENABLED) {
    formData.append('payload', getPayload('', getEncryptParams()));
  }

  formData.append('type', getParam(selectedTab));
  formData.append('subject', getParam(data.subject));
  formData.append('message', getParam(data.message));

  for (const file of attachedFiles) {
    const uuidFile = new File([file], file.name, {
      type: file.type,
      lastModified: file.lastModified
    });

    if (IS_ENCRYPTION_ENABLED) {
      const arrayBufferData: any = await readFileAsBuffer(uuidFile);
      const encryptedFile = await getEncryptedFile(
        arrayBufferData,
        uuidFile.name,
        uuidFile.type
      );
      formData.append('file', encryptedFile);
    } else {
      formData.append('file', uuidFile);
    }
  }

  return formData;
};

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  alpha,
  Autocomplete,
  Paper,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { StyledPopper } from './styles';

interface CustomAutoCompleteProps {
  onChange: (event: any, value: any) => void;
  label?: string;
  placeholder?: string;
  dataSource: any[];
  optionLabel?: string;
  value: any;
  freeSolo?: boolean;
  hideClearIcon?: boolean;
  multiple?: boolean;
  customLabel?: boolean;
  disableSearch?: boolean;
  limitTags?: number;
  popperWidth?: string | number;
  readOnly?: boolean;
  optionStyles?: boolean;
  chipSize?: 'small' | 'medium';
  popperMaxHeight?: string | number;
  disabledInputStroke?: string;
  hideEndAdornment?: boolean;
  defaultOptions?: boolean;
  isDropdown?: boolean;
  [z: string]: any;
}
const CustomAutoComplete = ({
  onChange,
  label = '',
  customLabel = false,
  optionLabel = '',
  placeholder = '',
  dataSource,
  value,
  freeSolo = false,
  hideClearIcon = false,
  multiple = true,
  disableSearch = false,
  limitTags = 1,
  popperWidth = null,
  readOnly = false,
  optionStyles,
  size = 'small',
  chipSize = 'small',
  popperMaxHeight,
  disabledInputStroke = '#223354',
  hideEndAdornment = false,
  defaultOptions = true,
  isDropdown = false,
  ...rest
}: CustomAutoCompleteProps) => {
  const theme = useTheme();
  const { sx, ...restProps } = rest;
  const [inputValue, setInputValue] = useState<string>('');
  const { t } = useTranslation();

  const PopperComponent = useCallback((props) => {
    return (
      <StyledPopper
        optionStyles={optionStyles}
        maxHeight={popperMaxHeight}
        {...props}
      />
    );
  }, []);

  const handleInputChangeInternal: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setInputValue(event.target.value);
  };

  const PaperComponent = useCallback(
    (props) => (
      <Paper {...props} style={{ ...props.style, width: popperWidth }} />
    ),
    [popperWidth]
  );

  return (
    <>
      {customLabel && (
        <Typography
          sx={{
            marginBottom: '5px',
            marginLeft: '2px',
            color: '#575757',
            fontSize: '13px'
          }}
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        multiple={multiple}
        id={label}
        size={size}
        options={inputValue || defaultOptions ? dataSource : []}
        disableClearable={hideClearIcon}
        freeSolo={freeSolo}
        getOptionLabel={(option: any) =>
          typeof option === 'string'
            ? option
            : optionLabel
            ? option[optionLabel]
            : option?.name
        }
        value={value}
        limitTags={limitTags}
        onChange={onChange}
        PopperComponent={PopperComponent}
        sx={{
          fontSize: theme.typography.pxToRem(12),
          position: 'relative',
          borderRadius: theme.general.borderRadius,
          ...sx
        }}
        autoHighlight={!disableSearch}
        ChipProps={{
          size: chipSize
        }}
        componentsProps={{
          clearIndicator: {
            sx: {
              mr: 0.01,
              color: theme.colors.alpha.black[100],
              backgroundColor: 'transparent',
              '&:hover': {
                color: theme.colors.alpha.black[100],
                backgroundColor: theme.colors.alpha.black[10]
              }
            }
          }
        }}
        PaperComponent={PaperComponent}
        {...(!defaultOptions && {
          open: !!inputValue && dataSource.length > 0
        })}
        noOptionsText={inputValue || defaultOptions ? t(T.noOptions) : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={customLabel ? null : null}
            maxRows={1}
            size={size}
            onChange={handleInputChangeInternal}
            sx={(theme) => ({
              ...(disableSearch && {
                '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
                  {
                    color: 'green',
                    borderColor: alpha(theme.colors.alpha.black[100], 0.23)
                  },
                input: {
                  '&.Mui-disabled': {
                    textFillColor: disabledInputStroke
                  },
                  cursor: 'pointer'
                },
                '& .MuiOutlinedInput-root': {
                  cursor: 'pointer'
                }
              })
            })}
            placeholder={value?.length ? '' : placeholder}
            InputProps={{
              ...params.InputProps,
              readOnly: readOnly,
              style: { caretColor: isDropdown ? 'transparent' : 'inherit' }
            }}
            InputLabelProps={{ shrink: customLabel ? false : true }}
          />
        )}
        popupIcon={
          hideEndAdornment ? null : <KeyboardArrowDownIcon fontSize={size} />
        }
        {...restProps}
      />
    </>
  );
};

export default CustomAutoComplete;

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { DocumentsContextProvider } from 'src/contexts/FileManagerContext';
import { toggleChatHistoryPan } from 'src/redux/slices/chat';
import { RootState, useSelector } from 'src/redux/store';
import SidebarBottomSection from './BottomSection';
import SidebarMidSection from './Favourites';
import HistoryPanel from './History/HistoryPanel';
import { CustomDivider } from './styles';
import SidebarTopSection from './TopSection';

function Sidebar() {
  const theme = useTheme();
  const { showChatHistoryPanel } = useSelector(
    (state: RootState) => state.chat
  );
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const regex = /^\/chat\/\d+$/;
    const isChatPath = regex.test(pathname);
    if (isChatPath) dispatch(toggleChatHistoryPan(false));
  }, []);

  return (
    <Box
      maxHeight={'100vh'}
      height={'100vh'}
      sx={{
        backgroundColor: theme.sidebar.background
      }}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" flexDirection="column">
        <SidebarTopSection />
      </Box>
      {showChatHistoryPanel && isMobile ? (
        <HistoryPanel />
      ) : (
        <>
          <DocumentsContextProvider>
            <SidebarMidSection />
          </DocumentsContextProvider>
          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <CustomDivider sx={{ borderTop: '1px solid #ffffff40' }} />
            <SidebarBottomSection />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Sidebar;

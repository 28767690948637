import { getEncryptParams } from 'src/keycloak';
import { DataApiInputParams } from 'src/types/api';
import {
  Builder,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';

export default (builder: Builder) => {
  return {
    getConfluenceContent: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `providers/confluence_provider?${getQueryString(
          arg.params.params
        )}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    })
  };
};

import { Clear, Done, FilterList } from '@mui/icons-material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Popover,
  Tooltip,
  Typography,
  TypographyProps,
  styled,
  useTheme
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomAutoComplete from 'src/components/CustomAutoComplete';
import Select from 'src/components/Select';
import { StringKeys } from 'src/types/base';
import {
  FilterComponents,
  FilterDatasource,
  FilterElementTypes,
  FilterSelect,
  SelectItem
} from 'src/types/custom_component';
import SearchableAutoComplete from '../SearchableAutoComplete';
import TextField from '../TextField';
import {
  SelectAllItem,
  getDefaultValues,
  getSelectedFilters,
  getValues
} from './utils';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

import { ReactComponent as AlphabeticAscIcon } from 'src/assets/assistantIcons/alphabetic_order_asc_icon.svg';
import { ReactComponent as AlphabeticDescIcon } from 'src/assets/assistantIcons/alphabetic_order_desc_icon.svg';
import { ReactComponent as NewestToOldestIcon } from 'src/assets/assistantIcons/newest_to_oldest_icon.svg';
import { ReactComponent as OldestToNewestIcon } from 'src/assets/assistantIcons/oldest_to_newest_icon.svg';
import {
  NAME_ASC,
  NAME_DESC,
  NEWEST_TO_OLDEST,
  OLDEST_TO_NEWEST
} from 'src/content/Assistant/utils/utils';

const GridContainer = styled(Grid)`
  width: 100%;
  padding: 15px;
  font: #000;
`;

const StyledLink = styled(Link)({
  color: 'primary',
  underline: 'always',
  fontWeight: 'bold',
  cursor: 'pointer'
});

const FilterPopup = ({
  componentsValue,
  setComponentValue,
  hideFilterButton = false,
  filterIcon = <FilterList />,
  showClearFilterBtn,
  showBadge = true,
  ...props
}: FilterProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState<StringKeys>({});
  const [dataSources, setDataSources] = useState<FilterDatasource>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover-actions' : undefined;

  const [selectedFilterIcon, setSelectedFilterIcon] = useState(filterIcon);

  const searchComponent = useMemo(() => {
    return props.elementsList.find(
      (element) => element.type === FilterElementTypes.Text
    );
  }, [props.elementsList]);

  const handleOpenPopup = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const isFiltersSelected = useMemo((): boolean => {
    const { isFilterSelected } = getSelectedFilters(componentsValue);
    return isFilterSelected;
  }, [componentsValue]);

  useEffect(() => {
    let componentsValue: StringKeys = {};
    let textInputs: StringKeys = {};
    let dataSources: FilterDatasource = {};

    props.elementsList.forEach((item: FilterComponents) => {
      componentsValue[item['dataField']] = getDefaultValues(item);
      if (item.type === FilterElementTypes.Text) {
        textInputs[item['dataField']] = item['defaultValue'] || '';
      }

      if (
        item.type === FilterElementTypes.AutoComplete ||
        item.type === FilterElementTypes.MultiAutoComplete
      ) {
        dataSources[item['dataField']] = item.filteredDatasource
          ? item.filteredDatasource
          : item.datasource || [];
      }
      if (item.type === FilterElementTypes.Select) {
        componentsValue[item['dataField']] =
          item['defaultValue'] || SelectAllItem;
      }
      if (item.type === FilterElementTypes.DropDown) {
        componentsValue[item['dataField']] = item['defaultValue'] || '';
      }
    });
    setComponentValue(componentsValue);
    setTextValue(textInputs);
    setDataSources(dataSources);

    setLoading(false);
  }, [props.elementsList]);

  useEffect(() => {
    if (!isLoading) {
      executeSearch();
    }
  }, [componentsValue, isLoading]);

  const executeSearch = () => {
    let filteringKeys: { [z: string]: any } = {};

    getValues(componentsValue, filteringKeys, props.elementsList);
    props.onSearch(filteringKeys);
  };

  const onClickReset = () => {
    let initialObject: { [name: string]: string | boolean } = {};
    let initialText: { [name: string]: string } = {};
    let defaultState: { [name: string]: string | boolean } = {};
    const defaults: { [name: string]: string } = {};
    let dataSources: FilterDatasource = {};
    Object.keys(componentsValue).forEach((key: string) => {
      let item: FilterComponents | undefined = props.elementsList.find(
        (item) => item.dataField === key
      );
      if (item) {
        initialObject[key] = getDefaultValues(item, props.forceReset);
        if (getDefaultValues(item, props.forceReset)) {
          defaults[key] = getDefaultValues(item, props.forceReset);
        }
        if (item.type === FilterElementTypes.Text) {
          initialText[key] = '';
        }
        if (
          item.type === FilterElementTypes.MultiAutoComplete ||
          item.type === FilterElementTypes.AutoComplete
        ) {
          dataSources[item['dataField']] = item.filteredDatasource
            ? item.filteredDatasource
            : item.datasource || [];
        }
        if (item.type === FilterElementTypes.Select) {
          dataSources[item['dataField']] = item.datasource || [];
        }
        if (item.type === FilterElementTypes.DropDown) {
          dataSources[item['dataField']] = item.datasource || [];
        }
      }
    });
    getValues(defaults, defaultState, props.elementsList);
    defaultState = { ...defaultState, s: componentsValue['s'] };
    initialObject = { ...initialObject, s: componentsValue['s'] };
    setComponentValue(initialObject);
    setDataSources(dataSources);
    props.onReset(defaultState);
  };

  const updateState = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = event.target.value;
    let type: string = getItemType(event.target.id);

    setComponentValue((prevState: any) => {
      return { ...prevState, [event.target.id]: value };
    });
  };

  const clearSearch = (key) => {
    setTextValue({ [key]: '' });
    setComponentValue((prevState: any) => {
      return { ...prevState, [key]: '' };
    });
  };

  const onChangeSelect = (event: any, newValue: FilterSelect, id: string) => {
    setComponentValue((prevState: any) => {
      return {
        ...prevState,
        [id]: newValue
      };
    });
  };

  const getItemType = (key: string): FilterElementTypes | '' => {
    let item: FilterComponents | undefined = props.elementsList.find(
      (item) => item.dataField === key
    );
    if (item) {
      return item.type;
    }
    return '';
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      updateState(e);
    }
  };

  const renderInputField = (item: FilterComponents) => {
    let InputProps = item.showIcon
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon color="disabled" />
            </InputAdornment>
          ),
          endAdornment: !!textValue?.s?.length && (
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => clearSearch(item.dataField)}
            >
              <Clear fontSize="small" />
            </IconButton>
          )
        }
      : {};

    const textProps = {
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue((prevState: any) => {
          return { ...prevState, [event.target.id]: event.target.value };
        });
        if (!event.target.value.length) {
          updateState(event);
        }
      },
      inputProps: {
        onKeyDown: handleKeyDown
      },
      value: textValue[item['dataField']]
    };

    return (
      <TextField
        disabled={props.disableFilters}
        type={item.type}
        id={item.dataField}
        name={item.name}
        variant="outlined"
        size="small"
        sx={{
          width: item.elementWidth,
          '& .MuiOutlinedInput-root': {
            height: '43px'
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        }}
        placeholder={t(item.placeholder)}
        InputLabelProps={{ style: { fontSize: 12 } }}
        InputProps={InputProps}
        {...textProps}
      />
    );
  };

  const renderMultiAutoComplete = (item: FilterComponents) => {
    const handleChange = (event: any, newValue: SelectItem) => {
      setComponentValue((prevState: any) => {
        return {
          ...prevState,
          [item.dataField]: newValue
        };
      });
    };
    return (
      <Box>
        <CustomAutoComplete
          id={item.dataField}
          dataSource={[...dataSources[item.dataField]]}
          onChange={handleChange}
          width={item.elementWidth}
          value={componentsValue[item.dataField]}
          sx={{
            width: item.elementWidth
          }}
          placeholder={t(item.placeholder)}
          hideEndAdornment={item.hideEndAdornment}
          defaultOptions={item.defaultOptions}
        />
      </Box>
    );
  };

  const renderAutoComplete = (item: FilterComponents) => {
    return (
      <Box>
        <CustomAutoComplete
          id={item.dataField}
          dataSource={[...(dataSources[item.dataField] || [])]}
          onChange={(event, newValue: SelectItem) =>
            onChangeSelect(event, newValue, item.dataField)
          }
          width={item.elementWidth}
          sx={{
            width: item.elementWidth
          }}
          value={componentsValue?.[item.dataField]}
          placeholder={t(item.placeholder)}
          multiple={false}
          disableSearch={item.disableSearch}
          hideEndAdornment={item.hideEndAdornment}
          isDropdown={item.isDropdown}
        />
      </Box>
    );
  };

  const renderSearchAutoComplete = (item: FilterComponents) => {
    const handleChange = (e, newValue) =>
      setComponentValue((prevState: any) => {
        return {
          ...prevState,
          [item.dataField]: newValue
        };
      });
    return (
      <SearchableAutoComplete
        searchApi={item.searchApi}
        placeholder={t(item.placeholder)}
        optionLabel={item.labelKey}
        onChange={handleChange}
        valueKey={item.valueKey}
        sx={{
          width: item.elementWidth
        }}
        value={componentsValue[item.dataField] || null}
        hideEndAdornment={item.hideEndAdornment}
      />
    );
  };

  const getSelectedFilterIcon = (
    dataSources: FilterSelect[] | undefined,
    selectedItem: number | undefined,
    code?: string
  ) => {
    let itemCode = code;
    if (!code) {
      itemCode = dataSources.find((item) => item.id === selectedItem)?.code;
    }

    switch (itemCode) {
      case NEWEST_TO_OLDEST:
        return <NewestToOldestIcon />;
      case OLDEST_TO_NEWEST:
        return <OldestToNewestIcon />;
      case NAME_ASC:
        return <AlphabeticAscIcon />;
      case NAME_DESC:
        return <AlphabeticDescIcon />;

      default:
        break;
    }
  };

  const renderSelect = (item: FilterComponents) => {
    const handleChange = (e: any) =>
      setComponentValue((prevState: any) => {
        return {
          ...prevState,
          [item.dataField]: [SelectAllItem, ...item.datasource].find(
            (item) => item.id === e.target.value
          )
        };
      });
    return (
      <Select
        id={item.dataField}
        value={componentsValue?.[item.dataField]?.id}
        width={item.elementWidth}
        onChange={handleChange}
        dataSource={[SelectAllItem, ...item.datasource]}
      />
    );
  };

  const renderMenuDropdown = (item: FilterComponents) => {
    const handleChange = (
      e: React.MouseEvent<HTMLSpanElement>,
      selectedItem: number
    ) => {
      setComponentValue((prevState: StringKeys) => {
        return {
          ...prevState,
          [item.dataField]: item.datasource.find(
            (item) => item.id === selectedItem
          )
        };
      });

      setSelectedFilterIcon(
        getSelectedFilterIcon(item.datasource, selectedItem)
      );

      handleClose(e);
    };

    return (
      <Box width={'105%'}>
        {item.datasource?.map((it, index) => (
          <Box key={index} onClick={(e) => handleChange(e, it.id)}>
            <Box
              padding={'5px 16px 5px 16px'}
              lineHeight={'10px'}
              sx={{
                backgroundColor:
                  it.name === componentsValue[item.dataField]?.name
                    ? '#f1f1f1'
                    : 'fff',
                cursor: 'pointer',
                display: 'flex',
                '&:hover': {
                  backgroundColor: '#f1f1f1'
                }
              }}
            >
              {getSelectedFilterIcon(undefined, undefined, it?.code)}
              <Typography
                variant="body1"
                sx={{
                  marginLeft: '5px'
                }}
              >
                {it?.name}
              </Typography>
            </Box>
            <Divider />
          </Box>
        ))}
      </Box>
    );
  };

  const renderBadgeIcon = useMemo(() => {
    return (
      <Badge
        invisible={showBadge ? !isFiltersSelected : true}
        badgeContent={<Done sx={{ fontSize: '10px' }} />}
        color="success"
      >
        <Tooltip title={t(T.filters)}>
          <span>
            <Button
              sx={{
                minWidth: 0,
                padding: '8px 12px',
                border: '1px solid'
              }}
              disabled={props.disableFilters}
              variant="outlined"
              color="primary"
              onClick={handleOpenPopup}
            >
              {selectedFilterIcon}
            </Button>
          </span>
        </Tooltip>
      </Badge>
    );
  }, [selectedFilterIcon, isFiltersSelected, props.disableFilters]);

  return isLoading ? null : (
    <>
      <Grid
        item
        flexGrow={{
          xs: 1,
          sm: 'initial'
        }}
      >
        {searchComponent && renderInputField(searchComponent)}
      </Grid>
      {!hideFilterButton && (
        <Grid
          item
          sx={{
            [theme.breakpoints.down('sm')]: {
              display: 'none'
            }
          }}
        >
          {renderBadgeIcon}
          <Popover
            id={id}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <GridContainer
              container
              style={{
                padding: props.elementsList?.filter(
                  (item) => item.type === FilterElementTypes.DropDown
                )?.[0]
                  ? 0
                  : 20
              }}
            >
              <Grid
                display={'flex'}
                flexDirection={'column'}
                container
                spacing={0.5}
                style={{
                  width: props?.isDropDown ? '100%' : 'calc(100% - 80px)'
                }}
              >
                {props.Button && (
                  <Grid item style={{ paddingBottom: 6 }}>
                    {props.Button}
                  </Grid>
                )}
                {props.elementsList.map(
                  (item: FilterComponents, index: number) => {
                    return item.type === FilterElementTypes.AutoComplete ? (
                      <Grid item key={index} style={{ paddingBottom: 2 }}>
                        {item.showLabel && (
                          <Typography
                            color={'primary'}
                            sx={{ marginBottom: '4px' }}
                          >
                            {item.label}
                          </Typography>
                        )}
                        {renderAutoComplete(item)}
                      </Grid>
                    ) : item.type === FilterElementTypes.MultiAutoComplete ? (
                      <Grid item key={index} style={{ paddingBottom: 2 }}>
                        {item.showLabel && (
                          <Typography
                            color={'primary'}
                            sx={{ marginBottom: '4px' }}
                          >
                            {item.label}
                          </Typography>
                        )}
                        {renderMultiAutoComplete(item)}
                      </Grid>
                    ) : item.type === FilterElementTypes.SearchAutoComplete ? (
                      <Grid item key={index} style={{ paddingBottom: 2 }}>
                        {item.showLabel && (
                          <Typography
                            color={'primary'}
                            sx={{ marginBottom: '4px' }}
                          >
                            {item.label}
                          </Typography>
                        )}
                        {renderSearchAutoComplete(item)}
                      </Grid>
                    ) : item.type === FilterElementTypes.Select ? (
                      <Grid item key={index} style={{ paddingBottom: 2 }}>
                        {item.showLabel && (
                          <Typography
                            color={'primary'}
                            sx={{ marginBottom: '4px' }}
                          >
                            {item.label}
                          </Typography>
                        )}
                        {renderSelect(item)}
                      </Grid>
                    ) : item.type === FilterElementTypes.DropDown ? (
                      <Grid item key={index}>
                        {renderMenuDropdown(item)}
                      </Grid>
                    ) : null;
                  }
                )}
                {showClearFilterBtn && (
                  <Grid
                    item
                    alignSelf={props?.isDropDown ? 'center' : 'end'}
                    sx={{
                      padding: props?.isDropDown ? '10px' : '0px',
                      paddingY: props?.isDropDown ? '5px' : '0px'
                    }}
                  >
                    <Typography variant="body2" align="center">
                      <StyledLink onClick={onClickReset}>
                        Clear Filter
                      </StyledLink>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </GridContainer>
          </Popover>
        </Grid>
      )}
    </>
  );
};

type FilterProps = {
  onSearch?: (selectedFields: { [z: string]: any }) => any;
  onReset: (initialObject: any) => void;
  forceReset?: boolean;
  elementsList: FilterComponents[];
  Button?: any;
  showFilters?: boolean;
  disableFilters?: boolean;
  componentsValue?: StringKeys;
  setComponentValue?: React.Dispatch<React.SetStateAction<StringKeys>>;
  hideFilterButton?: boolean;
  filterIcon?: React.ReactNode;
  isDropDown?: boolean;
  showClearFilterBtn?: boolean;
  showBadge?: boolean;
};

export default FilterPopup;

import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useContext } from 'react';
import NoDocumentsIcon from 'src/assets/no-documents.svg';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const AdvancedSearchFallback = ({
  isFetching,
  filesData,
  filesOptions,
  searchValue,
  isSearchPage,
  MemoizedSearchBar
}) => {
  const { isSidebarOpen } = useContext(SidebarContext);
  if (isFetching) {
    return (
      <>
        {[1, 2, 3, 4, 5, 6].map((_) => (
          <Stack key={_} spacing={2} my={'6px'} sx={{ overflowY: 'hidden' }}>
            <Box display={'flex'} alignItems={'center'}>
              <Stack spacing={1} my={'10px'} width={'100%'}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="200px"
                  height={30}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={50}
                />
              </Stack>
            </Box>
          </Stack>
        ))}
      </>
    );
  }
  if (!filesOptions.length) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: isSearchPage ? (isSidebarOpen ? '57%' : '50%') : '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Box
          src={NoDocumentsIcon}
          component={'img'}
          height={200}
          width={250}
          margin={'auto'}
          style={{ display: 'block' }}
        />
        <Typography
          variant="body1"
          color={'#4A4A4A'}
          fontSize={'20px'}
          fontWeight={600}
          textAlign={'center'}
        >
          {filesData
            ? `${t(T.noResultsTitle)} “${searchValue}”`
            : t(T.advancedSearchTitle)}
        </Typography>
        <Typography
          variant="body1"
          color={'#4A4A4A'}
          fontSize={'20px'}
          fontWeight={400}
          textAlign={'center'}
          marginBottom={isSearchPage ? '32px' : 0}
        >
          {filesData
            ? t(T.noResultsDescription)
            : t(T.advancedSearchDescription)}
        </Typography>
        {isSearchPage && searchValue?.length === 0 && MemoizedSearchBar}
      </Box>
    );
  } else return null;
};

export default AdvancedSearchFallback;

import {
  Box,
  Button,
  IconButton,
  ListItem,
  Popover,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as Warning } from 'src/assets/assistantIcons/Warning.svg';
import { ReactComponent as StarRateIcon } from 'src/assets/documentIcons/StarRateIcon.svg';
import { ReactComponent as MoreIcon } from 'src/assets/sidebarIcon/white-more-icon.svg';
import RouterLink from 'src/components/NavLink';
import TooltipOnDisabled from 'src/components/TooltipOnDisabled';
import { ActionButton } from 'src/content/Documents/utils/styles';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';
import { RootState } from 'src/redux/store';
import { StringKeys } from 'src/types/base';
import { FAVOURITE_TYPE } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { sidebarIconHeight, sidebarIconWidth } from '../../styles';

interface SidebarFavouriteItemProps {
  link?: string;
  icon?: any;
  active?: boolean;
  name: string;
  id: number;
  workspaceName?: string;
  handleStartChatClick: (
    id: number,
    name: string,
    workspaceName?: string
  ) => void;
  handleFavouriteClick: (id: number, isFavourite: boolean) => void;
  t: TFunction<'translation', undefined>;
  isLink?: boolean;
  favouriteType: string;
  hasFilePermissions?: boolean;
  hasChatPermission?: boolean;
  handleFilePermissionModal?: (id: number) => void;
}

const SidebarFavouriteItem: FC<SidebarFavouriteItemProps> = ({
  link,
  icon: Icon,
  active,
  name,
  id,
  handleStartChatClick,
  handleFavouriteClick,
  workspaceName,
  t,
  favouriteType,
  isLink = true,
  hasFilePermissions = false,
  hasChatPermission = false,
  handleFilePermissionModal,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { userFavourites } = useSelector((state: RootState) => state.data);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = open ? 'more-actions' : undefined;

  const handleStartChat = (e: any) => {
    e.preventDefault();
    handleStartChatClick?.(id, name, workspaceName);
  };

  const openFilePermissionModal = (e) => {
    e.preventDefault();
    handleFilePermissionModal?.(id);
  };

  const favouritesArray = useMemo(
    () =>
      userFavourites?.filter(
        (favourite) => favourite?.item_type === favouriteType
      ),
    [userFavourites]
  );

  const isFavourite = useMemo(
    () =>
      favouritesArray[0]?.items?.some((fav: StringKeys) => fav?.item_id === id),
    [favouritesArray]
  );

  const handleAddFavourite = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleFavouriteClick(id, isFavourite);
    },
    [handleFavouriteClick, id, isFavourite]
  );
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    setIsHovered(false);
  }, []);

  const showStartChatButton = useMemo(() => {
    return (
      (favouriteType === FAVOURITE_TYPE.ASSISTANT && !hasFilePermissions) ||
      (favouriteType === FAVOURITE_TYPE.RESOURCE && hasChatPermission)
    );
  }, [favouriteType, hasChatPermission, hasFilePermissions]);

  const showWarningIcon = useMemo(() => {
    return (
      (hasFilePermissions && favouriteType === FAVOURITE_TYPE.ASSISTANT) ||
      (favouriteType === FAVOURITE_TYPE.RESOURCE && !hasChatPermission)
    );
  }, [favouriteType, hasChatPermission, hasFilePermissions]);

  return (
    <ListItem
      component="div"
      key={name}
      {...rest}
      disablePadding
      sx={{
        maxHeight: '32px'
      }}
    >
      <Button
        disableRipple
        component={isLink ? RouterLink : Box}
        {...(isLink && {
          to: link
        })}
        {...(!isLink && {
          onClick: hasFilePermissions
            ? openFilePermissionModal
            : handleStartChat
        })}
        sx={{
          maxHeight: '32px'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={handleClose}
        startIcon={
          Icon && (
            <Icon
              style={{
                fontSize: 16,
                height: sidebarIconHeight,
                width: sidebarIconWidth
              }}
            />
          )
        }
      >
        <CustomListItemText
          primaryText={name}
          sx={{ textOverflow: 'ellipsis' }}
        />
        {showWarningIcon && (
          <TooltipOnDisabled
            title={T.chatUnavailable}
            showTooltip={
              favouriteType === FAVOURITE_TYPE.RESOURCE && !hasChatPermission
            }
          >
            <Warning
              style={{
                width: '16px',
                height: '16px',
                marginLeft: '8px',
                marginBottom: '-2px',
                flexShrink: 0
              }}
            />
          </TooltipOnDisabled>
        )}
        {isHovered ? (
          <Box display={'flex'} flexDirection={'row'} gap={'4px'}>
            <IconButton
              title={t(T.moreActions)}
              onClick={handleClick}
              size="small"
            >
              <MoreIcon />
            </IconButton>
            <Popover
              id={popoverId}
              open={openPopover}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              anchorEl={anchorEl}
              onClose={handleClose}
              disablePortal={true}
              sx={{
                left: '100px'
              }}
            >
              <ActionButton
                style={{ background: 'white', height: '34px' }}
                onClick={handleAddFavourite}
              >
                <StarRateIcon className="starRateIcon" />
                <Typography
                  sx={{
                    marginLeft: 1,
                    color: 'black'
                  }}
                >
                  {t(T.unmarkFavourite)}
                </Typography>
              </ActionButton>
            </Popover>
            {showStartChatButton && (
              <Button
                title={t(T.startChat)}
                onClick={handleStartChat}
                size="small"
                variant="contained"
                sx={{
                  fontSize: '12px',
                  px: 0.8,
                  py: 0.5,
                  marginTop: '3.5px'
                }}
              >
                {t(T.startChat)}
              </Button>
            )}
          </Box>
        ) : null}
      </Button>
    </ListItem>
  );
};

SidebarFavouriteItem.propTypes = {
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  name: PropTypes.string.isRequired
};

SidebarFavouriteItem.defaultProps = {
  active: false
};

export default SidebarFavouriteItem;

import { List, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import SidebarAccordion from 'src/components/Accordion';
import { startChat } from 'src/content/Documents/utils/gridUtils';
import { favouriteToChatFileMapper } from 'src/content/Documents/utils/utils';
import DocumentsContext from 'src/contexts/FileManagerContext';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { FAVOURITE_TYPE } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { renderSidebarFavouriteItems } from '.';
import { MenuWrapper } from '../styles';
import getFavouriteItems, { FavouriteItems } from './items';

const FavouriteDataLibrary = ({
  expandedSections,
  setExpandedSections,
  workspacesRef
}) => {
  const location = useLocation();
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { userAppPermissions, userFavourites, isFavouritesFetching } =
    useSelector((state) => state.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const { isUpdatingFavourite, updateFavourite } = useContext(DocumentsContext);
  const menuItems = useMemo(
    () =>
      getFavouriteItems(
        userAppPermissions,
        userFavourites || [],
        FAVOURITE_TYPE.RESOURCE
      ),
    [userFavourites]
  );

  const handleStartChatClick = async (
    id: number,
    name: string,
    workspaceName?: string
  ) => {
    const routeState = location?.state?.documentContext;
    await startChat({
      documentContext: {
        id,
        name,
        workspaceName
      },
      dispatch,
      navigate,
      selectedChat,
      routeState,
      resourceType: FAVOURITE_TYPE.RESOURCE,
      chatFile: favouriteToChatFileMapper(
        userFavourites
          .find((favs) => favs.item_type === FAVOURITE_TYPE.RESOURCE)
          .items.find((fav) => fav.item_id === id)
      )
    });
  };

  const handleFavouriteClick = useCallback(
    (id: number, isFavourite: boolean) => {
      if (isUpdatingFavourite || isFavouritesFetching) return;
      dispatch(setIsFavouritesFetching(true));
      updateFavourite(id, !isFavourite);
    },
    [dispatch, isUpdatingFavourite, isFavouritesFetching]
  );

  if (isSidebarOpen && menuItems?.length) {
    return (
      <SidebarAccordion
        expanded={expandedSections.workspaces}
        onToggle={() =>
          setExpandedSections({
            ...expandedSections,
            workspaces: !expandedSections.workspaces
          })
        }
        heading={t(T.workspaces)}
        summaryRef={workspacesRef}
        sx={{ bgcolor: theme.sidebar.background }}
      >
        {menuItems.map((section: FavouriteItems, index: number) => (
          <MenuWrapper key={index}>
            <List
              component="div"
              onClick={isMobile ? toggleSidebar : undefined}
            >
              {renderSidebarFavouriteItems({
                item: section.item,
                path: location.pathname,
                handleStartChatClick: handleStartChatClick,
                handleFavouriteClick: handleFavouriteClick,
                t: t,
                favouriteType: FAVOURITE_TYPE?.RESOURCE,
                hasFilePermissions: section?.item?.hasFilePermissions,
                hasChatPermission: section?.item?.hasChatPermission
              })}
            </List>
          </MenuWrapper>
        ))}
      </SidebarAccordion>
    );
  } else {
    return <></>;
  }
};

export default FavouriteDataLibrary;

import { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  Typography,
  useTheme
} from '@mui/material';
import {
  useCreateRoleMutation,
  useLazyGetPermissionsQuery,
  useUpdateRoleMutation
} from 'src/services/api';
import Loader from 'src/components/Loader';
import { StringKeys } from 'src/types/base';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useDispatch } from 'react-redux';
import { setAlert } from 'src/redux/slices/snackbar';
import { ALERT, PERMISSION_TYPE } from 'src/types/enum';
import { Controller, useForm } from 'react-hook-form';
import { RESOURCE_ROLE_TEMPLATE } from './utils';
import TextField from 'src/components/TextField';
import DialogTitle from 'src/components/Dialog/DialogTitle';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import { ErrorContext } from 'src/utils/errorMappings';
import useMutation from 'src/hooks/useMutation';
import { IContentRole } from './types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  roleData?: IContentRole;
};

interface IFormValues {
  name: string;
  description: string;
  permissions: any[];
}

const AddRole = ({ isOpen, roleData, onClose }: Props) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, watch } = useForm<IFormValues>({
    defaultValues: {
      name: '',
      description: '',
      permissions: []
    },
    mode: 'onBlur'
  });

  const formValues = watch();

  const [
    fetchPermissions,
    { data: userPermissions, isFetching: isFetchingPermissions }
  ] = useLazyQuery<DataApiInputParams, StringKeys>({
    api: useLazyGetPermissionsQuery
  });
  const [createRole, { isLoading: isCreatingRole }] = useMutation({
    api: useCreateRoleMutation,
    errorContext: ErrorContext.ROLES
  });
  const [updateRole, { isLoading: isUpdatingRole }] = useMutation({
    api: useUpdateRoleMutation,
    errorContext: ErrorContext.ROLES
  });

  useEffect(() => {
    if (isOpen) {
      let params = {
        ptype: PERMISSION_TYPE.ROLE_PERMISSIONS,
        roles: roleData?.name || RESOURCE_ROLE_TEMPLATE
      };
      fetchPermissions({
        params: {
          params
        }
      });
      if (roleData) {
        setValue('name', roleData.name);
        setValue('description', roleData.description);
      }
    }
  }, [roleData, isOpen]);

  useEffect(() => {
    if (userPermissions) {
      const permissionObject =
        userPermissions[roleData?.name || RESOURCE_ROLE_TEMPLATE];
      setValue(
        'permissions',
        Object.keys(permissionObject).map((key) => ({
          ...permissionObject[key]
        }))
      );
    }
  }, [userPermissions, roleData]);

  const handleCancelClick = useCallback(() => {
    onClose();
  }, []);

  const handleSubmitRole = async (data) => {
    if (roleData) {
      await updateRole({
        params: {
          params: {
            name: data.name,
            description: data.description,
            permissions: JSON.stringify(
              data.permissions.map((item) => ({
                id: item.id,
                value: item.value
              }))
            )
          }
        },
        fallbackMsg: T.roleUpdateError,
        successMsg: T.roleUpdateSuccess
      });
    } else {
      await createRole({
        params: {
          params: {
            name: data.name,
            description: data.description,
            permissions: JSON.stringify(
              data.permissions.map((item) => ({
                rid: item.rid,
                type_id: item.type_id,
                desc_id: item.desc_id,
                value: item.value,
                role: data.name
              }))
            )
          }
        },
        fallbackMsg: T.roleCreateError,
        successMsg: T.roleCreateSuccess
      });
    }
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: (theme) => ({
          borderRadius: theme.general.borderRadiusXl
        })
      }}
    >
      <DialogTitle
        title={roleData ? t(T.editRole) : t(T.addARole)}
        showCloseIcon
        onClose={onClose}
      />
      <DialogContent
        sx={{
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '0 !important'
          }
        }}
      >
        {isFetchingPermissions ? (
          <Loader />
        ) : (
          <>
            <Box>
              <InputLabel>{t(T.roleName)}</InputLabel>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t(T.required, { name: t(T.roleName) })
                  },
                  maxLength: {
                    value: 50,
                    message: t(T.maxLength, { name: t(T.roleName), length: 50 })
                  }
                }}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    sx={(theme) => ({
                      mb: theme.spacing(2),
                      '& .MuiOutlinedInput-root': {
                        borderRadius: theme.spacing(1)
                      }
                    })}
                    value={field.value}
                    required
                    disabled={!!roleData?.name}
                    error={!!errors.name}
                    helperText={
                      errors.name?.message || `${field.value.length} / 50`
                    }
                    {...field}
                  />
                )}
              />
              <InputLabel>{t(T.description)}</InputLabel>
              <Controller
                name="description"
                control={control}
                rules={{
                  maxLength: {
                    value: 50,
                    message: t(T.maxLength, {
                      name: t(T.description),
                      length: 50
                    })
                  }
                }}
                render={({ field, formState: { errors } }) => {
                  return (
                    <TextField
                      fullWidth
                      sx={(theme) => ({
                        mb: theme.spacing(2),
                        '& .MuiOutlinedInput-root': {
                          borderRadius: theme.spacing(1)
                        }
                      })}
                      value={field.value}
                      error={!!errors.description}
                      helperText={
                        errors.description?.message ||
                        `${field.value.length} / 50`
                      }
                      {...field}
                      multiline
                      rows={5}
                    />
                  );
                }}
              />
            </Box>
            <Grid container>
              {!!formValues.permissions &&
                formValues.permissions.map((permission, index) => (
                  <Grid item xs={12} sm={6} key={permission.id}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Controller
                        name={`permissions.${index}.value`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <Checkbox checked={permission?.value} {...field} />
                            <Typography variant="body1">
                              {permission.description}
                            </Typography>
                          </>
                        )}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mr: 2 }}
          onClick={handleCancelClick}
          disabled={isCreatingRole || isFetchingPermissions || isUpdatingRole}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          disabled={
            !formValues?.name?.length ||
            isCreatingRole ||
            isUpdatingRole ||
            !formValues?.permissions?.some((permission) => permission.value)
          }
          loading={isCreatingRole || isUpdatingRole}
          loadingPosition="center"
          loadingIndicator={<CircularProgress size={20} />}
          onClick={handleSubmit(handleSubmitRole)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddRole;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Root,
  TableCellHeader,
  TableRowHeader,
  EditActionCell,
  getActionColumnSorted,
  CustomTableRow
} from 'src/components/Grid/styles';
import Loader from 'src/components/Loader';
import PageHeader from 'src/components/PageHeader';
import {
  useDeleteUserGroupMutation,
  useLazyGetUserGroupsQuery,
  useLazyGetUsersQuery
} from 'src/services/api';
import {
  TableEditColumn,
  Grid as TableGrid,
  TableHeaderRow,
  VirtualTable,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import AddUserGroup from './addUserGroup';
import { MembersProvider } from './members';
import { Box, Grid, useTheme } from '@mui/material';
import { NameProvider } from 'src/components/Grid/name';
import { useSelector } from 'react-redux';
import { StringKeys } from 'src/types/base';
import {
  ChangeSet,
  EditingState,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  SortingState
} from '@devexpress/dx-react-grid';
import { Getter, Plugin } from '@devexpress/dx-react-core';
import { DeleteButton, EditButton } from '../Roles/styles';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import GridContainer from 'src/components/GridContainer';
import {
  COLUMNS,
  userGroupsColumnExtensions,
  getFilteredList,
  userGroupsColumns,
  defaultSorting,
  sortingColumnExtensions,
  integratedSortingColumnExtensions
} from './utils';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { getFilters } from './filters';
import { isEqual } from 'lodash';
import { RootState } from 'src/redux/store';
import { USER_APP_PERMISSIONS, ALERT } from 'src/types/enum';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import GridSkeleton from 'src/components/GridSkeleton';
import { Helmet } from 'react-helmet-async';
import { ActionButtons } from './ActionButtons';
import { ErrorContext } from 'src/utils/errorMappings';
import useMutation from 'src/hooks/useMutation';
import GridActions from 'src/components/GridActions';
import { CONTENT_PADDING } from 'src/utils/constants';

const UserGroups = ({ renderTabs, title }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [isAddUserGroupModalOpen, setIsAddUserGroupModalOpen] =
    useState<boolean>(false);
  const [currentlyEditingUserGroup, setCurrentlyEditingUserGroup] =
    useState<string>();
  const [selectedUserGroups, setSelectedUserGroups] = useState<
    (string | number)[]
  >([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const [filteredKeys, setFilteredKeys] = useState<StringKeys>({});
  const [filtersValue, setFiltersValue] = useState<StringKeys>({});
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [deletedUserGroupIds, setDeletedUserGroupIds] = useState<
    (string | number)[]
  >([]);

  const [
    fetchUserGroups,
    {
      data: userGroups,
      isLoading: isLoadingUserGroups,
      isFetching: isFetchingUserGroups
    }
  ] = useLazyQuery<DataApiInputParams, StringKeys>({
    api: useLazyGetUserGroupsQuery,
    errorContext: ErrorContext.USER_GROUPS
  });
  const [deleteUserGroup, { isLoading: isDeletingUserGroup }] = useMutation({
    api: useDeleteUserGroupMutation,
    errorContext: ErrorContext.USER_GROUPS
  });
  const { userAppPermissions } = useSelector((state: RootState) => state.data);

  const havePermissionToDeleteUserGroup = useMemo(
    () =>
      userAppPermissions[USER_APP_PERMISSIONS.DELETE_USER_GROUPS]?.value ||
      userAppPermissions[USER_APP_PERMISSIONS.DELETE_OWN_USER_GROUPS]?.value ||
      false,
    [userAppPermissions]
  );

  const userGroupsData = useMemo(() => {
    if (userGroups?.length) {
      return userGroups.map(({ id, name, members, ...rest }) => ({
        id,
        name,
        members: (members || []).map(({ id, username, attributes }) => ({
          id,
          username,
          fullName: attributes?.full_name?.[0] || ''
        })),
        ...rest
      }));
    }
  }, [userGroups]);

  const isFilterDisabled = useMemo(() => {
    return isLoadingUserGroups || selectedUserGroups.length > 0;
  }, [isLoadingUserGroups, selectedUserGroups.length]);

  const filters = useMemo(
    () =>
      getFilters({
        searchApi: useLazyGetUsersQuery
      }),
    []
  );

  const columns = useMemo(
    () => userGroupsColumns.map((col) => ({ ...col, title: t(col.title) })),
    [i18n.language]
  );

  useEffect(() => {
    fetchUserGroups({
      params: {
        params: {
          show_details: true,
          ...getFilteredList(filteredKeys)
        }
      }
    });
  }, [filteredKeys]);

  const handleClickAddButton = useCallback(() => {
    setIsAddUserGroupModalOpen(true);
  }, []);
  const handleClickCloseModalButton = useCallback(() => {
    setIsAddUserGroupModalOpen(false);
    setCurrentlyEditingUserGroup(undefined);
  }, []);

  const onClickReset = (initialObject: any) => {
    setFilteredKeys(initialObject);
    fetchUserGroups({
      params: {
        ...initialObject,
        show_details: true
      }
    });
  };

  const onSearch = (selectedFields: StringKeys) => {
    if (!isEqual(selectedFields, filteredKeys)) {
      fetchUserGroups({
        params: {
          params: { show_details: true, ...getFilteredList(selectedFields) }
        }
      });
      setFilteredKeys(selectedFields);
    }
  };

  const handleDeleteUserGroups = useCallback(async () => {
    if (!selectedUserGroups.length && !deletedUserGroupIds.length) return;
    const group_list = deletedUserGroupIds.length
      ? deletedUserGroupIds.toString()
      : selectedUserGroups.toString();

    await deleteUserGroup({
      params: {
        params: {
          group_list
        }
      },
      fallbackMsg:
        selectedUserGroups.length > 1
          ? T.failedToDeleteUserGroups
          : T.failedToDeleteUserGroup,
      successMsg:
        selectedUserGroups.length > 1
          ? T.userGroupsDeletedSuccessfully
          : T.userGroupDeletedSuccessfully
    });
    closeConfirmationDialog();
  }, [selectedUserGroups, deletedUserGroupIds]);

  const onSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedUserGroups(userGroups.map((userGroup) => userGroup.id));
      } else {
        setSelectedUserGroups([]);
      }
    },
    [userGroups, selectedUserGroups]
  );

  const handleOnCommitChanges = useCallback(({ deleted }: ChangeSet) => {
    if (deleted) {
      setIsConfirmationDialogOpen(true);
      setDeletedUserGroupIds([...deleted]);
    }
  }, []);

  const CommandComponent = useCallback(
    ({
      onExecute,
      ...props
    }: TableEditColumn.CommandProps & { rowData: StringKeys }) => {
      const showEditButton =
        userAppPermissions?.[USER_APP_PERMISSIONS.EDIT_USER_GROUPS]?.value ||
        (props?.rowData?.attributes?.owner?.[0] === user.username &&
          userAppPermissions?.[USER_APP_PERMISSIONS.EDIT_OWN_USER_GROUPS]
            ?.value);
      const showDeleteButton =
        userAppPermissions?.[USER_APP_PERMISSIONS.DELETE_USER_GROUPS]?.value ||
        (props?.rowData?.attributes?.owner?.[0] === user.username &&
          userAppPermissions?.[USER_APP_PERMISSIONS.DELETE_OWN_USER_GROUPS]
            ?.value);

      switch (props.id) {
        case 'edit':
          return (
            showEditButton && (
              <EditButton
                onExecute={() => {
                  setCurrentlyEditingUserGroup(props.rowData.id);
                  setIsAddUserGroupModalOpen(true);
                }}
              />
            )
          );
        case 'delete':
          return showDeleteButton && <DeleteButton onExecute={onExecute} />;
        default:
          return <TableEditColumn.Command {...props} onExecute={onExecute} />;
      }
    },
    []
  );

  const openConfirmationDialog = useCallback(
    () => setIsConfirmationDialogOpen(true),
    []
  );
  const closeConfirmationDialog = useCallback(() => {
    setIsConfirmationDialogOpen(false);
    setDeletedUserGroupIds([]);
  }, []);

  const onClearSelection = () => {
    setSelectedUserGroups([]);
  };

  return (
    <>
      <Helmet>
        <title>
          {T.empowerGPT} - {t(T.userGroups)}
        </title>
      </Helmet>

      <Box
        sx={{
          maxHeight: '100vh',
          overflow: 'hidden',
          height: '100%',
          backgroundColor: theme.colors.alpha.white[100],
          padding: CONTENT_PADDING
        }}
      >
        <PageHeader
          title={title}
          showInfoIcon={false}
          renderTabs={renderTabs}
          filterProps={{
            elementsList: filters,
            onSearch: onSearch,
            onReset: onClickReset,
            forceReset: true,
            showFilters: !isLoadingUserGroups,
            disableFilters: isFilterDisabled,
            filtersValue,
            setFiltersValue
          }}
          actionButtons={ActionButtons({
            selectedUserGroups,
            showAddButton: true,
            handleClickAddButton
          })}
        >
          <GridActions
            hideActions={!selectedUserGroups.length}
            selectedRows={selectedUserGroups.length}
            onClearSelection={onClearSelection}
            appliedFilters={{
              appliedFiltersValue: filtersValue,
              setAppliedFiltersValue: setFiltersValue,
              onReset: onClickReset,
              elementsList: filters
            }}
            actionButtons={ActionButtons({
              selectedUserGroups,
              havePermissionToDeleteUserGroup,
              showDeleteButton: true,
              handleClickDeleteButton: openConfirmationDialog
            })}
          />
        </PageHeader>
        {isLoadingUserGroups ? (
          <Loader />
        ) : (
          <Grid
            marginTop={theme.spacing(2)}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            sx={{
              height: 'calc(100% - 130px)'
            }}
          >
            {isDeletingUserGroup ? <Loader /> : null}
            <Grid item xs={12} height={'100%'}>
              {isFetchingUserGroups ? (
                <GridSkeleton columnExtensions={userGroupsColumnExtensions} />
              ) : (
                <GridContainer length={userGroupsData?.length || 0}>
                  <TableGrid
                    rows={userGroupsData || []}
                    columns={columns}
                    rootComponent={(props) => (
                      <Root
                        sx={{
                          '.TableContainer-root': {
                            height: '83vh !important'
                          }
                        }}
                        {...props}
                      />
                    )}
                    getRowId={(row) => row.id}
                  >
                    <SortingState
                      defaultSorting={defaultSorting}
                      columnExtensions={sortingColumnExtensions}
                    />
                    <IntegratedSorting
                      columnExtensions={integratedSortingColumnExtensions}
                    />

                    <VirtualTable rowComponent={CustomTableRow} />
                    <TableHeaderRow
                      rowComponent={TableRowHeader}
                      cellComponent={TableCellHeader}
                      showSortingControls
                    />
                    <MembersProvider for={[COLUMNS.USERS]} />
                    <NameProvider for={[COLUMNS.NAME]} />
                    <EditingState onCommitChanges={handleOnCommitChanges} />
                    {havePermissionToDeleteUserGroup && (
                      <SelectionState
                        selection={selectedUserGroups}
                        onSelectionChange={setSelectedUserGroups}
                      />
                    )}
                    {havePermissionToDeleteUserGroup && (
                      <Plugin>
                        <IntegratedSelection />
                        <TableSelection showSelectAll />
                      </Plugin>
                    )}
                    <TableEditColumn
                      showEditCommand
                      showDeleteCommand
                      commandComponent={CommandComponent}
                      cellComponent={EditActionCell}
                    />
                    <Getter
                      name="tableColumns"
                      computed={getActionColumnSorted}
                    />
                  </TableGrid>
                </GridContainer>
              )}
            </Grid>
          </Grid>
        )}
        {isAddUserGroupModalOpen && (
          <AddUserGroup
            isOpen={isAddUserGroupModalOpen}
            onClose={handleClickCloseModalButton}
            userGroupId={currentlyEditingUserGroup}
          />
        )}
        {isConfirmationDialogOpen && (
          <ConfirmationDialog
            title={t(T.delete) + '?'}
            isOpen={isConfirmationDialogOpen}
            message={t(T.userGroupDeleteConfirmationMsg)}
            onConfirm={handleDeleteUserGroups}
            onClose={closeConfirmationDialog}
          />
        )}
      </Box>
    </>
  );
};

export default UserGroups;

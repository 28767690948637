import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  BorderedBox,
  CountTypography,
  T2Typography,
  T3Typography
} from '../styles';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ReactComponent as MiniExpert } from 'src/assets/Analytics/mini-expert.svg';
import CountContainer from '../CountContainer';
import { InternalAnalytics } from 'src/services/analytics';

const DocAnalytics = ({ data }: { data: InternalAnalytics[] }) => {
  const { t } = useTranslation();
  return (
    <Box padding={1} marginBottom={'10px'}>
      <Box
        display="flex"
        marginBottom={'10px'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Box sx={{ marginRight: 1 }}>
            <MiniExpert />
          </Box>
          <T2Typography>
            {t(T.miniExperts)}: {t(T.docAnalyst)}
          </T2Typography>
        </Box>
      </Box>
      <CountContainer data={data} />
    </Box>
  );
};

export default DocAnalytics;

import React from 'react';
import {
  TableHeaderRow,
  Table,
  Grid,
  TableEditColumn
} from '@devexpress/dx-react-grid-material-ui';
import { styled, useTheme, Grid as MuiGrid } from '@mui/material';
import { TableSelection } from '@devexpress/dx-react-grid';

export const TableCellHeader = ({ style, ...restProps }: any) => {
  const theme = useTheme();
  return (
    <TableHeaderRow.Cell
      {...restProps}
      style={{
        ...style,
        color: theme.colors.alpha.black[100]
      }}
    />
  );
};

export const CompactTableCellHeader = (props) => {
  return (
    <TableCellHeader
      {...props}
      style={{
        padding: '8px'
      }}
    />
  );
};

export const TableRowHeader = ({ row, ...restProps }: any) => {
  const theme = useTheme();
  return (
    <Table.Row
      height={20}
      {...restProps}
      sx={{
        backgroundColor: theme.colors.alpha.white[100],
        th: {
          backgroundColor: '#cad9f233'
        }
      }}
    />
  );
};

export const TableRowCell = ({ style, ...restProps }: any) => (
  <Table.Cell
    {...restProps}
    style={{
      ...style,
      height: '36px',
      padding: '8px 8px',
      wordWrap: 'break-word',
      whiteSpace: 'unset'
    }}
  />
);

export const Root = (props: any) => {
  const { sx, isMoveDialogOpen, ...rest } = props;
  return (
    <Grid.Root
      {...rest}
      sx={{
        height: '100%',
        ...sx,
        ...(isMoveDialogOpen && {
          '.TableContainer-root': {
            height: '300px !important'
          }
        }),
        '.MuiTableHead-root': {
          'z-index': '501 !important',
          position: 'sticky',
          top: 0
        },
        '.Pager-pager': {
          'z-index': '501 !important',
          position: 'sticky',
          bottom: 0,
          padding: '6px 12px !important',
          borderTop: '1px solid #EEE !important',
          backgroundColor: '#fff'
        },
        '.MuiCheckbox-root': {
          padding: 0,
          height: 20,
          width: 20
        },
        '.Mui-checked, .MuiCheckbox-indeterminate': {
          color: '#1A47B8 !important',
          padding: 0
        }
      }}
    />
  );
};

export const NoDataRowComponent = (props: any) => (
  <Table.NoDataRow
    {...props}
    height={120}
    sx={{
      width: '100%',
      background: 'transparent',
      '& > td': {
        border: 'none',
        '& > div': {
          textAlign: 'center'
        }
      }
    }}
  />
);

export const CustomTableRow = styled(Table.Row, {
  shouldForwardProp: (prop) => prop !== 'selectedRow'
})(({ theme, onClick, onDoubleClick, disabled, row, selectedRow }) => {
  return `
    cursor: ${(onClick || onDoubleClick) && !disabled ? 'pointer' : 'auto'};
    opacity: ${disabled && 0.6};
    ${!row?.id && `background-color: ${theme.colors.primaryAlt.lighter}`};
    ${selectedRow && `background-color: #cad9f233 !important`};
    &:hover {
      background-color: ${!disabled ? theme.colors.alpha.black[10] : 'initial'};
      td.edit-action-cell  button {
        visibility: visible;
      }
    }
    ${theme.breakpoints.up('sm')} {    
      td.edit-action-cell  button {
        visibility: hidden;
      }
    }
    .Mui-checked, .MuiCheckbox-indeterminate {
      color: ${disabled ? '#22335480 !important' : 'initial'};
    }
  `;
});

export const CustomTableRowHighlight = ({
  highlighted,
  selectByRowClick,
  tableRow,
  onToggle,
  children
}: TableSelection.RowProps) => {
  const optionalProps = {};

  if (selectByRowClick) {
    optionalProps['onClick'] = onToggle;
  }

  return (
    <CustomTableRow
      row={tableRow.row}
      selectedRow={highlighted}
      tableRow={tableRow}
      {...optionalProps}
    >
      {children}
    </CustomTableRow>
  );
};

export const EditActionCell = ({ children, ...props }: any) => {
  const theme = useTheme();
  if (
    props?.row?.attributes?.is_readonly?.includes('true') ||
    props?.row?.attributes?.owner?.includes('SYSTEM')
  ) {
    return <TableEditColumn.Cell {...props} className="edit-action-cell" />;
  }
  return (
    <Table.Cell
      className="edit-action-cell"
      {...props}
      sx={{
        ...((props.tableRow.row.id === '' || props.tableRow.row.id === 0) && {
          backgroundColor: theme.colors.primaryAlt.lighter
        })
      }}
    >
      <MuiGrid
        container
        width={'100%'}
        display={'flex'}
        justifyContent={'flex-end'}
      >
        {React.Children.map(children, (child) => {
          return child?.props.id === 'edit' || child?.props.id === 'delete'
            ? React.cloneElement(child, {
                rowData: props.tableRow.row
              })
            : child?.props.id === 'commit' || child?.props.id === 'cancel'
            ? React.cloneElement(child, {
                rowData: props.tableRow.row
              })
            : child;
        })}
      </MuiGrid>
    </Table.Cell>
  );
};

export const getActionColumnSorted = ({ tableColumns }) => [
  ...tableColumns?.filter((c: any) => c.type !== TableEditColumn.COLUMN_TYPE),
  {
    key: 'editCommand',
    type: TableEditColumn.COLUMN_TYPE,
    width: 120
  }
];

export const StubCellComponent =
  (addNewRow: boolean) =>
  ({ ...props }: any) =>
    (
      <Table.Cell
        {...props}
        sx={(theme) =>
          addNewRow && { backgroundColor: theme.colors.primaryAlt.lighter }
        }
      />
    );

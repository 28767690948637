import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  AccordionSummary,
  Box,
  Drawer,
  IconButton,
  styled,
  Typography
} from '@mui/material';
export const drawerWidth = 250;
export const historyPanelWidth = 240;
export const miniDrawerWidth = 60;
const marginLeft = drawerWidth - miniDrawerWidth;
export const hoverZoneWidth = '20px';
export const sidebarIconWidth = '24px';
export const sidebarIconHeight = '24px';

interface MainProps {
  isSideBarOpen?: boolean;
  isHidden?: boolean;
  drawerMaxWidth?: string;
}
interface SidebarOpenProps {
  isSidebarOpen: boolean;
}

export const Main = styled('main')<MainProps>(
  ({ theme, isSideBarOpen, isHidden, drawerMaxWidth }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
    marginLeft: '0',
    display: 'flex',
    width: '100%',
    maxWidth: isHidden ? '100%' : drawerMaxWidth,
    ...(!isSideBarOpen && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      maxWidth: `calc(100% - ${miniDrawerWidth}px)`,
      marginLeft: `-${marginLeft}px`
    }),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginLeft: '0',
      maxHeight: `calc(100% - ${miniDrawerWidth}px)`,
      marginTop: `${isHidden ? 0 : miniDrawerWidth}px`
    }
  })
);

export const TopSectionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 10px'
});

export const StyledLogoContainer = styled(Box, {
  shouldForwardProp: (prop) => {
    return prop !== 'isSidebarOpen';
  }
})<SidebarOpenProps>(({ isSidebarOpen }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  paddingBottom: isSidebarOpen ? '14px' : '5px'
}));

export const StyledIconContainer = styled(Box)({
  display: 'flex',
  height: '50px',
  padding: '5px 5px 10px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch'
});

export const HoverZone = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSidebarOpen'
})<SidebarOpenProps>(({ isSidebarOpen, ...props }) => ({
  position: 'absolute',
  left: isSidebarOpen ? drawerWidth : miniDrawerWidth,
  width: hoverZoneWidth,
  height: '100vh',
  backgroundColor: 'transparent',
  zIndex: 300,
  props
}));

export const SidebarToggleButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isSidebarOpen'
})<SidebarOpenProps>(({ theme, isSidebarOpen }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: isSidebarOpen ? '250px' : '60px',
  boxShadow: theme.shadows[1],
  zIndex: 301,
  transition: 'left 0.1s',
  borderRadius: '0px 16px 16px 0px',
  borderTop: '1px solid #E5E5E5',
  borderRight: '1px solid #E5E5E5',
  borderBottom: '1px solid #E5E5E5',
  background: '#FFF',
  width: '20px',
  height: '28px'
}));

export const StyledMinimizedDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isSidebarOpen'
})<SidebarOpenProps>(({ theme, isSidebarOpen }) => ({
  display: isSidebarOpen && 'none',
  width: `${miniDrawerWidth}px`,
  flexShrink: 0,
  position: 'absolute',
  left: 0,
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
  '& .MuiDrawer-paper': {
    width: `${miniDrawerWidth}px`,
    boxSizing: 'border-box',
    backgroundColor: theme.sidebar.background
  }
}));

export const ScrolledBox = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  backgroundColor: theme.sidebar.background,
  color: 'white',
  position: 'relative',
  overflowX: 'hidden',
  marginTop: '-1px'
}));

export const AccordionWrapper = styled(Box)(({ theme }) => ({
  bgcolor: theme.sidebar.background
}));

export const StyledAccordionSummary = styled(AccordionSummary)`
  max-height: 24px;
  min-height: 24px;
  margin-top: 2px;
  color: #b2b6cd;
  position: sticky !important;
  top: -1px;
  padding: 0 16px;
  background: ${({ theme }) => theme.sidebar.background};
  z-index: 10;
  border-radius: 0px;
  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 24px;
  }
`;

export const StyledHeading = styled(Typography)({
  position: 'sticky',
  top: 0,
  zIndex: 2,
  marginRight: '6px'
});

export const MidSectionBox = styled(Box)({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  height: '100%'
});

export const CustomRightIcon = styled(ChevronRight)({
  color: '#000C57',
  height: '20px',
  width: '20px',
  position: 'absolute',
  right: '3px'
});

export const CustomLeftIcon = styled(ChevronLeft)({
  color: '#000C57',
  height: '20px',
  width: '20px',
  position: 'absolute',
  right: '3px'
});

export const HistoryPanelBox = styled(Box)({
  position: 'relative',
  flex: 1,
  marginBottom: '10px'
});

export const HistoryDrawer = styled(Drawer)({
  width: historyPanelWidth,
  position: 'absolute',
  flexShrink: 0,
  zIndex: 1200,
  '& .MuiDrawer-paper': {
    width: historyPanelWidth,
    boxSizing: 'border-box',
    backgroundColor: 'white',
    marginLeft: `${miniDrawerWidth}px`,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px'
  }
});

import { Close } from '@mui/icons-material';
import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
  keyframes,
  Button,
  Card,
  CircularProgress
} from '@mui/material';

interface OptionsBoxProps {
  props?: {
    inputBaseHeight?: number;
  };
}
interface OptionWrapperProps {
  props?: {
    index?: number;
    focusedIndex?: number;
  };
}

export const ChatWindow = styled(Box)(
  () => `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  `
);

const COMMON_STYLES = `
  word-break: break-word;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  font-size: 16px;
`;

const CURSOR_STYLES = `
  content : " ▉";
  animation: blink 1s infinite;
  width: "8px";
  height: "32px";
  font-weight: light;
  background: "#000";
  display: "inline-block";
`;
const shimmer = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const SecondaryMessage = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(0)} ${theme.spacing(1.5)};
  ${COMMON_STYLES},
  pre {
    white-space: pre-wrap;
    font-family: inherit;
    word-wrap: break-word;
    margin: 0;
    position: relative;
  }
  p {
    margin-top: 6px;
  }
  table {
    border-collapse: separate;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 100%;
    border-spacing: 0
  }
  /* Add border to table cells */
  th,
  td {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  blockquote {
    p {
      margin: 0 !important
    }
  }
 
  td:first-of-type, th:first-of-type {
    border-left: none;
  }
 
  th {
    background-color: rgba(227, 227, 227, 0.8);
    border-top: none;
  }
 

  #content-streaming>:not(ol):not(ul):not(pre):last-child:after,
  #content-streaming>ul:last-child>li:last-child:not(:has(*>li)):after,
  #content-streaming>ul:last-child>li:last-child>li:last-child:not(:has(*>li)):after,
  #content-streaming>ul:last-child>li:last-child>ul:last-child>li:last-child:not(:has(*>li)):after,
  #content-streaming>ol:last-child>li:last-child>ul:last-child>li:last-child:not(:has(*>li)):after,
  #content-streaming>ul:last-child>li:last-child>ul:last-child>li:last-child>ul:last-child>li:last-child:not(:has(*>ul>li)):after,
  #content-streaming>ul:last-child>li:last-child:not(:has(*>li)):after,
  #content-streaming>ol:last-child>li:last-child:not(:has(*>li)):after,
  #content-streaming>ol:last-child>li:last-child>ul:last-child>li:last-child>ul:last-child>li:last-child:not(:has(*>ul>li)):after{
    ${CURSOR_STYLES}
  }

@keyframes blink {
  50% {
    opacity: 0; 
  }
`
);

export const PrimaryMessage = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(0)} ${theme.spacing(1.5)};
  ${COMMON_STYLES}
`
);

export const MessagesDividerWrapper = styled(Divider)(
  ({ theme }) => `
  .MuiDivider-wrapper {
    border-radius: ${theme.general.borderRadiusSm};
    text-transform: none;
    background: ${theme.palette.background.default};
    font-size: ${theme.typography.pxToRem(13)};
    color: ${theme.colors.alpha.black[50]};
  }
`
);

export const TagsBadge = styled(Badge)`
  .MuiBadge-badge {
    min-width: 14px;
    height: 14px !important;
    border-radius: 30px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    padding: 0px 3px 1px 3px;
  }
`;

export const TagsButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '30px',
  border: '1px solid rgba(25, 58, 107, 0.2)',
  height: '24px',
  width: '24px'
}));

export const QueryBoxPaper = styled(Paper)(() => ({
  padding: '10px',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.10)',
  borderRadius: '6px',
  height: 'max-content',
  boxShadow: 'none'
}));

export const PromptOptionsBox = styled(Box)<OptionsBoxProps>(
  ({ props: { inputBaseHeight }, theme }) => `
  width: 400px;
  max-height: 180px;
  border-radius: 2px;
  border: 1px solid #CAD9F2;
  background: #FFF;
  position: absolute;
  bottom: ${inputBaseHeight + 40}px;
  left: -10px;
  zIndex: 1;
  overflow: auto;
  ${theme.breakpoints.down('sm')} {
    width: calc(100vw - 32px);
  }
  `
);

export const PromptOptionWrapper = styled(Grid)<OptionWrapperProps>(
  ({ props: { index, focusedIndex } }) => `
  width: auto;
  height: 36px;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid rgba(202, 217, 242, 0.40);
  gap: 4px;
  cursor: pointer;
  background-color: ${
    focusedIndex === index ? 'rgba(231, 240, 255, 0.20)' : '#fff'
  };
  &:hover {
    background-color: rgba(231, 240, 255, 0.20);
  }
  `
);

export const PromptTitle = styled(Typography)({
  color: 'var(--Secondary-500-Main, #222F59)',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.5px'
});

export const AnimatedText = styled(Typography)`
  color: #ccc;
  overflow: hidden;
  background: linear-gradient(to right, #ccc 0%, #000 20%, #ccc 40%, #ccc 100%);
  background-size: 200% auto;
  animation: ${shimmer} 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const EmpowerGPTLogoBox = styled(Box)(() => ({
  width: '40px',
  height: '40px',
  borderRadius: '8px',
  background: '#000C57',
  display: 'flex',
  padding: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  flexShrink: 0
}));

export const DisclaimerTextButton = styled(Button)`
  padding: 0;
  min-width: 0;
  text-transform: none;
  color: rgba(34, 47, 89, 0.8);
  border-radius: 0;
  border-bottom: 1px solid rgba(34, 47, 89, 0.8);
`;

export const StyledQuestionCard = styled(Card)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: '#F8F8F8',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'none',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: 'unset',
    padding: '12px 0'
  }
}));

export const QuestionCardText = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical'
});

export const FileCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #2233544d;
  border-radius: 6px;
  max-width: 188px;
  position: relative;
  overflow: visible;
  box-shadow: none;
`;

export const FileDetails = styled(Box)(`
  display: flex;
  align-items: center;
`);

export const FileNameContainer = styled(Box)(`
max-width: 110px;
overflow: hidden; 
text-overflow: ellipsis;
white-space: nowrap;  
font-size: 14px;  
display: inline-block;  
`);
export const ChatAvatarIconStyle = {
  width: '40px',
  height: '40px',
  borderRadius: '50px'
};

export const CloseIconButton = styled(IconButton)({
  position: 'absolute',
  top: '-9px',
  right: '-10px',
  border: '1px solid #d3d3d3',
  borderRadius: '50%',
  height: '20px',
  width: '20px',
  backgroundColor: 'white'
});

export const StyledCloseIcon = styled(Close)({
  height: '12px',
  width: '12px',
  stroke: '#000C57',
  strokeWidth: '1px'
});
export const ChatLoader = () => (
  <Box
    sx={{
      width: '100%',
      height: '100vh'
    }}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress size={40} disableShrink thickness={2} />
  </Box>
);

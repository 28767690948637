import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resourceProviderNavigateLink } from 'src/content/Documents/utils/utils';
import { RootState, useSelector } from 'src/redux/store';
import { useLazyDownloadFileQuery } from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { StringKeys } from 'src/types/base';
import { SelectedDocument } from 'src/types/document';
import { FILE_EXTENSIONS, RESOURCE_PROVIDERS } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { downloadFileToSystem } from 'src/utils/files/file_encryption';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { viewPDFDocument } from 'src/utils/utils';
import useLazyQuery from '../useLazyQuery';

type HandleViewFileProps = (
  document: SelectedDocument | StringKeys,
  e?: React.MouseEvent | Event,
  handleClose?: (e: any) => void
) => void;

const useFileViewer = ({
  selectedDocument
}: {
  selectedDocument: SelectedDocument | StringKeys;
}) => {
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [documentToView, setDocumentToView] = useState<
    SelectedDocument | StringKeys
  >(selectedDocument);
  const [downloadFile, { data, isFetching }] = useLazyQuery<
    DataApiInputParams,
    any
  >({ api: useLazyDownloadFileQuery, errorContext: ErrorContext.FILES });
  const { sharePointProviderId, oneDriveProviderId, userResourceProviders } =
    useSelector((state: RootState) => state.data);

  useEffect(() => {
    if (data && !isFetching) {
      const blob = new Blob([data.blob], {
        type: data.contentType
      });
      if (data?.format === FILE_EXTENSIONS.PDF) {
        if (!isFileDownloading) viewPDFDocument(blob, documentToView?.page_no);
        else downloadFileToSystem(blob, documentToView?.name);
      } else {
        downloadFileToSystem(blob, documentToView?.name);
      }
    }
  }, [data, isFileDownloading, isFetching]);

  const getCurrentResourceProvider = (document: SelectedDocument | StringKeys) => {
    const providerTypes = [
      RESOURCE_PROVIDERS.SHAREPOINT,
      RESOURCE_PROVIDERS.ONEDRIVE,
      RESOURCE_PROVIDERS.CONFLUENCE
    ];

    const providerObj = userResourceProviders.find(
      (resourceProvider) =>
        resourceProvider?.id === document?.provider_id &&
        providerTypes.includes(resourceProvider?.type)
    );
    const resourceProviderLinkText =
      document?.provider_id === sharePointProviderId
        ? t(T.openInSharePoint)
        : document?.provider_id === oneDriveProviderId
        ? t(T.openInOneDrive)
        : t(T.openInConfluence);
    return {
      ...providerObj,
      linkText: resourceProviderLinkText
    };
  };

  const handleViewResourceProviderFile = (
    e?: any,
    handleClose?: (e: any) => void,
    document = selectedDocument
  ) => {
    e?.stopPropagation();
    if (handleClose) handleClose(e);
    const itemData = JSON.parse(document?.external_data || '{}')?.item_data;
    const webUrl = resourceProviderNavigateLink(
      itemData,
      getCurrentResourceProvider(document)
    );
    window.open(webUrl, '_blank');
  };

  const handleViewFile: HandleViewFileProps = (
    document = documentToView,
    e = undefined,
    handleClose = undefined
  ) => {
    if (!!document.provider_id && document?.external_data) {
      handleViewResourceProviderFile(e, handleClose, document);
    } else {
      e?.stopPropagation();
      setIsFileDownloading(false);
      setDocumentToView(document);
      downloadFile({ params: { params: { id: document.id } } });
    }
  };

  const handleDownloadFile = useCallback(
    async (document = documentToView) => {
      setIsFileDownloading(true);
      await downloadFile({
        params: {
          params: {
            id: document.id,
            is_download: true
          }
        }
      });
    },
    [documentToView]
  );
  return {
    isFileDownloading,
    isFetching,
    handleDownloadFile,
    handleViewFile,
    handleViewResourceProviderFile,
    currentResourceProvider: getCurrentResourceProvider(selectedDocument)
  };
};

export default useFileViewer;

import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { BorderedBox } from './styles';
import { FactTypes } from '../types';
import CountBox from './CountBox';
import { InternalAnalytics } from 'src/services/analytics';

const CountContainer = ({ data }: { data: InternalAnalytics[] }) => {
  const { t } = useTranslation();

  const headings = (code: string): string =>
    code === FactTypes.ANALYSIS_PERFORMED
      ? t(T.analysisPerformed)
      : code === FactTypes.QUESTIONNAIRES_CREATED
      ? t(T.questionnairesCreated)
      : code === FactTypes.QUESTION_ADDED
      ? t(T.questionsAdded)
      : '';

  return data && data?.length ? (
    <Box padding={1} marginBottom={'10px'}>
      <BorderedBox>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          {data.map((item: InternalAnalytics, index) => (
            <CountBox
              key={index}
              count={item.count}
              name={headings(item.name)}
            />
          ))}
        </Box>
      </BorderedBox>
    </Box>
  ) : null;
};

export default CountContainer;

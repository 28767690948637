import React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Typography,
  useTheme
} from '@mui/material';
import { SelectItem } from 'src/types/custom_component';
import { StringKeys } from 'src/types/base';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200
    }
  },
  MenuListProps: {
    style: {
      padding: 0
    }
  }
};

type SelectProps = {
  id: string;
  width?: any;
  onChange: (event: SelectChangeEvent<any[]>) => void;
  value: any; //number[];
  dataSource: SelectItem[];
  label?: string;
  placeholder?: string;
  customLabel?: boolean;
  valueKey?: string;
  disabled?: boolean;
  sx?: StringKeys;
  renderValue?: (value: any) => string | JSX.Element;
  required?: boolean;
  error?: boolean;
  renderOptions?: (value: SelectItem) => JSX.Element;
};

const DropdownIcon = (props) => <KeyboardArrowDownIcon {...props} />;

const Select = React.forwardRef<HTMLDivElement | null, SelectProps>(
  (props: SelectProps, ref) => {
    const theme = useTheme();
    return (
      <FormControl sx={{ width: props.width || 200 }} size="small">
        {props.customLabel && (
          <Typography
            sx={{
              marginBottom: '5px',
              marginLeft: '2px',
              color: theme.colors.custom.labelColor,
              fontSize: '13px'
            }}
            gutterBottom
          >
            {props.label}
          </Typography>
        )}
        <MuiSelect
          ref={ref}
          id={`${props.id}`}
          sx={{
            borderRadius: theme.general.borderRadius,
            ...props.sx,
            ['& .MuiSelect-select']: {
              width: '100%',
              overflow: 'hidden',
              ['& .MuiBox-root']: {
                display: 'block',
                ['& .MuiTypography-root']: {
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }
              }
            }
          }}
          required={props.required || false}
          error={props.error || false}
          disabled={props.disabled || false}
          placeholder={props?.placeholder || ''}
          value={props.value}
          onChange={props.onChange}
          MenuProps={MenuProps}
          renderValue={props?.renderValue}
          IconComponent={DropdownIcon}
        >
          {props?.dataSource?.map((item: SelectItem) => (
            <MenuItem
              key={item.id}
              value={props.valueKey ? item[props.valueKey] : item.id}
              sx={{
                whiteSpace: 'break-spaces',
                paddingY: '8px',
                paddingX: '14px',
                borderRadius: 0
              }}
              divider
            >
              <Box display="flex" flexDirection="column">
                {props.renderOptions ? (
                  props.renderOptions(item)
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      color="primary"
                      gutterBottom
                      sx={{
                        fontWeight: 500,
                        lineHeight: '24px',
                        marginBottom: 0
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        lineHeight: '20px'
                      }}
                    >
                      {item.secondaryText}
                    </Typography>
                  </>
                )}
              </Box>
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    );
  }
);
export default Select;

import { Box, Popover } from '@mui/material';
import SearchableAutoComplete from 'src/components/SearchableAutoComplete';
import { t } from 'i18next';
import { useLazyGetHybridSearchQuery } from 'src/services/api';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  AdvancedSearchButton,
  CustomPopper,
  getDialogPaperStyles,
  searchableAutoCompleteStyles
} from './styles';
import { uniqWith } from 'lodash';
import ResourceSearchItem from 'src/components/ResourceSearch/ResourceSearchItem';
import { ChatFile } from 'src/content/Chat/types';
import { chatFileComparator } from 'src/content/Chat/utils';
import SimpleSearchFallback from './SimpleSearchFallback';
import { MutableRefObject, useState } from 'react';

type SimpleSearchDialogProps = {
  chatFiles: ChatFile[];
  setChatFiles: React.Dispatch<React.SetStateAction<ChatFile[]>>;
  isFileSearchDialogOpen: boolean;
  closeFileSearchDialog: () => void;
  fileSearchAnchorElem: HTMLElement | null;
  openAdvancedSearch: () => void;
  isDataLibrary?: boolean;
  onItemClick?: (value: ChatFile) => void;
  onStartChatClick?: (value: ChatFile[]) => void;
  searchInputRef?: MutableRefObject<HTMLInputElement>;
};

export default function SimpleSearchDialog({
  chatFiles,
  setChatFiles,
  isFileSearchDialogOpen,
  closeFileSearchDialog,
  fileSearchAnchorElem,
  openAdvancedSearch,
  isDataLibrary,
  onItemClick,
  onStartChatClick,
  searchInputRef
}: SimpleSearchDialogProps) {
  const [highLightedOption, setHighlightedOption] = useState<ChatFile>(null);
  const handleFileSelect = (_, value: ChatFile[]) => {
    const updatedValue = chatFiles.find((item) => item.id === value?.[0].id)
      ? chatFiles.filter((item) => item.id !== value?.[0].id)
      : uniqWith([...chatFiles, ...value], chatFileComparator);
    setChatFiles(updatedValue);
    if (onItemClick) {
      onItemClick(updatedValue[0]);
      closeFileSearchDialog();
    }
  };

  const handleStartChatClick = (file: ChatFile) => {
    setChatFiles([file]);
    closeFileSearchDialog();
    if (onStartChatClick) {
      onStartChatClick([file]);
    }
  };

  const handleHighlightChange = (_, option: ChatFile, reason: string) => {
    if (reason === 'keyboard') {
      setHighlightedOption(option);
    } else setHighlightedOption(null);
  };

  return (
    <Popover
      open={isFileSearchDialogOpen}
      anchorEl={fileSearchAnchorElem}
      PaperProps={{
        sx: getDialogPaperStyles
      }}
      onClose={closeFileSearchDialog}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isDataLibrary ? 'right' : 'left'
      }}
      transformOrigin={{
        vertical: isDataLibrary ? 'top' : 'bottom',
        horizontal: isDataLibrary ? 'right' : 'left'
      }}
      disablePortal
    >
      <Box sx={{ my: '8px' }}>
        <SearchableAutoComplete
          searchApi={useLazyGetHybridSearchQuery}
          searchApiParams={{
            ...(!isDataLibrary && { filter_by_query_permission: 'True' })
          }}
          clearOnBlur={false}
          optionLabel={'name'}
          value={[]}
          shouldInputReset={false}
          onClose={null}
          disablePortal
          limitTags={-1}
          filterOptions={(option) => option}
          onChange={handleFileSelect}
          CustomFallback={SimpleSearchFallback}
          CustomPopper={CustomPopper}
          sx={searchableAutoCompleteStyles(isDataLibrary)}
          placeholder={t(T.search)}
          multiple={true}
          hideEndAdornment={true}
          hideCloseIcon={true}
          useSearchApi={true}
          valueOptionsComparator={chatFileComparator}
          textFieldProps={{ autoFocus: true, inputRef: searchInputRef }}
          maxLength={250}
          onHighlightChange={handleHighlightChange}
          noOptionsText={false}
          renderOption={(props, option: ChatFile) => {
            const isOptionSelected = chatFiles.find(
              (item) => item.id === option.id
            );
            return (
              <ResourceSearchItem
                key={option.id}
                highLightedOption={highLightedOption}
                isOptionSelected={!!isOptionSelected}
                resultItem={option}
                handleStartChatClick={handleStartChatClick}
                optionProps={props}
                isDataLibrary={isDataLibrary}
              />
            );
          }}
        />
      </Box>
      <AdvancedSearchButton onClick={openAdvancedSearch}>
        {t(T.advancedSearchButtonText)}
      </AdvancedSearchButton>
    </Popover>
  );
}

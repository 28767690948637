import { ArticleOutlined } from '@mui/icons-material';
import { ReactComponent as ConfluenceFolderIcon } from 'src/assets/documentIcons/confluence-folder-icon.svg';
import { ReactComponent as NestedPageIcon } from 'src/assets/documentIcons/confluence-nested-page-icon.svg';
import { ReactComponent as CPageIcon } from 'src/assets/documentIcons/confluence-page-icon.svg';
import { ReactComponent as CExcelIcon } from 'src/assets/documentIcons/excel-confluence-icon.svg';
import { ReactComponent as ExcelIcon } from 'src/assets/documentIcons/excel-icon.svg';
import { ReactComponent as ODExcelIcon } from 'src/assets/documentIcons/excel-onedrive-icon.svg';
import { ReactComponent as SPExcelIcon } from 'src/assets/documentIcons/excel-sharepoint-icon.svg';
import { ReactComponent as CMsWordIcon } from 'src/assets/documentIcons/ms-word-confluence-icon.svg';
import { ReactComponent as MsWordIcon } from 'src/assets/documentIcons/ms-word-icon.svg';
import { ReactComponent as ODMsWordIcon } from 'src/assets/documentIcons/ms-word-onedrive-icon.svg';
import { ReactComponent as SPMsWordIcon } from 'src/assets/documentIcons/ms-word-sharepoint-icon.svg';
import { ReactComponent as OneDriveFolderIcon } from 'src/assets/documentIcons/onedrive-folder-icon.svg';
import { ReactComponent as CPdfIcon } from 'src/assets/documentIcons/pdf-confluence-icon.svg';
import { ReactComponent as PdfIcon } from 'src/assets/documentIcons/pdf-icon.svg';
import { ReactComponent as ODPdfIcon } from 'src/assets/documentIcons/pdf-onedrive-icon.svg';
import { ReactComponent as SPPdfIcon } from 'src/assets/documentIcons/pdf-sharepoint-icon.svg';
import { ReactComponent as CPptIcon } from 'src/assets/documentIcons/ppt-confluence-icon.svg';
import { ReactComponent as PptIcon } from 'src/assets/documentIcons/ppt-icon.svg';
import { ReactComponent as ODPptIcon } from 'src/assets/documentIcons/ppt-onedrive-icon.svg';
import { ReactComponent as SPPptIcon } from 'src/assets/documentIcons/ppt-sharepoint-icon.svg';
import { ReactComponent as SharepointFolderIcon } from 'src/assets/documentIcons/sharepoint-folder-icon.svg';
import { ReactComponent as CollectionOneDriveIcon } from 'src/assets/documentIcons/collection-onedrive-icon.svg';
import { ReactComponent as CollectionSharePointIcon } from 'src/assets/documentIcons/collection-sharepoint-icon.svg';
import { ReactComponent as CollectionConfluenceIcon } from 'src/assets/documentIcons/collection-confluence.svg';

import {
  FILE_EXTENSIONS,
  RESOURCE_PROVIDERS,
  SUPPORTED_UPLOAD_FILES_FORMAT
} from 'src/types/enum';

export const FILE_NAME_LENGTH_LIMIT = 200;
export const NO_OF_CONCURRENT_API_CALLS = 3;
export const MAX_FILES_IN_EACH_API = 3;
export const TOTAL_FILES_MAX_SIZE = 600; //MB

export const isSupportedFormat = (fileType: any): boolean => {
  return Object.values(SUPPORTED_UPLOAD_FILES_FORMAT).includes(fileType);
};

const ProvidersFilesIconsMap = {
  [RESOURCE_PROVIDERS.SHAREPOINT]: {
    [FILE_EXTENSIONS.PDF]: SPPdfIcon,
    [FILE_EXTENSIONS.PPT]: SPPptIcon,
    [FILE_EXTENSIONS.XLSX]: SPExcelIcon,
    [FILE_EXTENSIONS.DOCX]: SPMsWordIcon
  },
  [RESOURCE_PROVIDERS.ONEDRIVE]: {
    [FILE_EXTENSIONS.PDF]: ODPdfIcon,
    [FILE_EXTENSIONS.PPT]: ODPptIcon,
    [FILE_EXTENSIONS.XLSX]: ODExcelIcon,
    [FILE_EXTENSIONS.DOCX]: ODMsWordIcon
  },
  [RESOURCE_PROVIDERS.CONFLUENCE]: {
    [FILE_EXTENSIONS.PDF]: CPdfIcon,
    [FILE_EXTENSIONS.PPT]: CPptIcon,
    [FILE_EXTENSIONS.XLSX]: CExcelIcon,
    [FILE_EXTENSIONS.DOCX]: CMsWordIcon,
    [FILE_EXTENSIONS.PAGE]: CPageIcon,
    [FILE_EXTENSIONS.BLOG_POST]: CMsWordIcon,
    [FILE_EXTENSIONS.NESTED_PAGE]: NestedPageIcon
  }
};

const getProviderFileIcon = (provider = '', file = '') => {
  return ProvidersFilesIconsMap[provider]?.[file] || null;
};

const DEFAULT_ICONS = {
  [FILE_EXTENSIONS.PDF]: PdfIcon,
  [FILE_EXTENSIONS.PPT]: PptIcon,
  [FILE_EXTENSIONS.DOCX]: MsWordIcon,
  [FILE_EXTENSIONS.XLSX]: ExcelIcon
};

const FILE_FORMAT_CATEGORIES = {
  [FILE_EXTENSIONS.PDF]: [
    SUPPORTED_UPLOAD_FILES_FORMAT.PDF,
    FILE_EXTENSIONS.PDF
  ],

  [FILE_EXTENSIONS.PPT]: [
    SUPPORTED_UPLOAD_FILES_FORMAT.PPT,
    SUPPORTED_UPLOAD_FILES_FORMAT.PPTX,
    SUPPORTED_UPLOAD_FILES_FORMAT.ODP,
    FILE_EXTENSIONS.PPT,
    FILE_EXTENSIONS.PPTX,
    FILE_EXTENSIONS.ODP
  ],

  [FILE_EXTENSIONS.DOCX]: [
    SUPPORTED_UPLOAD_FILES_FORMAT.DOC,
    SUPPORTED_UPLOAD_FILES_FORMAT.DOCX,
    SUPPORTED_UPLOAD_FILES_FORMAT.ODT,
    FILE_EXTENSIONS.DOC,
    FILE_EXTENSIONS.DOCX,
    FILE_EXTENSIONS.ODT,
    FILE_EXTENSIONS.BLOG_POST
  ],

  [FILE_EXTENSIONS.XLSX]: [
    SUPPORTED_UPLOAD_FILES_FORMAT.XLS,
    SUPPORTED_UPLOAD_FILES_FORMAT.XLSX,
    SUPPORTED_UPLOAD_FILES_FORMAT.ODS,
    FILE_EXTENSIONS.XLS,
    FILE_EXTENSIONS.XLSX,
    FILE_EXTENSIONS.ODS
  ]
};

export const getFileIcon = (
  fileType: any = '',
  externalType: string = '',
  providerType = ''
) => {
  const extension = Object.entries(FILE_FORMAT_CATEGORIES).find(
    ([_, formats]) => formats.includes(fileType)
  )?.[0];

  return providerType
    ? getProviderFileIcon(
        providerType,
        fileType === FILE_EXTENSIONS.HTML && externalType
          ? externalType
          : extension
      )
    : extension
    ? DEFAULT_ICONS[extension]
    : ArticleOutlined;
};

export const ResourceProviderFolder = {
  [RESOURCE_PROVIDERS.SHAREPOINT]: SharepointFolderIcon,
  [RESOURCE_PROVIDERS.ONEDRIVE]: OneDriveFolderIcon,
  [RESOURCE_PROVIDERS.CONFLUENCE]: ConfluenceFolderIcon
};

export const ResourceProviderCollection = {
  [RESOURCE_PROVIDERS.SHAREPOINT]: CollectionSharePointIcon,
  [RESOURCE_PROVIDERS.ONEDRIVE]: CollectionOneDriveIcon,
  [RESOURCE_PROVIDERS.CONFLUENCE]: CollectionConfluenceIcon
};

import React from 'react';
import { Dialog, DialogContent, Button, Box, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'src/assets/helpAction/close-icon.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/helpAction/success.svg';
import { BodyTextTypography, MainTextTypography, StyledButton } from './styles';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { HelpAndSupportTabs } from './utils';

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  tab: String;
}

export const SuccessDialog: React.FC<SuccessDialogProps> = ({
  open,
  onClose,
  tab
}) => {
  const { t }: { t: any } = useTranslation();
  const { user } = useSelector((state: any) => state.auth);
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '20px',
          width: '632px',
          height: '190px',
          overflow: 'hidden',
          position: 'relative'
        }
      }}
    >
      <IconButton
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          padding: '8px',
          zIndex: 1
        }}
      >
        <CloseIcon width={'28px'} height={'28px'} />
      </IconButton>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '24px',
          textAlign: 'left'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexGrow: 1 }}>
          <SuccessIcon
            width={'55px'}
            height={'55px'}
            style={{
              marginBottom: '25px',
              marginTop: '-14px'
            }}
          />

          <Box>
            <MainTextTypography
              variant="h5"
              style={{
                fontSize: '26px',
                marginLeft: '12px',
                paddingBottom: '12px'
              }}
            >
              {tab === HelpAndSupportTabs.CONTACT_SUPPORT ||
              tab === HelpAndSupportTabs.SUGGEST_IMPROVEMENTS
                ? t(T.successMsg)
                : t(T.issueReported)}
            </MainTextTypography>

            <BodyTextTypography style={{ marginLeft: '12px' }}>
              {tab === HelpAndSupportTabs.CONTACT_SUPPORT ||
              tab === HelpAndSupportTabs.REPORT_ISSUE ? (
                <>
                  {t(T.supportText)} <strong>{user?.email}</strong>.
                </>
              ) : (
                <>
                  {t(T.issueText)} <strong>{user?.email}</strong>.
                </>
              )}
            </BodyTextTypography>
          </Box>
        </Box>
      </DialogContent>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 24,
          padding: '16px'
        }}
      >
        <StyledButton onClick={onClose} variant="contained">
          Ok
        </StyledButton>
      </Box>
    </Dialog>
  );
};

export default SuccessDialog;

import { styled, Tab, Tabs } from '@mui/material';

export const StyledTab = styled(Tab)(() => ({
  fontWeight: '400 !important',
  minWidth: 'max-content !important',
  margin: '0 2px',
  paddingLeft: '14px !important',
  paddingRight: '14px !important',
  ['&.Mui-selected']: {
    borderRadius: '7.14px !important',
    boxShadow:
      '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'
  }
}));

export const StyledTabs = styled(Tabs)({
  minHeight: '44px',
  maxWidth: 'max-content',
  borderRadius: '7.14px',
  ['& .MuiTabs-scroller']: {
    ['& .MuiTabs-indicator']: {
      display: 'none'
    }
  }
});

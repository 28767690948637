import React from 'react';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import TabGroup from 'src/components/TabGroup';
import { t } from 'i18next';
import { RootState, useSelector } from 'src/redux/store';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import { ANALYTICS_ROUTES } from 'src/utils/constants';
import { useLocation } from 'react-router';
import Usage from '../Analytics/External';
import InternalAnalytics from '../Analytics/Internal';

const UsageAndAnalytics = () => {
  const { userAppPermissions } = useSelector((state: RootState) => state.data);
  const location = useLocation();

  const tabs = [
    {
      label: t(T.usage),
      value: ANALYTICS_ROUTES.USAGE,
      component: Usage,
      permission:
        userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ANALYTICS]?.value
    },
    {
      label: t(T.analytics),
      value: ANALYTICS_ROUTES.INTERNAL_ANALYTICS,
      component: InternalAnalytics,
      permission:
        userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ANALYTICS]?.value
    }
  ];

  return (
    <TabGroup
      title={t(T.usageAndAnalytics)}
      tabs={tabs}
      defaultTab={location.pathname?.substring(1)}
    />
  );
};

export default UsageAndAnalytics;

import React from 'react';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import TabGroup from 'src/components/TabGroup';
import { t } from 'i18next';
import { RootState, useSelector } from 'src/redux/store';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import Users from '../Users';
import UserRoles from '../Roles';
import UserGroups from '../UserGroups';
import { USER_ROUTES } from 'src/utils/constants';
import { useLocation } from 'react-router';

const UserAndRoles = () => {
  const { userAppPermissions } = useSelector((state: RootState) => state.data);
  const location = useLocation();

  const tabs = [
    {
      label: t(T.users),
      value: USER_ROUTES.USERS,
      component: Users,
      permission: userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_USERS]?.value
    },
    {
      label: t(T.rolesTitle),
      value: USER_ROUTES.ROLES,
      component: UserRoles,
      permission: userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROLES]?.value
    },
    {
      label: t(T.userGroups),
      value: USER_ROUTES.USER_GROUPS,
      component: UserGroups,
      permission:
        userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_USER_GROUPS]?.value ||
        userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_OWN_USER_GROUPS]?.value
    }
  ];

  return (
    <TabGroup
      tabs={tabs}
      defaultTab={location.pathname?.substring(1)}
      renderTabsInsideComponent
      title={t(T.userAndRoles)}
    />
  );
};

export default UserAndRoles;

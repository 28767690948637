import { ArticleOutlined } from '@mui/icons-material';
import { ReactComponent as CollectionIcon } from 'src/assets/documentIcons/collection-icon.svg';
import { ReactComponent as FolderIcon } from 'src/assets/documentIcons/folder-icon.svg';
import { RESOURCE_TYPE } from 'src/types/enum';
import {
  getFileIcon,
  ResourceProviderCollection,
  ResourceProviderFolder
} from 'src/utils/files/file';
import { EXEMPTED_WORKSPACES_COLLECTIONS } from 'src/content/Documents/utils/utils';
import SellIcon from '@mui/icons-material/Sell';
import WorkspaceIcon from '../WorkspaceIcon';

type ResourceIconProps = {
  typeCode: string;
  originalFormat?: string;
  externalType?: string;
  providerType?: string;
  height?: string | number;
  width?: string | number;
  customStyle?: any;
  displayName?: string;
  workspaceStyle?: any;
  initials?: string;
  initialsColor?: string;
  [z: string]: any;
};

const ResourceIcon = ({
  typeCode,
  originalFormat,
  externalType,
  providerType,
  height,
  width,
  customStyle = {},
  displayName,
  workspaceStyle,
  initials,
  initialsColor,
  ...rest
}: ResourceIconProps) => {
  const size = {
    ...(height && { height }),
    ...(width && { width })
  };
  switch (typeCode) {
    case RESOURCE_TYPE.FILE:
      const Icon = getFileIcon(originalFormat, externalType, providerType);
      if (Icon)
        return (
          <Icon
            height={40}
            width={40}
            {...size}
            style={{ ...customStyle }}
            {...rest}
          />
        );
      return <ArticleOutlined fontSize="medium" />;
    case RESOURCE_TYPE.FOLDER:
      const ResourceProviderFolderIcon = ResourceProviderFolder?.[providerType];
      return ResourceProviderFolderIcon ? (
        <ResourceProviderFolderIcon
          {...size}
          style={{ ...customStyle }}
          {...rest}
        />
      ) : (
        <FolderIcon {...size} style={{ ...customStyle }} {...rest} />
      );
    case RESOURCE_TYPE.COLLECTION:
    case EXEMPTED_WORKSPACES_COLLECTIONS.FOUND_COLLECTION:
      const ResourceProviderCollectionIcon =
        ResourceProviderCollection?.[providerType];
      return ResourceProviderCollectionIcon ? (
        <ResourceProviderCollectionIcon
          {...size}
          style={{ ...customStyle }}
          {...rest}
        />
      ) : (
        <CollectionIcon {...size} style={{ ...customStyle }} {...rest} />
      );
    case RESOURCE_TYPE.WORKSPACE:
    case RESOURCE_TYPE.PRIVATE_WORKSPACE:
    case RESOURCE_TYPE.FOUND_WORKSPACE:
    case RESOURCE_TYPE.PUBLIC_WORKSPACE:
      return (
        <WorkspaceIcon
          displayName={displayName}
          initials={initials}
          initialsColor={initialsColor}
          {...size}
          style={workspaceStyle}
        />
      );
    case RESOURCE_TYPE.TAG:
      return <SellIcon fontSize="small" sx={{ ...size }} />;
    default:
      return null;
  }
};

export default ResourceIcon;

import React, { useState } from 'react';
import { ReactComponent as HelpAndSupportIcon } from 'src/assets/helpAction/Help-support-icon.svg';
import HelpAndSupportModal from './HelpAndSupportModal';
import SuccessDialog from './SuccessModal';
import { HelpAndSupportTabs } from './utils';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { StyledSupportButton, StyledTypography } from './styles';

interface ContactSupportButtonProps {
  isSidebarOpen?: boolean;
  iconSize?: string;
}

const ContactSupportButton: React.FC<ContactSupportButtonProps> = ({
  isSidebarOpen,
  iconSize
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(
    HelpAndSupportTabs.CONTACT_SUPPORT
  );

  const { t } = useTranslation();

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClose = () => {
    setIsSuccessDialogOpen(false);
    setSelectedTab(HelpAndSupportTabs.CONTACT_SUPPORT);
  };

  return (
    <React.Fragment>
      <StyledSupportButton
        onClick={handleModalToggle}
        disabled={isSubmitting}
        isSidebarOpen={isSidebarOpen}
      >
        <HelpAndSupportIcon style={{ width: iconSize, height: iconSize }} />
        {isSidebarOpen && (
          <StyledTypography variant="body2">
            {t(T.helpSupport)}
          </StyledTypography>
        )}
      </StyledSupportButton>

      {isModalOpen && (
        <HelpAndSupportModal
          isOpen={isModalOpen}
          onClose={handleModalToggle}
          setIsSuccessDialogOpen={setIsSuccessDialogOpen}
          isSuccessDialogOpen={isSuccessDialogOpen}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          setIsSubmitting={setIsSubmitting}
        />
      )}

      {isSuccessDialogOpen && (
        <SuccessDialog
          open={isSuccessDialogOpen}
          onClose={handleClose}
          tab={selectedTab}
        />
      )}
    </React.Fragment>
  );
};

export default ContactSupportButton;

import { useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { drawerWidth } from 'src/layouts/AccentHeaderLayout/styles';
import { toggleChatHistoryPan } from 'src/redux/slices/chat';

const useGlobalFunction = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleHistoryDrawer = useCallback(
    (
      event: React.MouseEvent<
        HTMLButtonElement | HTMLAnchorElement | HTMLElement
      >,
      isOpen: boolean
    ) => {
      event.stopPropagation();
      dispatch(toggleChatHistoryPan(isOpen));
    },
    [dispatch]
  );

  const calculateSideBarDrawerWidth = () => `calc(100vw - ${drawerWidth}px)`;

  return { handleHistoryDrawer, calculateSideBarDrawerWidth };
};

export default useGlobalFunction;

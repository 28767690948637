import { Box, CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import { ReactComponent as SearchIcon } from 'src/assets/search-icon.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const SimpleSearchFallback = ({
  showLoading,
  noOptions,
  showFallbackSearch
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          height: '258px',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #cad9f2',
          boxShadow: 'inset 0px -16px 8px -10px rgba(0, 0, 0, 0.06)'
        }}
      >
        {showFallbackSearch ? (
          <Box
            display={'flex'}
            gap={'8px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <SearchIcon />
            <Typography whiteSpace="nowrap">
              {t(T.findDocumentsText)}
            </Typography>{' '}
          </Box>
        ) : noOptions ? (
          <Typography whiteSpace="nowrap">{t(T.noResultsFound)}</Typography>
        ) : (
          <CircularProgress size={42} disableShrink thickness={2} />
        )}
      </Box>
    </Box>
  );
};

export default SimpleSearchFallback;

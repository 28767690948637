import { Box, useTheme } from '@mui/material';
import React, { RefObject } from 'react';
import SidebarAccordion from 'src/components/Accordion';
import { StringKeys } from 'src/types/base';
import { ChatData } from 'src/types/chat';
import { AccordionWrapper } from '../../styles';
import ChatList from '../TopSection/Chat/ListItem';
import { GroupTitle } from './styles';

interface GroupChatProps {
  groupTitle: string;
  chatGroup: ChatData[];
  onChatDeleted: (chatId: number) => void;
  onTitleEdited: (chatId: number, updatedTitle: string) => void;
  expandedSections?: StringKeys;
  setExpandedSections?: (chatId: StringKeys) => void;
  categoryRef?: RefObject<HTMLHeadingElement>;
}

const GroupChat: React.FC<GroupChatProps> = ({
  groupTitle,
  chatGroup,
  onChatDeleted,
  onTitleEdited,
  expandedSections,
  setExpandedSections,
  categoryRef
}) => {
  const theme = useTheme();
  if (chatGroup.length === 0) return null;

  if (expandedSections && categoryRef) {
    const groupTitleMap = {
      Today: 'today',
      'This week': 'thisWeek',
      'This month and older': 'thisMonth'
    };
    return (
      <AccordionWrapper>
        <SidebarAccordion
          expanded={expandedSections.chatHistory[groupTitleMap[groupTitle]]}
          onToggle={() =>
            setExpandedSections({
              ...expandedSections,
              chatHistory: {
                ...expandedSections.chatHistory,
                [groupTitleMap[groupTitle]]:
                  !expandedSections.chatHistory[groupTitleMap[groupTitle]]
              }
            })
          }
          heading={groupTitle}
          summaryRef={categoryRef}
          sx={{ bgcolor: theme.sidebar.background }}
        >
          {chatGroup.map((item) => (
            <ChatList
              data={item}
              key={item.id}
              onChatDeleted={onChatDeleted}
              onTitleEdited={onTitleEdited}
            />
          ))}
        </SidebarAccordion>
      </AccordionWrapper>
    );
  }

  return (
    <Box marginBottom={'16px'}>
      <GroupTitle>{groupTitle}</GroupTitle>
      {chatGroup.map((item) => (
        <ChatList
          data={item}
          key={item.id}
          onChatDeleted={onChatDeleted}
          onTitleEdited={onTitleEdited}
        />
      ))}
    </Box>
  );
};

export default GroupChat;

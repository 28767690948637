import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FileOption,
  FilterOption,
  TypeFilters,
  UseAdvancedSearch
} from './types';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { useLazyGetHybridSearchQuery } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { chatFileComparator } from 'src/content/Chat/utils';
import { RESOURCE_TYPE, USER_RESOURCE_PERMISSION } from 'src/types/enum';
import { uniqWith } from 'lodash';
import { useSelector } from 'src/redux/store';
import { getFilesOptionsFromData } from './utils';
import { ChatFile } from 'src/content/Chat/types';

export default function useAdvancedSearch({
  chatFiles,
  setChatFiles,
  closeAdvancedSearchDialog,
  onStartChatClick,
  searchInputRef,
  isAdvancedSearchDialogOpen,
  isDataLibrary
}: UseAdvancedSearch) {
  const { userResourceProviders } = useSelector((state) => state.data);
  const [filesOptions, setFilesOptions] = useState<FileOption[]>([]);
  const [sourceFilters, setSourceFilters] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typeFilters, setTypeFilters] = useState<TypeFilters>({
    resourceType: [],
    fileType: []
  });
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: { searchInput: searchValue }
  });
  const [fetchHybridSearchData, { isFetching, data: hybridSearchData }] =
    useLazyQuery({
      api: useLazyGetHybridSearchQuery,
      errorContext: ErrorContext.FILES
    });
  const isAnyOptionSelected = filesOptions.some(
    (option) => option.isSelected && !option.isDisabled
  );

  useEffect(() => {
    if (isAdvancedSearchDialogOpen && searchInputRef?.current?.value) {
      handleSearch({ searchInput: searchInputRef?.current?.value });
      setValue('searchInput', searchInputRef?.current?.value);
    }
  }, [isAdvancedSearchDialogOpen]);

  const handleFileSelect = (
    _,
    selectedFile: FileOption,
    isStartChatEnabled: boolean
  ) => {
    if (selectedFile.isDisabled || !isStartChatEnabled) return;
    setFilesOptions((prev) =>
      prev.map((item) =>
        item.id === selectedFile.id
          ? { ...selectedFile, isSelected: !selectedFile.isSelected }
          : item
      )
    );
  };

  const appendFiles = (files: ChatFile[]) => {
    const currValue = hybridSearchData.filter((item) =>
      files.some((option) => option.id === item.id && option.isSelected)
    );
    return uniqWith([...chatFiles, ...currValue], chatFileComparator);
  };

  const handleAddSelectedFiles = () => {
    const updatedChatFiles = appendFiles(filesOptions);
    setChatFiles(updatedChatFiles);
    closeAdvancedSearchDialog();
    if (onStartChatClick) {
      onStartChatClick(updatedChatFiles);
    }
  };

  const handleStartChatClick = (file: FileOption) => {
    const updatedValue = isDataLibrary
      ? [hybridSearchData.find((item) => item.id === file.id)]
      : appendFiles([{ ...file, isSelected: true }]);
    setChatFiles(updatedValue);
    closeAdvancedSearchDialog();
    if (onStartChatClick) {
      onStartChatClick(updatedValue);
    }
  };

  const handleSearch = async (formData) => {
    const { searchInput } = formData;
    if (searchInput) {
      fetchHybridSearchData({
        params: {
          params: {
            s: searchInput,
            is_advanced: 'True',
            ...(!isDataLibrary && { filter_by_query_permission: 'True' })
          }
        },
        successCallback: (res) => {
          updateOptionsBasedOnFiltersUnion(typeFilters, sourceFilters, res);
          setSearchValue(searchInput);
        }
      });
    }
  };

  const updateOptionsBasedOnFiltersUnion = (
    typeFilters: { resourceType: string[]; fileType: string[] },
    sourceFilters: number[],
    filesData = hybridSearchData
  ) => {
    const currentData = getFilesOptionsFromData(filesData, chatFiles);
    if (
      typeFilters.resourceType.length ||
      typeFilters.fileType.length ||
      sourceFilters.length
    ) {
      const updatedResourceTypeOptions = currentData.filter((file) =>
        typeFilters.resourceType.includes(file.type_code)
      );
      let updatedFileTypeOptions = [];
      if (!typeFilters.resourceType.includes(RESOURCE_TYPE.FILE)) {
        updatedFileTypeOptions = currentData.filter((file) =>
          typeFilters.fileType.includes(file.original_format)
        );
      }
      const filteredByType = uniqWith(
        [...updatedResourceTypeOptions, ...updatedFileTypeOptions],
        chatFileComparator
      );
      const isTypeFilterSelected =
        typeFilters.resourceType.length || typeFilters.fileType.length;
      let filteredResults = isTypeFilterSelected ? filteredByType : currentData;

      if (sourceFilters.length) {
        filteredResults = filteredResults.filter((file) =>
          sourceFilters.includes(file.provider_id)
        );
      }
      setFilesOptions(filteredResults);
    } else setFilesOptions(currentData);
  };

  const handleTypeFilterSelected = (typeFilterData: FilterOption[]) => {
    const filteredResourceTypes = typeFilterData
      .filter((item) => item.isSelected)
      .map((item) => item.value)
      .flat();
    const filteredFileTypes = typeFilterData
      .find((item) => item.value === RESOURCE_TYPE.FILE)
      .data.filter((item) => item.isSelected)
      .map((item) => item.value)
      .flat();
    const updatedTypeFilters = {
      resourceType: filteredResourceTypes,
      fileType: filteredFileTypes
    };
    setTypeFilters(updatedTypeFilters);
    updateOptionsBasedOnFiltersUnion(updatedTypeFilters, sourceFilters);
  };

  const handleSourceFilterSelected = (sourceFilterData: FilterOption[]) => {
    const updatedSourceFilters = sourceFilterData
      .filter((item) => item.isSelected)
      .map((item) => item.value);
    setSourceFilters(updatedSourceFilters);
    updateOptionsBasedOnFiltersUnion(typeFilters, updatedSourceFilters);
  };

  return {
    handleSubmit,
    handleSearch,
    control,
    hybridSearchData,
    handleFileSelect,
    handleTypeFilterSelected,
    handleSourceFilterSelected,
    handleAddSelectedFiles,
    isFetching,
    filesOptions,
    handleStartChatClick,
    userResourceProviders,
    searchValue,
    isAnyOptionSelected
  };
}

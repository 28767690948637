import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { ChatFile } from 'src/content/Chat/types';
import AdvancedSearchDialog from './AdvancedSearch/AdvancedSearchDialog';
import SimpleSearchDialog from './SimpleSearch/SimpleSearchDialog';

type ResourceSearchProps = {
  chatFiles?: ChatFile[];
  setChatFiles?: Dispatch<SetStateAction<ChatFile[]>>;
  fileSearchAnchorElem: HTMLElement | null;
  isFileSearchDialogOpen: boolean;
  closeFileSearchDialog: () => void;
  onStartChatClick?: (value: ChatFile[]) => void;
  onSimpleSearchItemClick?: (value: ChatFile) => void;
  isDataLibrary?: boolean;
  isSearchPage?: boolean;
};

export default function ResourceSearch({
  chatFiles = [],
  setChatFiles = () => {},
  fileSearchAnchorElem,
  isFileSearchDialogOpen,
  closeFileSearchDialog,
  onStartChatClick,
  onSimpleSearchItemClick,
  isDataLibrary,
  isSearchPage = false
}: ResourceSearchProps) {
  const [isAdvancedSearchDialogOpen, setIsAdvancedSearchDialogOpen] =
    useState<boolean>(isSearchPage || false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleOpenAdvancedSearchDialog = () => {
    setIsAdvancedSearchDialogOpen(true);
    closeFileSearchDialog();
  };

  const closeAdvancedSearchDialog = () => {
    setIsAdvancedSearchDialogOpen(false);
  };
  return (
    <>
      <SimpleSearchDialog
        chatFiles={chatFiles}
        fileSearchAnchorElem={fileSearchAnchorElem}
        setChatFiles={setChatFiles}
        openAdvancedSearch={handleOpenAdvancedSearchDialog}
        onItemClick={onSimpleSearchItemClick}
        onStartChatClick={onStartChatClick}
        isFileSearchDialogOpen={isFileSearchDialogOpen}
        closeFileSearchDialog={closeFileSearchDialog}
        isDataLibrary={isDataLibrary}
        searchInputRef={searchInputRef}
      />

      <AdvancedSearchDialog
        key={`advanced-search-${isAdvancedSearchDialogOpen}`}
        chatFiles={chatFiles}
        onStartChatClick={onStartChatClick}
        setChatFiles={setChatFiles}
        isAdvancedSearchDialogOpen={isAdvancedSearchDialogOpen}
        closeAdvancedSearchDialog={closeAdvancedSearchDialog}
        isDataLibrary={isDataLibrary}
        searchInputRef={searchInputRef}
        isSearchPage={isSearchPage}
      />
    </>
  );
}

import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { StyledTab, StyledTabs } from './styles';
import { CONTENT_PADDING } from 'src/utils/constants';

interface ITab {
  label: string;
  value: string;
  component: React.ElementType;
  permission: boolean;
}

interface TabGroupProps {
  tabs: ITab[];
  basePath?: string;
  defaultTab?: string;
  title?: string;
  renderTabsInsideComponent?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
  renderTabsInsideComponent?: boolean;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, renderTabsInsideComponent, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{
        overflow: 'auto',
        ...(renderTabsInsideComponent ? {} : { height: '91vh' })
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

const TabGroup: React.FC<TabGroupProps> = ({
  tabs,
  basePath = '',
  defaultTab,
  title,
  renderTabsInsideComponent
}) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const handleTabChange = (_: any, newValue: string) => {
    navigate(`${basePath}/${newValue}`);
    setSelectedTab(newValue);
  };
  const renderTabs = useMemo(
    () => (
      <StyledTabs onChange={handleTabChange} value={selectedTab}>
        {tabs
          .filter((tab) => tab.permission)
          .map((tab) => (
            <StyledTab key={tab.value} label={tab.label} value={tab.value} />
          ))}
      </StyledTabs>
    ),
    [selectedTab]
  );

  return (
    <Box height={'100%'} sx={{ background: '#fff', overflow: 'hidden' }}>
      {!renderTabsInsideComponent && (
        <Box
          display={'flex'}
          gap={'20px'}
          alignItems={'center'}
          padding={CONTENT_PADDING}
        >
          {title && <Typography variant="h3">{title}</Typography>}
          {renderTabs}
        </Box>
      )}
      {tabs.map(
        ({ component: Component, permission, value }) =>
          permission && (
            <TabPanel
              key={value}
              index={value}
              value={selectedTab}
              renderTabsInsideComponent={renderTabsInsideComponent}
            >
              <Component renderTabs={renderTabs} title={title} />
            </TabPanel>
          )
      )}
    </Box>
  );
};

export default TabGroup;

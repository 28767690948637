import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAlert } from 'src/redux/slices/snackbar';
import { APITags } from 'src/services/utils';
import { ALERT } from 'src/types/enum';
import { ErrorMessages, ErrorContext } from 'src/utils/errorMappings';
import logger from 'src/utils/logger';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { INVALID_USER_MAX_RETRIES, INVALID_USER_RETRY_DELAY, INVALID_USER_STATUS } from 'src/utils/utils';


const useMutation = <T, R>({
  api,
  errorContext = ErrorContext.GENERAL,
  subContext
}: {
  api: UseMutation<MutationDefinition<T, BaseQueryFn, APITags, R>>;
  errorContext: ErrorContext;
  subContext?: ErrorContext;
}) => {
  const [trigger, result] = api();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const retryFailedCountRef = useRef(0);

  const customTrigger = async ({
    params,
    fallbackMsg = T.internalServerError,
    successMsg,
    successCallback,
    errorCallback
  }: {
    params: T;
    fallbackMsg?: string;
    successMsg?: string;
    successCallback?: ((data: R) => void) | undefined;
    errorCallback?: ((error: SerializedError) => void) | undefined;
  }): Promise<R> => {
    try {
      const response = await trigger(params).unwrap();
      if (successMsg) {
        dispatch(
          setAlert({
            type: ALERT.SUCCESS,
            msg: t(successMsg)
          })
        );
      }
      if (successCallback) successCallback(response);
      return response;
    } catch (err) {
       // Retry, in case for INVALID USER error_status
      if (err?.status ===  INVALID_USER_STATUS && retryFailedCountRef.current < INVALID_USER_MAX_RETRIES){
        retryFailedCountRef.current += 1;

        setTimeout(() => {
          logger.log(`Retrying... Attempt ${retryFailedCountRef.current}`);
          return customTrigger({params, fallbackMsg, successMsg, successCallback,errorCallback}) // Retry with the same arguments
        }, INVALID_USER_RETRY_DELAY);

      } else {
        retryFailedCountRef.current = 0; // Reset for future requests
        const errorMsg =
        ErrorMessages[errorContext]?.[subContext]?.[err.status] ||
        ErrorMessages[errorContext]?.[err.status] ||
        ErrorMessages[ErrorContext.GENERAL][err.status] ||
        fallbackMsg;
        dispatch(
          setAlert({
            type: ALERT.ERROR,
            msg: t(errorMsg)
          })
        );

        if (errorCallback) errorCallback(err);
      }
    }
  };
  return [customTrigger, result] as const;
};

export default useMutation;

import { TryOutlined } from '@mui/icons-material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import { useContext, useMemo, useRef, useState } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';
import { RootState, useSelector } from 'src/redux/store';
import { ChatData } from 'src/types/chat';
import { CHAT_CLASS } from 'src/types/enum';
import { ChatListActionButtonsBox, ChatListBox } from '../../styles';
import DeleteChat from './DeleteChat';
import RenameChat from './RenameChat';

type Props = {
  data: ChatData;
  onChatDeleted: (chatId: number) => void;
  onTitleEdited: (id: number, title: string) => void;
};

function ChatList({ data: item, onTitleEdited, onChatDeleted }: Props) {
  const { toggleSidebar, isSidebarOpen } = useContext(SidebarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonRef = useRef<HTMLAnchorElement | null>(null);
  const [isEditingEnabled, setEditingEnabled] = useState<boolean>(false);
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const navigate = useNavigate();

  const showMoreButton = useMemo(
    () => selectedChat === item.id,
    [selectedChat, item]
  );

  const toggleEditing = () => {
    setEditingEnabled((isEditing) => !isEditing);
  };

  const handleEditClick = () => {
    if (selectedChat !== item?.id) {
      navigate(`/chat/${item?.id}`);
    }
    toggleEditing();
  };

  return (
    <ListItem
      key={item.id}
      disablePadding
      onClick={isMobile ? toggleSidebar : undefined}
      sx={{
        maxHeight: '28px',
        marginBottom: '4px'
      }}
    >
      <ChatListBox
        component="div"
        title={item.subject}
        isSidebarOpen={isSidebarOpen}
        selectedChat={item.id === selectedChat}
      >
        <Button
          ref={buttonRef}
          disableRipple
          fullWidth
          className={`chat-button ${showMoreButton ? 'Mui-active' : ''}`}
          component={selectedChat !== item.id ? RouterLink : Button}
          startIcon={
            item?.chat_class === CHAT_CLASS.ASSISTANT ? (
              <TryOutlined
                sx={{
                  color: isSidebarOpen ? 'white' : '#000C57',
                  padding: theme.spacing(0.2)
                }}
              />
            ) : (
              <ChatBubbleOutlineIcon
                sx={{
                  color: isSidebarOpen ? 'white' : '#000C57',
                  padding: theme.spacing(0.2)
                }}
              />
            )
          }
          to={`/chat/${item.id}`}
        >
          {isEditingEnabled ? (
            <RenameChat
              toggleEditing={toggleEditing}
              title={item.subject}
              onTitleEdited={onTitleEdited}
              isSidebarOpen={isSidebarOpen}
            />
          ) : (
            <CustomListItemText
              primaryText={item.subject}
              isSidebarOpen={isSidebarOpen}
            />
          )}
        </Button>
        {!isEditingEnabled && (
          <ChatListActionButtonsBox className="chat-action-buttons">
            <DeleteChat
              onDelete={onChatDeleted}
              chat_id={item.id}
              isSidebarOpen={isSidebarOpen}
            />
            <IconButton onClick={handleEditClick} size="small">
              <EditOutlinedIcon
                htmlColor={isSidebarOpen ? 'white' : '#000C57'}
                fontSize="small"
              />
            </IconButton>
          </ChatListActionButtonsBox>
        )}
      </ChatListBox>
    </ListItem>
  );
}

export default ChatList;

import { useSelector } from 'src/redux/store';
import { RESOURCE_PROVIDERS } from 'src/types/enum';

const useFileInfo = () => {
  const { oneDriveProviderId, sharePointProviderId, confluenceProviderId } =
    useSelector((state) => state.data);
  const providerMap = {
    [oneDriveProviderId]: RESOURCE_PROVIDERS.ONEDRIVE,
    [sharePointProviderId]: RESOURCE_PROVIDERS.SHAREPOINT,
    [confluenceProviderId]: RESOURCE_PROVIDERS.CONFLUENCE
  };

  const getProviderType = (providerId: number) => providerMap[providerId] || '';

  return {
    getProviderType,
    oneDriveProviderId,
    sharePointProviderId,
    confluenceProviderId
  };
};

export default useFileInfo;

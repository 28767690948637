import { forwardRef } from 'react';
import { NavLinkProps, NavLink as RouterNavLink } from 'react-router-dom';

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ className, ...rest }, ref) => {
    return (
      <RouterNavLink
        ref={ref}
        className={({ isActive }) =>
          `${className} ${isActive ? 'Mui-active' : ''}`
        }
        {...rest}
      />
    );
  }
);

export default NavLink;

import React from 'react';
import { Box, CardContent, Typography } from '@mui/material';
import { MainTextTypography, SmallIconStyles, StyledCard } from './styles';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ReactComponent as SupportIcon } from 'src/assets/helpAction/contact-support.svg';
import { ReactComponent as ImprovementIcon } from 'src/assets/helpAction/suggest-improvemet.svg';
import { ReactComponent as ReportIcon } from 'src/assets/helpAction/report-issue.svg';
import { HelpAndSupportTabs } from './utils';

const SupportTabs = ({
  selectedTab,
  setSelectedTab,
  setValue,
  setAttachedFiles
}: {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  setValue: any;
  setAttachedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: HelpAndSupportTabs.CONTACT_SUPPORT,
      label: (
        <MainTextTypography variant="body1" style={{ fontSize: '14px' }}>
          {t(T.contactSupport)}
        </MainTextTypography>
      ),
      icon: (
        <Box sx={SmallIconStyles}>
          <SupportIcon />
        </Box>
      )
    },
    {
      id: HelpAndSupportTabs.REPORT_ISSUE,
      label: (
        <MainTextTypography variant="body1" style={{ fontSize: '14px' }}>
          {t(T.ReportIssue)}
        </MainTextTypography>
      ),
      icon: (
        <Box sx={SmallIconStyles}>
          <ReportIcon />
        </Box>
      )
    },
    {
      id: HelpAndSupportTabs.SUGGEST_IMPROVEMENTS,
      label: (
        <MainTextTypography variant="body1" style={{ fontSize: '14px' }}>
          {t(T.suggestedImprovements)}
        </MainTextTypography>
      ),
      icon: (
        <Box sx={SmallIconStyles}>
          <ImprovementIcon />
        </Box>
      )
    }
  ];

  const handleReset = (tab: string) => {
    setSelectedTab(tab);
    setValue('subject', '');
    setValue('message', '');
    setAttachedFiles([]);
  };

  const handleTabChange = (tab: string) => {
    handleReset(tab);
  };

  return (
    <>
      <Box display="flex" justifyContent="center" gap={0} mb={3}>
        {tabs.map((tab) => (
          <StyledCard
            key={tab.id}
            isSelected={selectedTab === tab.id}
            onClick={() => handleTabChange(tab.id)}
          >
            <CardContent>
              <Box display="flex" justifyContent="center" mb={2}>
                {tab.icon}
              </Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  color: '#333',
                  fontSize: '14px',
                  lineHeight: '20px'
                }}
              >
                {tab.label}
              </Typography>
            </CardContent>
          </StyledCard>
        ))}
      </Box>
    </>
  );
};

export default SupportTabs;

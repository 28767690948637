import useGlobalFunction from 'src/hooks/useGlobalFunction';
import { RootState, useSelector } from 'src/redux/store';
import HistoryPanel from '../Sidebar/History/HistoryPanel';
import { HistoryDrawer } from '../styles';

const ChatHistoryDrawer = ({ setShowButton }) => {
  const { showChatHistoryPanel } = useSelector(
    (state: RootState) => state.chat
  );
  const { handleHistoryDrawer } = useGlobalFunction();

  return (
    <HistoryDrawer
      variant="persistent"
      anchor="left"
      open={showChatHistoryPanel}
      onMouseEnter={() => setShowButton(false)}
      onMouseLeave={(event) => handleHistoryDrawer(event, false)}
    >
      <HistoryPanel />
    </HistoryDrawer>
  );
};

export default ChatHistoryDrawer;

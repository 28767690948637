import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as EgptLogo } from 'src/assets/sidebarIcon/egpt.svg';
import { ReactComponent as MinimizedEgptLogo } from 'src/assets/sidebarIcon/minimized_egpt.svg';
import { ReactComponent as NewChat } from 'src/assets/sidebarIcon/new-chat.svg';
import { resetAssistantContext } from 'src/content/Documents/utils/gridUtils';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  StyledIconContainer,
  StyledLogoContainer,
  TopSectionContainer
} from 'src/layouts/AccentHeaderLayout/styles';
import {
  closeDocumentViewer,
  createChatSnapshot,
  setChatCreationError,
  setSelectedChat
} from 'src/redux/slices/chat';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { CHAT_PRE_CREATION_ID } from 'src/utils/constants';
import { TRANSLATION_CONSTANTS } from 'src/utils/translations';

const CreateChat = () => {
  const { t } = useTranslation();
  const { isSidebarOpen } = useContext(SidebarContext);
  const { selectedChat, chatCreationError } = useSelector(
    (state: RootState) => state.chat
  );
  const dispatch = useDispatch();
  const { state: routeState } = useLocation();

  const handleCreateNewChat = () => {
    if (selectedChat !== 0 || routeState || chatCreationError) {
      if (chatCreationError) dispatch(setChatCreationError({ isError: false }));
      dispatch(closeDocumentViewer(selectedChat));
      dispatch(setSelectedChat(undefined));
      dispatch(createChatSnapshot({ chatId: CHAT_PRE_CREATION_ID }));
      resetAssistantContext(dispatch);
      setTimeout(() => {
        dispatch(setSelectedChat(0));
      }, 100);
    }
  };

  return (
    <TopSectionContainer>
      <StyledLogoContainer isSidebarOpen={isSidebarOpen}>
        {isSidebarOpen ? (
          <EgptLogo />
        ) : (
          <StyledIconContainer>
            <MinimizedEgptLogo />
          </StyledIconContainer>
        )}
      </StyledLogoContainer>
      <Button
        sx={{
          borderRadius: '6px',
          width: '100%',
          height: '40px',
          minWidth: isSidebarOpen ? '64px' : '100%',
          paddingX: !isSidebarOpen ? 0 : 'inherit',
          marginTop: isSidebarOpen ? 0 : '5px'
        }}
        component={NavLink}
        variant="contained"
        color="secondary"
        to="/chat"
        onClick={handleCreateNewChat}
        size={isSidebarOpen ? 'large' : 'small'}
        {...(!isSidebarOpen && {
          title: t(TRANSLATION_CONSTANTS.createNewChat)
        })}
        startIcon={
          isSidebarOpen && (
            <NewChat
              style={{
                height: isSidebarOpen ? '16px' : '20px',
                width: isSidebarOpen ? '16px' : '20px'
              }}
            />
          )
        }
      >
        {isSidebarOpen ? (
          <Typography variant="h6" fontSize={'16px'} fontWeight={400}>
            {t(TRANSLATION_CONSTANTS.createNewChat)}
          </Typography>
        ) : (
          <NewChat height={'20px'} width={'20px'} />
        )}
      </Button>
    </TopSectionContainer>
  );
};

export default CreateChat;

import {
  Avatar,
  styled,
  Box,
  IconButton,
  lighten,
  Button,
  Typography,
  Divider
} from '@mui/material';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { LoadingButton } from '@mui/lab';

interface ActionButtonProps {
  props?: {
    isBorderTop?: boolean;
  };
}

export const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.colors.alpha.trueWhite[70]};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
      margin: ${theme.spacing(1)};
  
      &:hover {
          background: ${lighten(theme.colors.primary.main, 0.2)};
          color: ${theme.colors.alpha.trueWhite[100]};
      }
  `
);

export const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.primary.lighter};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
    `
);

export const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.error.light};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

export const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.success.light};
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
    `
);

export const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(11)};
      height: ${theme.spacing(11)};
  `
);

export const StarRateIcon = styled(StarRateRoundedIcon)(
  ({ theme }) => `
      color: #4C5778;
  `
);

export const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        height: 300px;
        background: ${theme.colors.alpha.black[5]};
        border: 1px dashed ${theme.colors.alpha.black[30]};
        outline: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: ${theme.transitions.create(['border', 'background'])};
    
        &:hover {
          background: ${theme.colors.alpha.white[100]};
          border-color: ${theme.colors.primary.main};
        }
    `
);

export const ActionButton = styled(Button)<ActionButtonProps>(({ props }) => ({
  padding: '4.5px 16px',
  borderRadius: 0,
  borderTop: props?.isBorderTop && '1px solid #E5E5E5',
  display: 'flex',
  justifyContent: 'flex-start'
}));

export const FileDetailsHeader = styled(Box)({
  position: 'absolute',
  right: '20px',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '4px'
});

export const SidebarActionButton = styled(LoadingButton)({
  padding: '0px',
  minWidth: '20px',
  minHeight: '20px'
});

export const DetailRowWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

export const RowKeyText = styled(Typography)({
  color: 'var(--Secondary-400, var(--Secondary-400, #495990))',
  width: '80px'
});

export const RowValueText = styled(Typography)({
  color: 'var(--Secondary-500-Main, #222F59);'
});

export const UploadedByText = styled(Typography)({
  color: 'var(--Secondary-400, var(--Secondary-400, #495990))',
  marginBottom: '12px'
});

export const DetailsBoxWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
});

export const HorizontalDivider = styled(Divider)({
  marginTop: '12px',
  marginBottom: '12px'
});

export const TagsHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '6px'
});

export const MainSidebarWrapper = styled(Box)({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden'
});

export const TagsWrapper = styled(Box)({
  marginBottom: '25px',
  flexGrow: 1
});

export const StackedChipsWrapper = styled(Box)({
  height: '100%',
  overflow: 'auto'
});

export const DetailsDisclaimerBox = styled(Box)({
  position: 'relative',
  bottom: '5px',
  left: '3px'
});

export const CollectionIconStyles = {
  height: '64px',
  width: '64px',
  minHeight: '64px',
  minWidth: '64px'
};

export const ResourceIconStyles = {
  width: '66px',
  height: '66px'
};

export const ViewScrollContainer = styled(Box)(({ theme }) => ({
  minHeight: '100%',
  overflowY: 'auto',
  margin: '0px -24px',
  padding: '0px 24px'
}));

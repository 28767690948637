import styled from '@emotion/styled';
import { Box, IconButton, Typography } from '@mui/material';

export const GroupTitle = styled(Typography)(
  `overflow: hidden;
  color: #010C57B2;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0 10px;`
);

export const HeaderBox = styled(Box)({
  color: 'white',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '20px 16px 6px 16px',
  gap: '10px',
  borderRadius: '5px',
  margin: '2px 0px',
  position: 'relative'
});

export const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  top: '12px',
  right: '12px',
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.25)'
  }
});

export const StyledBackButton = styled(IconButton)({
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.25)'
  }
});

export const ChatBox = styled(Box)({
  overflowY: 'auto',
  padding: '16px 0',
  position: 'absolute',
  height: '100%',
  width: '100%'
});

export const HistoryText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px'
});

import { useEffect, useRef, useState } from 'react';
import Assistants from '../Sidebar/Assistants';
import FavouriteDataLibrary from '../Sidebar/Favourites/FavouriteDataLibrary';
import HistoryPanel from '../Sidebar/History/HistoryPanel';
import { AccordionWrapper, ScrolledBox } from '../styles';

const ScrollableSection = () => {
  const [expandedSections, setExpandedSections] = useState({
    assistants: true,
    workspaces: true,
    chatHistory: { today: true, thisWeek: true, thisMonth: true }
  });

  const sidebarRef = useRef<HTMLDivElement>(null);
  const assistantsRef = useRef<HTMLDivElement>(null);
  const workspacesRef = useRef<HTMLDivElement>(null);
  const todayRef = useRef<HTMLDivElement>(null);
  const thisWeekRef = useRef<HTMLDivElement>(null);
  const thisMonthRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (!sidebarRef.current) return;

    const sections = [
      { ref: assistantsRef, threshold: -Infinity },
      {
        ref: thisWeekRef,
        threshold: thisWeekRef.current?.getBoundingClientRect().top
      },
      {
        ref: thisMonthRef,
        threshold: thisMonthRef.current?.getBoundingClientRect().top
      },
      {
        ref: workspacesRef,
        threshold: workspacesRef.current?.getBoundingClientRect().top
      },
      {
        ref: todayRef,
        threshold: todayRef.current?.getBoundingClientRect().top
      }
    ];

    const scrollY = sidebarRef.current.scrollTop;
    let activeSection = sections[0];

    for (const section of sections.slice().reverse()) {
      if (scrollY >= (section.threshold ?? Infinity) - 50) {
        activeSection = section;
        break;
      }
    }

    sections.forEach(({ ref }) => {
      ref.current?.classList.toggle('sticky', ref === activeSection.ref);
    });
  };

  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (sidebar) {
      sidebar.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (sidebar) {
        sidebar.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <ScrolledBox ref={sidebarRef} onScroll={handleScroll}>
      <AccordionWrapper>
        <Assistants
          expandedSections={expandedSections}
          setExpandedSections={setExpandedSections}
          assistantsRef={assistantsRef}
        />
      </AccordionWrapper>

      <AccordionWrapper>
        <FavouriteDataLibrary
          expandedSections={expandedSections}
          setExpandedSections={setExpandedSections}
          workspacesRef={workspacesRef}
        />
      </AccordionWrapper>

      <HistoryPanel
        expandedSections={expandedSections}
        setExpandedSections={setExpandedSections}
        todayRef={todayRef}
        thisWeekRef={thisWeekRef}
        thisMonthRef={thisMonthRef}
        sidebarRef={sidebarRef}
      />
    </ScrolledBox>
  );
};

export default ScrollableSection;

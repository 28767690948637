import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useMutation from 'src/hooks/useMutation';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import {
  useAddFavouriteMutation,
  useRemoveFavouriteMutation
} from 'src/services/api';
import { FAVOURITE_TYPE, RESOURCE_TYPE } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { SelectedDocument } from '../types/document';

type DocumentsContextType = {
  selectedDocument: null | SelectedDocument;
  setSelectedDocument: React.Dispatch<SelectedDocument | null>;
  isUploadModalOpen: boolean;
  toggleUploadModal: () => void;
  isDetailsDrawerOpen: boolean;
  toggleDetailsDrawer: () => void;
  isUploadingDocuments: boolean;
  setIsUploadingDocuments: React.Dispatch<React.SetStateAction<boolean>>;
  resourcePermissions: any;
  setResourcePermissions: React.Dispatch<React.SetStateAction<any>>;
  currentResourceType: RESOURCE_TYPE;
  setCurrentResourceType: React.Dispatch<React.SetStateAction<RESOURCE_TYPE>>;
  isSharePointImportModalOpen: boolean;
  setIsSharePointImportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSharePointImportSuccessModalOpen: boolean;
  setIsSharePointImportSuccessModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isOneDriveImportModalOpen: boolean;
  setIsOneDriveImportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOneDriveImportSuccessModalOpen: boolean;
  setIsOneDriveImportSuccessModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  updateFavourite: (
    id: number,
    isAdd?: boolean,
    type?: string
  ) => Promise<boolean>;
  isUpdatingFavourite: boolean;
  isConfluenceImportModalOpen: boolean;
  setIsConfluenceImportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isConfluenceImportSuccessModalOpen: boolean;
  setIsConfluenceImportSuccessModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const DocumentsContext = React.createContext<DocumentsContextType>({
  selectedDocument: null,
  setSelectedDocument: null,
  isUploadModalOpen: false,
  toggleUploadModal: () => {},
  isDetailsDrawerOpen: false,
  toggleDetailsDrawer: () => {},
  isUploadingDocuments: false,
  setIsUploadingDocuments: () => {},
  resourcePermissions: {},
  setResourcePermissions: () => {},
  currentResourceType: RESOURCE_TYPE.NAMESPACE,
  setCurrentResourceType: () => {},
  isSharePointImportModalOpen: false,
  setIsSharePointImportModalOpen: () => {},
  isSharePointImportSuccessModalOpen: false,
  setIsSharePointImportSuccessModalOpen: () => {},
  isOneDriveImportModalOpen: false,
  setIsOneDriveImportModalOpen: () => {},
  isOneDriveImportSuccessModalOpen: false,
  setIsOneDriveImportSuccessModalOpen: () => {},
  updateFavourite: () => new Promise((resolve) => resolve(true)),
  isUpdatingFavourite: false,
  isConfluenceImportModalOpen: false,
  setIsConfluenceImportModalOpen: () => {},
  isConfluenceImportSuccessModalOpen: false,
  setIsConfluenceImportSuccessModalOpen: () => {}
});

export const DocumentsContextProvider = ({ children }) => {
  const [selectedDocument, setSelectedDocument] =
    useState<SelectedDocument | null>(null);
  const dispatch = useDispatch();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const [resourcePermissions, setResourcePermissions] = useState<any>({});
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] =
    useState<boolean>(false);
  const [currentResourceType, setCurrentResourceType] = useState<RESOURCE_TYPE>(
    RESOURCE_TYPE.NAMESPACE
  );
  const [isSharePointImportModalOpen, setIsSharePointImportModalOpen] =
    useState<boolean>(false);
  const [
    isSharePointImportSuccessModalOpen,
    setIsSharePointImportSuccessModalOpen
  ] = useState<boolean>(false);

  const [isOneDriveImportModalOpen, setIsOneDriveImportModalOpen] =
    useState<boolean>(false);
  const [
    isOneDriveImportSuccessModalOpen,
    setIsOneDriveImportSuccessModalOpen
  ] = useState<boolean>(false);

  const [isConfluenceImportModalOpen, setIsConfluenceImportModalOpen] =
    useState<boolean>(false);
  const [
    isConfluenceImportSuccessModalOpen,
    setIsConfluenceImportSuccessModalOpen
  ] = useState<boolean>(false);

  const [isUploadingDocuments, setIsUploadingDocuments] =
    useState<boolean>(false);

  const [addFavourite, { isLoading: isAddingFavourite }] = useMutation({
    api: useAddFavouriteMutation,
    errorContext: ErrorContext.FAVOURITES
  });
  const [removeFavourite, { isLoading: isRemovingFavourite }] = useMutation({
    api: useRemoveFavouriteMutation,
    errorContext: ErrorContext.FAVOURITES
  });
  const isUpdatingFavourite = useMemo(() => {
    return isAddingFavourite || isRemovingFavourite;
  }, [isAddingFavourite, isRemovingFavourite]);
  const toggleUploadModal = useCallback(
    () => setIsUploadModalOpen((prevState) => !prevState),
    []
  );

  const toggleDetailsDrawer = useCallback(
    () => setIsDetailsDrawerOpen((prevState) => !prevState),
    []
  );

  const updateFavourite = async (
    id: number,
    isAdd?: boolean,
    type?: string
  ) => {
    if (isAdd)
      return await addFavourite({
        params: {
          params: {
            item_id: id,
            item_type:
              type === FAVOURITE_TYPE.ASSISTANT
                ? FAVOURITE_TYPE.ASSISTANT
                : FAVOURITE_TYPE.RESOURCE
          }
        },
        successMsg: T.markedFavourite,
        fallbackMsg: T.markFavouriteError,
        errorCallback: () => {
          dispatch(setIsFavouritesFetching(false));
        }
      });
    else
      return await removeFavourite({
        params: {
          params: {
            item_id: id,
            item_type:
              type === FAVOURITE_TYPE.ASSISTANT
                ? FAVOURITE_TYPE.ASSISTANT
                : FAVOURITE_TYPE.RESOURCE
          }
        },
        successMsg: T.unmarkedFavourite,
        fallbackMsg: T.unmarkFavouriteError,
        errorCallback: () => {
          dispatch(setIsFavouritesFetching(false));
        }
      });
  };

  return (
    <DocumentsContext.Provider
      value={{
        selectedDocument,
        setSelectedDocument,
        isUploadModalOpen,
        toggleUploadModal,
        isDetailsDrawerOpen,
        toggleDetailsDrawer,
        isUploadingDocuments,
        setIsUploadingDocuments,
        resourcePermissions,
        setResourcePermissions,
        currentResourceType,
        setCurrentResourceType,
        isSharePointImportModalOpen,
        setIsSharePointImportModalOpen,
        isSharePointImportSuccessModalOpen,
        setIsSharePointImportSuccessModalOpen,
        isOneDriveImportModalOpen,
        setIsOneDriveImportModalOpen,
        isOneDriveImportSuccessModalOpen,
        setIsOneDriveImportSuccessModalOpen,
        updateFavourite,
        isUpdatingFavourite,
        isConfluenceImportModalOpen,
        setIsConfluenceImportModalOpen,
        isConfluenceImportSuccessModalOpen,
        setIsConfluenceImportSuccessModalOpen
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsContext;

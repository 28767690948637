import { Control, FormState } from "react-hook-form";

export type FormValues = {
  subject: string;
  message: string;
};

export const defaultFormValues = {
  subject: '',
  message: ''
};
export interface HelpFormFieldsProps {
  control: Control<FormValues>;
  errors: FormState<FormValues>['errors'];
  selectedTab: string;
}

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce, isEqual, uniqWith } from 'lodash';
import get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatedText } from 'src/content/Chat/styles';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { filterEmptyValuesFromList } from 'src/utils/utils';

interface SearchableAutoCompleteProps {
  searchApi: any;
  label?: string;
  placeholder?: string;
  optionLabel: string;
  multiple?: boolean;
  required?: boolean;
  customLabel?: boolean;
  onChange: (event: any, value: any) => void;
  valueKey: string;
  value?: string;
  [z: string]: any;
  searchApiParams?: StringKeys;
  customValidation?: (value: StringKeys | string) => boolean;
  autoSelect?: boolean;
  hideEndAdornment?: boolean;
  useSearchApi: boolean;
  hideCloseIcon?: boolean;
  shouldInputReset?: boolean;
  valueOptionsComparator?: (a: any, b: any) => boolean;
  maxLength?: number;
  CustomFallback?: React.FC<{
    showLoading: boolean;
    noOptions: boolean;
    showFallbackSearch: boolean;
  }>;
  CustomPopper?: React.FC;
}

const SearchableAutoComplete = React.forwardRef<
  HTMLDivElement | null,
  SearchableAutoCompleteProps
>(
  (
    {
      searchApi,
      label = '',
      placeholder,
      optionLabel,
      multiple = false,
      required = false,
      customLabel = false,
      shouldInputReset = true,
      onChange,
      valueKey,
      value,
      searchApiParams,
      customValidation,
      hideEndAdornment = false,
      useSearchApi = true,
      hideCloseIcon,
      CustomFallback,
      valueOptionsComparator,
      maxLength,
      CustomPopper,
      textFieldProps,
      ...rest
    }: SearchableAutoCompleteProps,
    ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [fetchData, { data, isFetching, isError }] = searchApi();
    const [selectValue, setSelectValue] = useState<any>(multiple ? [] : '');
    const [isFieldFocused, setIsFieldFocused] = useState<boolean>(false);
    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);

    const { noOptions, showLoading, showFallbackSearch } = {
      noOptions:
        !!inputValue && !options?.length && data && !isLoading && !isFetching,
      showLoading: !!isFetching || !!isLoading,
      showFallbackSearch: (!inputValue && !options.length) || isError
    };
    const showFallback = noOptions || showLoading || showFallbackSearch;

    const toggleFieldFocus = () => {
      setIsFieldFocused(!isFieldFocused);
    };

    const searchData = useCallback(
      debounce(async (s: string) => {
        if (!s) return;
        try {
          await fetchData({ params: { s, ...searchApiParams } }).unwrap();
        } catch (error) {
          setOptions([]);
        } finally {
          setIsLoading(false);
        }
      }, 300),
      []
    );

    const handleInputChange = async (event) => {
      const inputValue = event.target.value;
      if (maxLength && inputValue?.length >= maxLength) return;
      setIsOptionSelected(false);
      setInputValue(inputValue);
      if (inputValue === '') {
        if (multiple) setOptions([...value]);
        else setOptions([]);
        setIsLoading(false);
      }
      setIsLoading(true);
      if (useSearchApi) searchData(inputValue);
    };

    const handleSelectionChange = (_, selectedValues) => {
      setIsOptionSelected(true);
      if (customValidation) {
        if (customValidation(selectedValues[selectedValues.length - 1])) {
          setSelectValue(() => selectedValues);
          setOptions([]);
        } else {
          return;
        }
      } else if (!customValidation) {
        setSelectValue(() => selectedValues);
      }
      onChange(_, selectedValues);
    };

    useEffect(() => {
      const filteredData = filterEmptyValuesFromList(data, optionLabel);
      if (data && !isFetching) setIsLoading(false);
      if (data?.length && !isFetching) {
        if (multiple) {
          setOptions(
            uniqWith(
              [...value, ...filteredData],
              valueOptionsComparator || isEqual
            )
          );
        } else setOptions(filteredData);
      }
    }, [data, isFetching]);

    useEffect(() => {
      if (!value) {
        setSelectValue(multiple ? [] : null);
        setOptions([]);
      } else {
        setSelectValue(value);
      }
    }, [value]);

    const handleClearInput = useCallback(() => {
      setSelectValue(multiple ? [] : null);
      setOptions([]);
      setIsLoading(false);
    }, []);

    const PopperComponent = CustomPopper
      ? useMemo(() => (props) => <CustomPopper {...props} />, [])
      : undefined;

    return (
      <Box>
        {customLabel && (
          <Typography
            sx={{
              marginBottom: '5px',
              marginLeft: '2px',
              color: theme.colors.custom.labelColor,
              fontSize: '13px'
            }}
            gutterBottom
          >
            {label}
          </Typography>
        )}
        <Autocomplete
          ref={ref}
          limitTags={1}
          size="small"
          autoHighlight
          id="reusable-autocomplete"
          options={options}
          inputValue={shouldInputReset ? undefined : inputValue}
          open={!!options.length && open && !showFallback}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return get(option, optionLabel) || '';
          }}
          isOptionEqualToValue={(option, _value) => {
            if (option instanceof Object && _value instanceof Object) {
              return (
                get(option, valueKey || 'id') === get(_value, valueKey || 'id')
              );
            }
            return typeof option === 'string'
              ? option === value
              : option?.[valueKey || 'id'] === value?.[valueKey || 'id'];
          }}
          multiple={multiple}
          onChange={handleSelectionChange}
          componentsProps={{
            clearIndicator: {
              sx: {
                mr: 0.01,
                color: theme.colors.alpha.black[100],
                backgroundColor: 'transparent',
                '&:hover': {
                  color: theme.colors.alpha.black[100],
                  backgroundColor: theme.colors.alpha.black[10]
                }
              }
            }
          }}
          value={selectValue}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={customLabel ? '' : label}
                variant="outlined"
                placeholder={
                  selectValue?.length && isFieldFocused ? '' : placeholder
                }
                onChange={handleInputChange}
                onFocus={toggleFieldFocus}
                onBlur={toggleFieldFocus}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isFetching && !hideEndAdornment ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (!!selectValue?.length ||
                          !!selectValue?.username?.length) &&
                        !hideCloseIcon ? (
                        <CloseIcon
                          onClick={handleClearInput}
                          sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '10%',
                            right: '15px'
                          }}
                        />
                      ) : null}
                    </React.Fragment>
                  )
                }}
                {...textFieldProps}
              />
            );
          }}
          popupIcon={<KeyboardArrowDownIcon />}
          PopperComponent={PopperComponent}
          {...rest}
        />
        {!CustomFallback && isFetching && !isOptionSelected && (
          <Box
            width={'100%'}
            border={'1px solid rgb(202, 217, 242)'}
            padding={'8px 10px'}
          >
            <AnimatedText>{t(T.searching)}</AnimatedText>
          </Box>
        )}
        {!!CustomFallback && showFallback && (
          <CustomFallback
            showLoading={showLoading}
            noOptions={noOptions}
            showFallbackSearch={showFallbackSearch}
          />
        )}
      </Box>
    );
  }
);

export default SearchableAutoComplete;

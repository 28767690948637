import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import ChevronRight from 'src/assets/ChevronRight.svg';
import { ReactComponent as SearchIcon } from 'src/assets/search-icon.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import AdvancedSearchFilter from './AdvancedSearchFilter';
import {
  AddSelectedFilesButton,
  searchButtonStyles,
  searchInputFieldStyles
} from './styles';
import { fileTypeFiltersData, getSourceFiltersData } from './utils';

const SearchBar = ({
  handleSubmit,
  handleSearch,
  control,
  hybridSearchData,
  handleSourceFilterSelected,
  userResourceProviders,
  handleTypeFilterSelected,
  isAnyOptionSelected,
  handleAddSelectedFiles,
  isDataLibrary
}) => {
  return (
    <form onSubmit={handleSubmit(handleSearch)}>
      <Box mt={1}>
        <Controller
          name="searchInput"
          control={control}
          render={({ field }) => (
            <Box>
              <TextField
                id="searchInput"
                {...field}
                placeholder="Search"
                sx={searchInputFieldStyles}
                fullWidth
                autoComplete="off"
                autoFocus
                size="small"
                inputProps={{ maxLength: 250 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: 'pointer'
                      }}
                    >
                      <Box
                        sx={searchButtonStyles}
                        onClick={handleSubmit(handleSearch)}
                        component={'img'}
                        src={ChevronRight}
                      />
                    </InputAdornment>
                  )
                }}
              />
              {hybridSearchData ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: '100%',
                    pt: '12px'
                  }}
                >
                  <Box display="flex" gap={'12px'}>
                    <AdvancedSearchFilter
                      onChange={handleSourceFilterSelected}
                      filtersData={getSourceFiltersData(userResourceProviders)}
                      filterLabel="Source"
                    />
                    <AdvancedSearchFilter
                      onChange={handleTypeFilterSelected}
                      filtersData={fileTypeFiltersData}
                      filterLabel="Type"
                    />
                  </Box>
                  <AddSelectedFilesButton
                    sx={{
                      visibility: isAnyOptionSelected ? 'visible' : 'hidden'
                    }}
                    onClick={handleAddSelectedFiles}
                  >
                    <Typography
                      variant="body1"
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      whiteSpace={'nowrap'}
                      color={'#000C57'}
                      fontSize={'14px'}
                      lineHeight={'16.5px'}
                    >
                      {isDataLibrary
                        ? t(T.startChatOnSelectedFiles)
                        : t(T.addSelectedFilesToChat)}
                    </Typography>
                  </AddSelectedFilesButton>
                </Box>
              ) : null}
            </Box>
          )}
        />
      </Box>
    </form>
  );
};

export default SearchBar;

import { Chip, Box } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SellIcon from '@mui/icons-material/Sell';

type ChipItemProps = {
  item: {
    id: string | number;
    text: string;
  };
  allowRemove?: boolean;
  onTagRemove?: (id: string | number) => void;
  size?: 'small' | 'medium';
  disabled?: boolean;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
};

const ChipItem = ({
  item,
  allowRemove,
  onTagRemove,
  size = 'small',
  disabled,
  color = 'primary'
}: ChipItemProps) => {
  const onDelete = () => {
    onTagRemove(item.id);
  };
  const deleteProps = allowRemove
    ? {
        onDelete: onDelete,
        deleteIcon: allowRemove ? <CloseOutlinedIcon sx={{ ml: 14 }} /> : null
      }
    : {};

  return (
    <Chip
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <SellIcon fontSize="small" />
          {item.text}
        </Box>
      }
      color={color}
      disabled={disabled}
      sx={{
        p: 0.6
      }}
      size={size}
      {...deleteProps}
    />
  );
};

export default ChipItem;

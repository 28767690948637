import { AlertColor } from '@mui/material';
import { ErrorCode, FileRejection } from 'react-dropzone';
import { TFunction } from 'react-i18next';
import { setAlert } from 'src/redux/slices/snackbar';
import { AppDispatch } from 'src/redux/store';
import { IDropZoneFileValidator } from 'src/types/document';
import { ALERT, RESOURCE_TYPE } from 'src/types/enum';
import { FILE_NAME_LENGTH_LIMIT } from 'src/utils/files/file';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { UPLOAD_CONSTRAINT_RESOURCES } from '../utils/utils';

interface DropHandler {
  acceptedFiles: IDropZoneFileValidator[];
  fileRejections: FileRejection[];
  currentResourceType: RESOURCE_TYPE;
  dispatch: AppDispatch;
  t: TFunction<'translation', undefined>;
  toggleUploadModal?: () => void;
  unauthorized?: boolean;
}

export const filesValidator = (
  file: IDropZoneFileValidator,
  currentResourceType: RESOURCE_TYPE,
  dispatch: AppDispatch,
  t: TFunction<'translation', undefined>
) => {
  const path: string[] = file?.path?.split('/');
  if (path?.length < 2) {
    if (UPLOAD_CONSTRAINT_RESOURCES.includes(currentResourceType)) {
      dispatch(
        setAlert({
          msg: t(T.workSpaceDocumentsDropError),
          type: ALERT.ERROR
        })
      );
      return {
        message: t(T.workSpaceDocumentsDropError),
        code: ErrorCode.FileInvalidType
      };
    } else if (
      currentResourceType === RESOURCE_TYPE.COLLECTION ||
      currentResourceType === RESOURCE_TYPE.FOLDER
    ) {
      return null;
    } else {
      return {
        message: t(T.filesPermission),
        code: ErrorCode.FileInvalidType
      };
    }
  }
  return null;
};

export const dropHandler = ({
  acceptedFiles,
  fileRejections,
  currentResourceType,
  dispatch,
  t,
  toggleUploadModal,
  unauthorized = false
}: DropHandler) => {
  if (unauthorized) {
    dispatch(
      setAlert({
        type: ALERT.ERROR,
        msg: t(T.unauthorizedFilesError)
      })
    );
    return;
  } else {
    if (
      UPLOAD_CONSTRAINT_RESOURCES.includes(currentResourceType) &&
      !fileRejections.length
    ) {
      const path = (acceptedFiles as IDropZoneFileValidator[])?.[0]?.path;
      if (
        (toggleUploadModal && path?.split('/')?.length >= 2) ||
        !UPLOAD_CONSTRAINT_RESOURCES.includes(currentResourceType)
      ) {
        toggleUploadModal?.();
      }
      return;
    }
    if (
      fileRejections.length &&
      !acceptedFiles.length &&
      !UPLOAD_CONSTRAINT_RESOURCES.includes(currentResourceType)
    ) {
      dispatch(
        setAlert({
          type: ALERT.ERROR,
          msg: t(T.dropDocsRestriction)
        })
      );
    } else {
      if (fileRejections.length && acceptedFiles.length) {
        dispatch(
          setAlert({
            type: ALERT.WARNING,
            msg: t(T.dropDocsPartialRestriction)
          })
        );
      }
      if (toggleUploadModal) {
        toggleUploadModal();
      }
    }
  }
};

export type UploadStatusMessageType = {
  message: string;
  type: AlertColor;
};

export const areFilesValid = (acceptedFiles, dispatch, t) => {
  let isValid = true;
  acceptedFiles.map((file) => {
    if (file.name.length > FILE_NAME_LENGTH_LIMIT) {
      dispatch(
        setAlert({
          msg: t(T.maxDocSize, {
            name: file.name.slice(0, 15) + '...'
          }),
          type: ALERT.ERROR
        })
      );
      isValid = false;
    }
  });

  return isValid;
};

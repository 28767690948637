import { Box } from '@mui/material';

type GridContainerProps = {
  length: number;
  children: React.ReactNode;
  isCardContent?: boolean;
  height?: string;
  minHeight?: string;
} & React.ComponentProps<typeof Box>;

export const GridContainer = ({
  length,
  children,
  height,
  minHeight,
  ...rest
}: GridContainerProps) => (
  <Box
    sx={{
      ...(length && { height: height || '100%' }),
      ...(minHeight && { minHeight }),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      ...(rest.sx || {})
    }}
    {...rest}
  >
    {children}
  </Box>
);

export default GridContainer;

import { ListItemText, useTheme } from '@mui/material';
import { StringKeys } from 'src/types/base';

type Props = {
  primaryText: string;
  isSidebarOpen?: boolean;
  secondaryText?: string;
  sx?: StringKeys;
};

const CustomListItemText = ({
  primaryText = '',
  secondaryText = '',
  isSidebarOpen = true,
  sx = {}
}: Props) => {
  const theme = useTheme();
  return (
    <ListItemText
      sx={{
        position: 'relative',
        textAlign: 'left',
        // maxWidth: 'max-content',
        ...sx
      }}
      primaryTypographyProps={{
        color: isSidebarOpen ? 'white' : '#000C57',
        variant: 'h5',
        noWrap: true,
        fontWeight: 'regular',
        letterSpacing: 0,
        overflow: 'clip',
        whiteSpace: 'nowrap',
        textOverflow: 'clip',
        ...sx
      }}
      primary={primaryText}
      secondaryTypographyProps={{
        color: theme.colors.alpha.white[70],
        fontSize: '12px',
        noWrap: true,
        fontWeight: 'regular',
        letterSpacing: 0,
        overflow: 'clip',
        whiteSpace: 'nowrap',
        textOverflow: 'clip',
        ...sx
      }}
      secondary={secondaryText}
    />
  );
};

export default CustomListItemText;

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { t } from 'i18next';
import { MutableRefObject, useMemo } from 'react';
import { ChatFile } from 'src/content/Chat/types';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { PAGE_TITLE_SIZE, PAGE_TITLE_WEIGHT } from 'src/utils/utils';
import useAdvancedSearch from '.';
import ResourceSearchItem from '../ResourceSearchItem';
import AdvancedSearchFallback from './AdvancedSearchFallback';
import SearchBar from './Searchbar';
import { getAdvancedDialogPaperStyles, SearchPageWrapper } from './styles';
import { FileOption } from './types';

type AdvancedSearchDialogProps = {
  chatFiles: ChatFile[];
  setChatFiles: React.Dispatch<React.SetStateAction<ChatFile[]>>;
  isAdvancedSearchDialogOpen: boolean;
  closeAdvancedSearchDialog: () => void;
  onStartChatClick: (value: ChatFile[]) => void;
  isDataLibrary?: boolean;
  searchInputRef?: MutableRefObject<HTMLInputElement>;
  isSearchPage?: boolean;
};

export default function AdvancedSearchDialog({
  chatFiles,
  setChatFiles,
  isAdvancedSearchDialogOpen,
  closeAdvancedSearchDialog,
  onStartChatClick,
  isDataLibrary,
  searchInputRef,
  isSearchPage = false
}: AdvancedSearchDialogProps) {
  const {
    handleSubmit,
    handleSearch,
    control,
    hybridSearchData,
    handleFileSelect,
    handleTypeFilterSelected,
    handleSourceFilterSelected,
    handleAddSelectedFiles,
    isFetching,
    filesOptions,
    handleStartChatClick,
    userResourceProviders,
    searchValue,
    isAnyOptionSelected
  } = useAdvancedSearch({
    chatFiles,
    setChatFiles,
    closeAdvancedSearchDialog,
    onStartChatClick,
    searchInputRef,
    isAdvancedSearchDialogOpen,
    isDataLibrary
  });

  const Wrapper = useMemo(() => {
    return isSearchPage
      ? SearchPageWrapper
      : ({ children }: { children: React.ReactNode }) => (
          <Dialog
            open={isAdvancedSearchDialogOpen}
            PaperProps={{ sx: getAdvancedDialogPaperStyles }}
            onClose={closeAdvancedSearchDialog}
            fullWidth
          >
            {children}
          </Dialog>
        );
  }, [isSearchPage, isAdvancedSearchDialogOpen, getAdvancedDialogPaperStyles]);

  const MemoizedSearchBar: React.ReactNode = useMemo(
    () => (
      <SearchBar
        handleSubmit={handleSubmit}
        handleSearch={handleSearch}
        control={control}
        hybridSearchData={hybridSearchData}
        handleSourceFilterSelected={handleSourceFilterSelected}
        userResourceProviders={userResourceProviders}
        handleTypeFilterSelected={handleTypeFilterSelected}
        isAnyOptionSelected={isAnyOptionSelected}
        handleAddSelectedFiles={handleAddSelectedFiles}
        isDataLibrary={isDataLibrary}
      />
    ),
    [
      handleSubmit,
      handleSearch,
      control,
      hybridSearchData,
      handleSourceFilterSelected,
      userResourceProviders,
      handleTypeFilterSelected,
      isAnyOptionSelected,
      handleAddSelectedFiles,
      isDataLibrary
    ]
  );

  return (
    <Wrapper>
      <Typography
        fontSize={isSearchPage ? PAGE_TITLE_SIZE : 26}
        fontWeight={isSearchPage ? PAGE_TITLE_WEIGHT : 400}
        paddingLeft={isSearchPage ? 0 : 0.5}
        paddingTop={isSearchPage ? 0.3 : 0}
      >
        {t(T.advancedSearch)}
      </Typography>
      {!isSearchPage && (
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeAdvancedSearchDialog}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 12,
            top: 0
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {(!isSearchPage || searchValue.length !== 0 || isFetching) &&
        MemoizedSearchBar}
      {!filesOptions.length || isFetching ? (
        <AdvancedSearchFallback
          isFetching={isFetching}
          filesData={hybridSearchData}
          filesOptions={filesOptions}
          searchValue={searchValue}
          isSearchPage={isSearchPage}
          MemoizedSearchBar={MemoizedSearchBar}
        />
      ) : (
        <Box
          sx={{
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            mt: '4px'
          }}
        >
          {filesOptions?.map((option: FileOption, idx) => (
            <ResourceSearchItem
              key={option.id}
              isOptionSelected={option.isSelected}
              resultItem={option}
              handleStartChatClick={handleStartChatClick}
              handleItemSelect={handleFileSelect}
              isAdvanced
              isDataLibrary={isDataLibrary}
            />
          ))}
        </Box>
      )}
    </Wrapper>
  );
}

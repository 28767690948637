import { getEncryptParams } from 'src/keycloak';
import { DataApiInputParams } from 'src/types/api';
import {
  Builder,
  decryptResponse,
  getHeaders,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';

export default (builder: Builder) => {
  return {
    createHelpSupportFeedback: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, baseQuery) {
        let decryptKey = getEncryptParams()?.k;
        let url = `contact-support/support`;
        let result: any = await baseQuery({
          url: url,
          method: 'POST',
          body: arg.params.formData,
          ...getHeaders(url, arg.params.formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    })
  };
};

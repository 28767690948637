import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as SidebarIcon } from 'src/assets/sidebarIcon/sidebar-icon.svg';
import { resetAssistantContext } from 'src/content/Documents/utils/gridUtils';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  closeDocumentViewer,
  createChatSnapshot,
  setChatCreationError,
  setSelectedChat
} from 'src/redux/slices/chat';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { CHAT_PRE_CREATION_ID } from 'src/utils/constants';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { miniDrawerWidth } from '../styles';

const MobileMinimizedDrawer = ({
  isShareChatPath
}: {
  isShareChatPath: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const { selectedChat, chatCreationError } = useSelector(
    (state: RootState) => state.chat
  );

  const dispatch = useDispatch();
  const { state: routeState } = useLocation();

  const handleCreateNewChat = () => {
    if (selectedChat !== 0 || routeState || chatCreationError) {
      if (chatCreationError) dispatch(setChatCreationError({ isError: false }));
      dispatch(closeDocumentViewer(selectedChat));
      dispatch(setSelectedChat(undefined));
      dispatch(createChatSnapshot({ chatId: CHAT_PRE_CREATION_ID }));
      resetAssistantContext(dispatch);
      setTimeout(() => {
        dispatch(setSelectedChat(0));
      }, 100);
    }
  };
  return (
    <Drawer
      sx={{
        height: `${miniDrawerWidth}px`,
        flexShrink: 0,
        position: 'absolute',
        left: 0,
        [theme.breakpoints.up('sm')]: {
          display: 'none'
        },
        '& .MuiDrawer-paper': {
          height: `${miniDrawerWidth}px`,
          boxSizing: 'border-box'
        }
      }}
      variant="persistent"
      anchor="top"
      open={!isSidebarOpen && !isShareChatPath}
    >
      <Box
        display="flex"
        gap="12px"
        alignItems="center"
        height="100%"
        padding="16px"
      >
        <IconButton
          aria-label="open drawer"
          onClick={toggleSidebar}
          color="secondary"
          sx={{
            boxShadow: '0px 1.1px 3.5px 0 #1018282A'
          }}
        >
          <SidebarIcon
            height="24px"
            width="24px"
            stroke={theme.colors.primary.main}
          />
        </IconButton>

        <Button
          component={NavLink}
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: '6px',
            flexGrow: 1
          }}
          to="/chat"
          size="medium"
          startIcon={<Add />}
          onClick={handleCreateNewChat}
        >
          <Typography variant="h4">{t(T.createNewChat)}</Typography>
        </Button>
      </Box>
    </Drawer>
  );
};

export default MobileMinimizedDrawer;

import { ArticleOutlined } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DialogLayout from 'src/components/MiniExperts/CommonDialogLayout';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { FilePermissionModalProps } from '../utils/types';
import { getFileIcon } from 'src/utils/files/file';

const FilePermissionModal: FC<FilePermissionModalProps> = ({
  isFileModalOpen,
  handleCloseFileModal,
  rowData
}) => {
  const { t } = useTranslation();
  const hasNoFilePermissions = useMemo(
    () =>
      rowData?.permission?.resources?.filter(
        ({ has_permission }) => !has_permission || has_permission === 'false'
      ),
    [rowData?.permission?.resources]
  );

  const getStartIcon = useCallback((originalFormat) => {
    if (originalFormat) {
      const Icon = getFileIcon(originalFormat);
      if (Icon) {
        return <Icon height={30} width={30} />;
      }
    }
    return <ArticleOutlined fontSize="small" />;
  }, []);

  const hasNoFiles = useMemo(() => {
    return rowData?.permission?.resources?.length === 0;
  }, [rowData?.permission?.resources]);

  const hasAiModel = useMemo(() => {
    return !!rowData?.aiModel;
  }, [rowData?.aiModel]);

  return (
    <DialogLayout
      dialogTitle={
        !hasAiModel
          ? t(T.noAiModelTitle)
          : hasNoFiles
          ? t(T.assistantNoFiles)
          : t(T.assistantFilesPermission)
      }
      isOpen={isFileModalOpen}
      onClose={handleCloseFileModal}
      showConfirmButton={false}
      sxHeader={{
        fontSize: '22px',
        fontWeight: 400,
        lineHeight: '36px',
        letterSpacing: '0.7px'
      }}
      sxDialog={{
        minWidth: '650px'
      }}
      showCloseHeaderButton={false}
      cancelButtonText={t(T.close)}
    >
      {!hasAiModel ? (
        <Typography variant="body1">{t(T.noAiModelDisclaimer)}</Typography>
      ) : hasNoFiles ? (
        <Typography variant="body1">{t(T.noFilesDisclaimer)}</Typography>
      ) : (
        <Box>
          <Typography
            variant="body1"
            sx={{
              backgroundColor: '#CAD9F266',
              padding: '8px'
            }}
          >
            {t(T.name)}
          </Typography>
          <Divider />
          {hasNoFilePermissions?.map((item: StringKeys, index: number) => (
            <Box key={index}>
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={'8px'}
                padding={'8px'}
              >
                {getStartIcon(item?.file?.original_format)}
                <Typography variant="body1">{item?.file?.name}</Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      )}
    </DialogLayout>
  );
};

export default FilePermissionModal;

import { InfoOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import React, { PropsWithChildren, useCallback } from 'react';
import HeaderButton from './HeaderButton';
import { IActionButtonProps } from 'src/types/header';
import HeaderFilter from '../HeaderFilterwithPopOver';
import { FilterComponents } from 'src/types/custom_component';
import { StringKeys } from 'src/types/base';
import ViewSwitcher from './ViewSwitcher';
import { GRID_CONTAINER_HEIGHT } from 'src/utils/constants';
type FilterProps = {
  onSearch: (selectedFields: { [z: string]: any }) => any;
  onReset: (initialObject: any) => void;
  forceReset?: boolean;
  elementsList: FilterComponents[];
  Button?: any;
  showFilters?: boolean;
  disableFilters?: boolean;
  filtersValue?: StringKeys;
  setFiltersValue?: React.Dispatch<React.SetStateAction<StringKeys>>;
  hideFilterButton?: boolean;
};

type CustomFilterElementProps = {
  id: number;
  component: React.FC;
  props: { [z: string]: any };
};

type Props = PropsWithChildren<{
  title?: string;
  showInfoIcon?: boolean;
  infoIconTooltipText?: string;
  filterProps?: FilterProps;
  actionButtons: IActionButtonProps[];
  customFilterElements?: CustomFilterElementProps[];
  showViewSwitcher?: boolean;
  renderTabs?: any;
}>;

const PageHeader = ({
  children,
  title,
  showInfoIcon,
  infoIconTooltipText = '',
  actionButtons = [],
  customFilterElements,
  showViewSwitcher = false,
  filterProps,
  renderTabs
}: Props) => {
  const renderHeaderButton = useCallback(
    (actionButton: IActionButtonProps, index: number) => {
      if (!actionButton.visible) {
        return null;
      }
      return (
        <React.Fragment key={index}>
          <Grid
            item
            display={{
              xs: 'none',
              sm: 'flex'
            }}
          >
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item>
            <HeaderButton
              isButtonDisabled={actionButton.disabled}
              onClick={actionButton.onClick}
              tooltipMessage={actionButton.tooltipMsg}
              isPopover={actionButton.popover}
              buttonProps={{
                color: actionButton.color,
                startIcon: <actionButton.icon />,
                variant: actionButton.variant
              }}
              popoverElements={actionButton.popoverElements}
              btnText={actionButton.text}
              elemId={actionButton.elementId}
            />
          </Grid>
        </React.Fragment>
      );
    },
    []
  );

  return (
    <Paper sx={(theme) => ({ paddingBottom: theme.spacing(1) })} elevation={0}>
      <Grid
        container
        sx={{
          height: {
            xs: 'fit-content',
            sm: GRID_CONTAINER_HEIGHT
          }
        }}
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Grid item>
          <Box display="flex" alignItems={'center'}>
            <Typography variant="h3">{title}</Typography>
            <Box ml={'20px'}>{renderTabs}</Box>
            {showInfoIcon && (
              <Tooltip title={infoIconTooltipText} placement="right">
                <InfoOutlined
                  sx={(theme) => ({
                    color: theme.colors.custom.iconColor,
                    padding: '4px'
                  })}
                />
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid
          item
          width={{
            xs: '100%',
            sm: 'initial'
          }}
        >
          <Grid container gap={1.5}>
            {customFilterElements?.length &&
              customFilterElements.map(
                ({ component: CustomFilterComponent, props, id }) => (
                  <CustomFilterComponent {...props} key={id} />
                )
              )}
            {filterProps && (
              <HeaderFilter
                {...filterProps}
                componentsValue={filterProps.filtersValue}
                setComponentValue={filterProps.setFiltersValue}
              />
            )}
            {showViewSwitcher && (
              <Grid item>
                <ViewSwitcher filtersValue={filterProps.filtersValue} />
              </Grid>
            )}
            {actionButtons.map(renderHeaderButton)}
          </Grid>
        </Grid>
      </Grid>
      {children && <Box mt={1}>{children}</Box>}
    </Paper>
  );
};

export default PageHeader;

import { Box, Button, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const AdvancedSearchButton = styled(Button)(({ theme }) => ({
  width: '100%',
  border: '1px solid rgba(170, 190, 222, 0.40)',
  background: '#FFF',
  height: '32px',
  color: '#53669B',
  fontSize: '14px',
  fontWeight: '400',
  position: 'absolute',
  zIndex: 0,
  bottom: 0,
  ':hover': {
    background: '#f5f5f5'
  }
}));

export const CustomPopper = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  width: '413px !important',
  border: `1px solid ${theme.colors.primaryAlt.main}`,
  borderRadius: 0,
  height: '258px',
  '& .MuiAutocomplete-paper': {
    border: 'none',
    overflow: 'hidden',
    scrollWidth: 'none'
  },
  '& .MuiAutocomplete-listbox': {
    height: '256px',
    scrollbarWidth: 'thin',
    boxShadow: 'inset 0px -16px 8px -10px rgba(0, 0, 0, 0.06)'
  }
}));

export const getDialogPaperStyles = (theme: Theme) => ({
  maxWidth: '413px',
  width: '100%',
  minHeight: '351px',
  padding: '0 !important',
  borderRadius: '0 !important',
  boxShadow: 'none',
  mt: -1.5
});

export const searchableAutoCompleteStyles = (isDataLibrary: boolean) => ({
  '& .MuiAutocomplete-tag': {
    display: 'none'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #cad9f2',
    borderRadius: '6px',
    width: isDataLibrary ? '100%' : '94%',
    margin: 'auto'
  },
  '& .MuiOutlinedInput-root': {
    paddingInline: '20px !important',
    height: isDataLibrary ? '43px' : 'auto'
  },
  '& .MuiFormControl-root': {
    border: isDataLibrary ? 'none' : '1px solid #cad9f2',
    borderBottom: 0,
    height: '54px',
    justifyContent: 'center'
  }
});

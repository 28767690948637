import React from 'react';
import { ChatFile } from 'src/content/Chat/types';
import useFileViewer from 'src/hooks/files/useFileViewer';
import { RESOURCE_TYPE } from 'src/types/enum';
import { DATA_LIBRARY_ROUTES } from 'src/utils/constants';

const useResourceViewer = ({ resource }: { resource?: ChatFile }) => {
  const { isFetching, handleViewFile } = useFileViewer({
    selectedDocument: resource
  });

  const viewResource = (resourceItem = resource) => {
    if (resourceItem.type_code === RESOURCE_TYPE.FILE) {
      handleViewFile(resourceItem);
    } else {
      window.open(
        `/data-library/${DATA_LIBRARY_ROUTES[resourceItem.type_code]}/${
          resourceItem.file_id
        }`,
        '_blank'
      );
    }
  };

  const showInFolder = (resourceItem = resource) =>
    window.open(
      `/data-library/${DATA_LIBRARY_ROUTES[resourceItem.type_code]}/${
        resourceItem.parent_id
      }`,
      '_blank'
    );

  return { viewResource, showInFolder, isFetching };
};

export default useResourceViewer;

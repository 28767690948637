import { useMediaQuery, useTheme } from '@mui/material';
import { FC, useState, createContext, useEffect } from 'react';

type SidebarContextValues = {
  isSidebarOpen: any;
  toggleSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContextValues>(
  {} as SidebarContextValues
);

export const SidebarProvider: FC = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  }, [isMobile]);

  const toggleSidebar = () => {
    setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
  };

  return (
    <SidebarContext.Provider
      value={{ isSidebarOpen, toggleSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

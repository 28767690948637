import { Accordion, AccordionDetails } from '@mui/material';
import CustomAccordionSummary from './AccordionSummary';
import { StringKeys } from 'src/types/base';

interface SidebarAccordionProps {
  expanded: boolean;
  onToggle: () => void;
  heading: string;
  children: React.ReactNode;
  summaryRef?: React.RefObject<HTMLDivElement>;
  sx?: StringKeys;
}

const SidebarAccordion: React.FC<SidebarAccordionProps> = ({
  expanded,
  onToggle,
  heading,
  children,
  summaryRef = null,
  sx = {}
}) => {
  return (
    <Accordion expanded={expanded} onChange={onToggle} sx={sx}>
      <CustomAccordionSummary
        expanded={expanded}
        summaryRef={summaryRef}
        heading={heading}
      />
      <AccordionDetails sx={{ minHeight: '24px', padding: 0 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarAccordion;

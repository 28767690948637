import type { ReactNode } from 'react';
import { ReactComponent as AssistantIcon } from 'src/assets/assistantIcons/assistant-bubble.svg';
import { ReactComponent as DataLibraryIcon } from 'src/assets/sidebarIcon/datalibrary.svg';
import { ReactComponent as MiniExpertsIcon } from 'src/assets/sidebarIcon/Mini Expert.svg';
import { ReactComponent as SearchIcon } from 'src/assets/sidebarIcon/search.svg';
import { IS_MINI_EXPERTS_DISABLED } from 'src/config';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const getMenuItems = (userAppPermissions) => {
  const menuItems = [
    {
      heading: '',
      items: [
        {
          name: 'Assistants',
          icon: AssistantIcon,
          link: '/assistants'
        }
      ]
    },
    {
      heading: '',
      items: [
        {
          name: 'Advanced Search',
          icon: SearchIcon,
          link: '/advanced-search'
        }
      ]
    }
  ];

  if (!IS_MINI_EXPERTS_DISABLED) {
    menuItems.push({
      heading: '',
      items: [
        {
          name: 'Mini Experts',
          icon: MiniExpertsIcon,
          link: '/mini-experts'
        }
      ]
    });
  }

  if (
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROOT_FOLDER_CONTENT]?.value
  ) {
    menuItems.push({
      heading: '',
      items: [
        {
          name: 'Workspaces',
          icon: DataLibraryIcon,
          link: '/data-library'
        }
      ]
    });
    return menuItems;
  }
  return menuItems;
};

export default getMenuItems;

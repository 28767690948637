import {
  Box,
  Checkbox,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import ResourceIcon from 'src/components/ResourceIcon';
import { ChatFile } from 'src/content/Chat/types';
import usePopover from 'src/hooks/usePopover';
import { RESOURCE_TYPE, USER_RESOURCE_PERMISSION } from 'src/types/enum';
import MoreIcon from 'src/assets/menu-icon.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { t } from 'i18next';
import { StartChatButton } from './AdvancedSearch/styles';
import MenuPopover from './MenuPopover';
import { ReactComponent as CheckBoxIcon } from 'src/assets/checkbox-icon.svg';
import { ReactComponent as CheckBoxSelectedIcon } from 'src/assets/checkbox-selected.svg';
import { ReactComponent as CheckBoxDisabledIcon } from 'src/assets/checkbox-disabled.svg';
import { FileOption } from './AdvancedSearch/types';

import {
  getOptionListItemStyles,
  getResourceTitleStyles,
  truncateTextStyle
} from './styles';
import useFileInfo from 'src/hooks/files/useFileInfo';

type ResourceSearchItemProps = {
  isOptionSelected: boolean;
  resultItem: ChatFile | FileOption;
  optionProps?: any;
  isAdvanced?: boolean;
  isDataLibrary?: boolean;
  highLightedOption?: ChatFile;
  handleStartChatClick: (item: ChatFile) => void;
  handleItemSelect?: (
    event: React.MouseEvent<HTMLElement>,
    item: ChatFile,
    isStartChatEnabled: boolean
  ) => void;
};

const ResourceSearchItem = ({
  isOptionSelected,
  resultItem,
  optionProps = {},
  isAdvanced,
  isDataLibrary,
  highLightedOption,
  handleStartChatClick,
  handleItemSelect
}: ResourceSearchItemProps) => {
  const {
    anchorEl: menuAnchorElem,
    isPopoverOpen: isMorePopupOpen,
    openPopover: openMenuPopover,
    closePopover: closeMenuPopover
  } = usePopover();
  const [popoverTargetOption, setPopoverTargetOption] =
    useState<ChatFile>(null);
  const [isHovered, setIsHovered] = useState(false);
  const { getProviderType } = useFileInfo();
  const isHighlighted = highLightedOption?.id === resultItem.id;
  const textContent = resultItem.highlighted_text || resultItem.chunk_text;
  const isStartChatEnabled =
    (isAdvanced || isDataLibrary) &&
    (resultItem.type_code === RESOURCE_TYPE.TAG ||
      !isDataLibrary ||
      !!resultItem.permissions?.[USER_RESOURCE_PERMISSION.QUERY_FILES_FOLDERS]);

  const openMorePopup = (
    event: React.MouseEvent<HTMLElement>,
    targetOption: ChatFile
  ) => {
    event.stopPropagation();
    openMenuPopover(event);
    setPopoverTargetOption(targetOption);
  };

  const onStartChatClick = (
    event: React.MouseEvent<HTMLElement>,
    item: ChatFile
  ) => {
    event.stopPropagation();
    handleStartChatClick(item);
  };

  return (
    <ListItem
      key={`${resultItem.id}-${resultItem.name}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(e) => handleItemSelect(e, resultItem, isStartChatEnabled)}
      sx={getOptionListItemStyles(isOptionSelected, isAdvanced, isHovered)}
      {...optionProps}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Box
          display="flex"
          alignItems="center"
          maxWidth="100%"
          justifyContent="space-between"
          key={`${resultItem.id}-${resultItem.name}`}
        >
          {isAdvanced && (
            <Checkbox
              sx={{
                ':hover': {
                  background: 'none'
                }
              }}
              icon={
                <CheckBoxIcon
                  fill={`${!isStartChatEnabled ? '#e5e5e5' : '#fff'}`}
                />
              }
              checkedIcon={
                resultItem.isDisabled ? (
                  <CheckBoxDisabledIcon />
                ) : (
                  <CheckBoxSelectedIcon />
                )
              }
              checked={resultItem.isSelected}
              disabled={resultItem.isDisabled}
            />
          )}
          <Box>
            <ResourceIcon
              typeCode={resultItem.type_code}
              originalFormat={resultItem?.original_format}
              height={30}
              width={30}
              providerType={getProviderType(resultItem.provider_id)}
              displayName={resultItem?.name}
              initials={resultItem?.initials}
              initialsColor={resultItem?.initials_color}
            />
          </Box>
          <ListItemText
            primary={resultItem.name}
            primaryTypographyProps={{ sx: getResourceTitleStyles(isAdvanced) }}
            secondaryTypographyProps={{
              color: '#8894BA',
              fontSize: '12px'
            }}
            sx={{ ml: '8px' }}
            secondary={`${
              resultItem.author
                ? `${resultItem.author} created at`
                : 'Created at'
            } ${resultItem.created_on}${
              resultItem?.page_no ? ` | Page No ${resultItem?.page_no}` : ''
            }`}
          />

          {(isHovered || isHighlighted || isMorePopupOpen) && (
            <>
              {resultItem.type_code !== RESOURCE_TYPE.TAG && (
                <Box
                  component="img"
                  src={MoreIcon}
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '10px'
                  }}
                  onClick={(e) => openMorePopup(e, resultItem)}
                />
              )}
              {isStartChatEnabled && (
                <StartChatButton
                  onClick={(e) => onStartChatClick(e, resultItem)}
                >
                  <Typography
                    variant="body1"
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    whiteSpace={'nowrap'}
                    color={'#000C57'}
                    fontSize={'12px'}
                    textAlign={'center'}
                    margin={'0px'}
                  >
                    {t(isDataLibrary ? T.startChat : T.addToChat)}
                  </Typography>
                </StartChatButton>
              )}
              <MenuPopover
                popoverTargetOption={popoverTargetOption}
                isMorePopupOpen={isMorePopupOpen}
                menuAnchorElem={menuAnchorElem}
                closeMenuPopover={closeMenuPopover}
              />
            </>
          )}
        </Box>
        {!!textContent && (
          <Typography
            color={'#8894BA'}
            fontSize={'12px'}
            lineHeight={'17px'}
            letterSpacing={'0.5px'}
            pl={'72px'}
            mt={'5px'}
            sx={truncateTextStyle}
            dangerouslySetInnerHTML={{ __html: textContent }}
          />
        )}
      </Box>
    </ListItem>
  );
};

export default ResourceSearchItem;

export const getOptionListItemStyles = (
  isOptionSelected: boolean,
  isAdvanced: boolean,
  isHovered: boolean
) => ({
  height: '52px',
  py: '8px !important',
  ...(isOptionSelected && !isAdvanced
    ? { background: 'rgb(216, 218, 229)' }
    : {}),
  ...(isAdvanced ? { py: '16px !important', px: '0', height: 'auto' } : {}),
  cursor: 'pointer',
  borderBottom: '1px solid rgba(170, 190, 222, 0.40)',
  ...(isHovered && { backgroundColor: '#F5F7FA' })
});

export const truncateTextStyle = {
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  wordBreak: 'break-word'
};
export const getResourceTitleStyles = (isAdvanced: boolean) => ({
  ...(!isAdvanced
    ? {
        maxWidth: '230px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    : {})
});

import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { ReactComponent as CloseIcon } from 'src/assets/close-icon.svg';

// translations
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

// styles
import {
  BodyTextTypography,
  DialogStyles,
  IconButtonStyles,
  MainTextTypography
} from './styles';

import useMutation from 'src/hooks/useMutation';
import { useCreateHelpSupportFeedbackMutation } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import AttachedFilesList from './AttachedFiles';
import { createFormData, HelpAndSupportTabs } from './utils';
import ContactSupportTabs from './HelpAndSupportTabs';
import UploadFile from './UploadFile';
import HelpFormFields from './Form';
import Loader from 'src/components/Loader';
import { defaultFormValues, FormValues } from './types';

interface HelpAndSupportModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsSuccessDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  isSuccessDialogOpen: boolean;
  selectedTab: string;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

const HelpAndSupportModal: React.FC<HelpAndSupportModalProps> = ({
  isOpen,
  onClose,
  setIsSuccessDialogOpen,
  selectedTab,
  setSelectedTab,
  setIsSubmitting
}) => {
  const supportEmail = useSelector(
    (state: RootState) => state.auth.user?.supportEmail
  );

  const { t }: { t: any } = useTranslation();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    defaultValues: defaultFormValues,
    mode: 'onChange'
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);

  const [createHelpSupportFeedback, { isLoading }] = useMutation({
    api: useCreateHelpSupportFeedbackMutation,
    errorContext: ErrorContext.HELP_SUPPORT
  });

  const onSubmit = useCallback(
    async (data: any) => {
      setIsSubmitting(true);
      const formData = await createFormData(data, attachedFiles, selectedTab);
      try {
        await handleAddHelpFeedback(formData);
      } finally {
        setIsSubmitting(false);
      }
    },
    [attachedFiles, selectedTab, setSelectedTab]
  );

  const handleReset = () => {
    setValue('subject', '');
    setValue('message', '');
    setAttachedFiles([]);
    onClose();
  };

  const handleAddHelpFeedback = useCallback(
    async (formData: FormData) => {
      const response = await createHelpSupportFeedback({
        params: { params: { formData: formData } },
        fallbackMsg: t(T.supportTicketError, { supportEmail: supportEmail }),
        successCallback: () => {
          handleReset();
          setIsSuccessDialogOpen(true);
        }
      });
    },
    [
      createHelpSupportFeedback,
      onClose,
      setValue,
      setIsSuccessDialogOpen,
      dispatch
    ]
  );

  const handleClose = () => {
    if (!isLoading) {
      handleReset();
    }
  };

  useEffect(() => {
    clearErrors();
  }, [selectedTab, clearErrors]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={DialogStyles}
      >
        {isLoading && <Loader />}
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={IconButtonStyles}
          disabled={isLoading}
        >
          <CloseIcon width={'28px'} height={'28px'} />
        </IconButton>

        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MainTextTypography variant="h5" style={{ fontSize: '26px' }}>
              {t(T.helpSupport)}
            </MainTextTypography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <BodyTextTypography mb={3}>{t(T.chooseOptions)}</BodyTextTypography>
          <ContactSupportTabs
            selectedTab={selectedTab}
            setValue={setValue}
            setAttachedFiles={setAttachedFiles}
            setSelectedTab={setSelectedTab}
          />

          <HelpFormFields
            control={control}
            errors={errors}
            selectedTab={selectedTab}
          />

          <UploadFile
            setAttachedFiles={setAttachedFiles}
            attachedFiles={attachedFiles}
            setIsUploading={setIsUploading}
          />

          <AttachedFilesList
            attachedFiles={attachedFiles}
            setAttachedFiles={setAttachedFiles}
          />
        </DialogContent>

        <DialogActions>
          <Tooltip
            title={isUploading ? t(T.fileUploadingInProgress) : ''}
            disableHoverListener={!isUploading}
          >
            <span>
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: 'none',
                  fontWeight: 'light',
                  borderRadius: '6px'
                }}
                disabled={!isValid || isLoading || isUploading}
              >
                <Typography variant="body2">{t(T.submit)}</Typography>
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HelpAndSupportModal;

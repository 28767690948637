import { useMemo, useState, useEffect, useCallback } from 'react';
import { Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import { RootState, useDispatch } from 'src/redux/store';
import {
  Column,
  EditingState,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  SortingState
} from '@devexpress/dx-react-grid';
import { Getter, Plugin } from '@devexpress/dx-react-core';
import {
  Grid as DataGrid,
  VirtualTable,
  TableEditColumn,
  TableHeaderRow,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import { useDeleteRoleMutation, useLazyGetRolesQuery } from 'src/services/api';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Loader from 'src/components/Loader';
import {
  TableRowHeader,
  Root,
  getActionColumnSorted,
  CustomTableRow,
  EditActionCell
} from 'src/components/Grid/styles';
import { StringKeys } from 'src/types/base';
import { setAlert } from 'src/redux/slices/snackbar';
import {
  COLUMNS,
  RoleColumns,
  columnExtensions as rolesColumnExtensions,
  defaultSorting,
  getFilteredList,
  integratedSortingColumnExtensions,
  sortingColumnExtensions
} from '../utils';
import { DeleteButton, EditButton } from '../styles';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import GridContainer from 'src/components/GridContainer';
import PageHeader from 'src/components/PageHeader';
import { DescriptionProvider } from './Description';
import AddRole from '../AddRole';
import { useSelector } from 'react-redux';
import { ALERT, USER_APP_PERMISSIONS } from 'src/types/enum';
import { getFilters } from './filter';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import { IContentRole, KcRole } from '../types';
import GridSkeleton from 'src/components/GridSkeleton';
import { ActionButtons } from './ActionButtons';
import { ErrorContext } from 'src/utils/errorMappings';
import useMutation from 'src/hooks/useMutation';
import { NameProvider } from './Name';
import GridActions from 'src/components/GridActions';
import { CONTENT_PADDING } from 'src/utils/constants';

const RolesList = ({ renderTabs, title }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [filteredKeys, setFilteredKeys] = useState<StringKeys>({});
  const [filtersValue, setFiltersValue] = useState<StringKeys>({});

  const [
    fetchAllRoles,
    {
      data: contentRoles,
      isLoading: isLoadingRoles,
      isFetching: isFetchingRoles
    }
  ] = useLazyQuery<DataApiInputParams, (KcRole & { id: string })[]>({
    api: useLazyGetRolesQuery,
    errorContext: ErrorContext.ROLES
  });
  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [editedRole, setEditedRole] = useState<IContentRole>();
  const [deleteKcRole, { isLoading: isDeleting }]: any = useMutation({
    api: useDeleteRoleMutation,
    errorContext: ErrorContext.ROLES
  });
  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [deletedRoleIds, setDeletedRoleIds] = useState<(string | number)[]>([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const { userAppPermissions } = useSelector((state: RootState) => state.data);

  const filters = useMemo(() => getFilters(), []);
  const havePermissionToDeleteRoles = useMemo(
    () => userAppPermissions[USER_APP_PERMISSIONS.DELETE_ROLES].value || false,
    [userAppPermissions]
  );

  const columns: Column[] = RoleColumns.map((col) => ({
    ...col,
    title: t(col.title),
    key: col.title
  }));

  const columnExtensions = useMemo(() => {
    return rolesColumnExtensions(isMobile);
  }, [isMobile]);

  const isFilterDisabled = useMemo(() => {
    return isFetchingRoles || selectedRows.length > 0;
  }, [isFetchingRoles, selectedRows]);

  useEffect(() => {
    fetchAllRoles({
      params: {
        params: { ...getFilteredList(filteredKeys), show_details: true }
      }
    });
  }, [filteredKeys]);

  const commitChanges = ({ deleted }: any) => {
    if (deleted?.length) {
      setDeletedRoleIds(deleted);
      setDeleteOpen(true);
      return;
    }
  };

  const onCancelDelete = () => {
    setDeleteOpen((prevDelete) => !prevDelete);
  };

  const onConfirmDelete = async () => {
    if (!selectedRows?.length && !deletedRoleIds.length) return;
    const rolesToDelete = (
      deletedRoleIds.length ? deletedRoleIds : selectedRows
    ).map((item) => contentRoles.find((role) => role.id === item).name);
    await deleteKcRole({
      params: {
        params: {
          role_list: rolesToDelete
        }
      },
      fallbackMsg: T.errorDeletingRole,
      successMsg: T.roleDeleteSuccess
    });
    if (deletedRoleIds.length) setDeletedRoleIds([]);
    else setSelectedRows([]);
    if (isDeleteOpen) {
      setDeleteOpen((prevDelete) => !prevDelete);
    }
  };

  const Command = useCallback(
    ({ rowData, ...rest }: TableEditColumn.CommandProps & { rowData: any }) => {
      switch (rest.id) {
        case 'edit':
          return userAppPermissions[USER_APP_PERMISSIONS.EDIT_ROLES]?.value ? (
            <EditButton
              onExecute={() => {
                setEditedRole(rowData);
                openAddDialog();
                rest.onExecute();
              }}
            />
          ) : null;
        case 'delete':
          return havePermissionToDeleteRoles ? (
            <DeleteButton onExecute={rest.onExecute} />
          ) : null;
        default:
          return <TableEditColumn.Command {...rest} />;
      }
    },
    []
  );

  const openAddDialog = useCallback(() => setIsAddDialogOpen(true), []);
  const closeAddDialog = useCallback(() => {
    setIsAddDialogOpen(false);
    setEditedRole(undefined);
  }, []);

  const onSearch = (selectedFields: StringKeys) => {
    if (!isEqual(selectedFields, filteredKeys)) {
      fetchAllRoles({
        params: { ...getFilteredList(selectedFields) }
      });
      setFilteredKeys(selectedFields);
    }
  };

  const onReset = useCallback((initialObject: any) => {
    setFilteredKeys({ ...initialObject });
  }, []);

  const onClearSelection = useCallback(() => {
    setSelectedRows([]);
  }, []);

  const renderGrid = useMemo(() => {
    return (
      <GridContainer length={contentRoles?.length || 0}>
        {isDeleting ? <Loader /> : null}
        <DataGrid
          rows={contentRoles || []}
          columns={columns}
          getRowId={(row) => row.id}
          rootComponent={Root}
        >
          <EditingState onCommitChanges={commitChanges} />
          {havePermissionToDeleteRoles && (
            <SelectionState
              selection={selectedRows}
              onSelectionChange={setSelectedRows}
            />
          )}
          <SortingState
            defaultSorting={defaultSorting}
            columnExtensions={sortingColumnExtensions}
          />
          <IntegratedSorting
            columnExtensions={integratedSortingColumnExtensions}
          />
          <VirtualTable
            columnExtensions={columnExtensions}
            rowComponent={CustomTableRow}
          />

          {havePermissionToDeleteRoles && (
            <Plugin>
              <IntegratedSelection />
              <TableSelection showSelectAll />
            </Plugin>
          )}
          <TableEditColumn
            showEditCommand
            showDeleteCommand
            commandComponent={Command}
            cellComponent={EditActionCell}
          />
          <TableHeaderRow rowComponent={TableRowHeader} showSortingControls />
          <NameProvider for={[COLUMNS.NAME]} />
          <DescriptionProvider for={[COLUMNS.PERMISSIONS]} />
          <Getter name="tableColumns" computed={getActionColumnSorted} />
        </DataGrid>
      </GridContainer>
    );
  }, [contentRoles, isDeleting, selectedRows, columns]);

  return (
    <>
      <Box
        height="100%"
        padding={CONTENT_PADDING}
        sx={{ backgroundColor: 'white' }}
      >
        <PageHeader
          title={title}
          renderTabs={renderTabs}
          showInfoIcon={false}
          infoIconTooltipText={''}
          filterProps={{
            hideFilterButton: true,
            elementsList: filters,
            onSearch: onSearch,
            onReset,
            forceReset: true,
            showFilters: !isFetchingRoles,
            disableFilters: isFilterDisabled,
            filtersValue,
            setFiltersValue
          }}
          actionButtons={ActionButtons({
            selectedRows,
            showAddButton: true,
            handleOnAddClick: openAddDialog
          })}
        >
          <GridActions
            hideActions={!selectedRows.length}
            selectedRows={selectedRows.length}
            onClearSelection={onClearSelection}
            appliedFilters={{
              appliedFiltersValue: filtersValue,
              setAppliedFiltersValue: setFiltersValue,
              onReset,
              elementsList: filters
            }}
            actionButtons={ActionButtons({
              selectedRows,
              showDeleteButton: true,
              havePermissionToDeleteRoles,
              handleOnDeleteClick: setDeleteOpen
            })}
          />
        </PageHeader>
        {isLoadingRoles ? (
          <Loader />
        ) : (
          <Grid
            marginTop={theme.spacing(2)}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            sx={{
              height: 'calc(100% - 130px)'
            }}
          >
            <Grid item xs={12} height={'100%'}>
              {isFetchingRoles ? (
                <GridSkeleton columnExtensions={columnExtensions} />
              ) : (
                <> {renderGrid}</>
              )}
            </Grid>
          </Grid>
        )}
      </Box>

      {isAddDialogOpen && (
        <AddRole
          isOpen={isAddDialogOpen}
          onClose={closeAddDialog}
          roleData={editedRole}
        />
      )}
      {isDeleteOpen && (
        <ConfirmationDialog
          title={t(T.delete) + '?'}
          isOpen={isDeleteOpen}
          message={t(T.confirmDeleteRole)}
          onConfirm={onConfirmDelete}
          onClose={onCancelDelete}
        />
      )}
    </>
  );
};
export default RolesList;
